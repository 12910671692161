import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit'
import { copyAndSortOfDate, getDatesObject } from "../../utils/sort";
import {
  getNotificationsSuccess,
  getNotificationsRequest,
  setUnreadedNotificationsCount,
  clearUnreadedCount,
  getBySearchSuccess,
  clearSearch,
  searchRequest,
  setIsSearchEmpty,
} from './notifications-actions'

const notifications = createReducer([], {
  [getNotificationsSuccess]: (_, { payload }) => getDatesObject(copyAndSortOfDate(payload.data.items, 'createdAt', 'asc')),
})

const loading = createReducer(false, {
  [getNotificationsRequest]: (_, __) => true,
  [getNotificationsSuccess]: (_, __) => false,
})

const unreadedNotificationsCount = createReducer(0, {
  [setUnreadedNotificationsCount]: (state, {payload}) => payload,
  [clearUnreadedCount]: (_, __) => 0
})

const currentPage = createReducer(1, {
  [getNotificationsSuccess]: (_, { payload }) => payload.data.current_page,
})

const totalPage = createReducer(1, {
  [getNotificationsSuccess]: (_, { payload }) => payload.data.page_count,
})

export const itemsBySearch = createReducer([], {
  [getBySearchSuccess]: (_, {payload}) => getDatesObject(copyAndSortOfDate(payload.data.items, 'createdAt', 'asc')),
  [clearSearch]: () => [],
})

export const searchLoading = createReducer(false, {
  [searchRequest]: () => true,
  [getBySearchSuccess]: () => false,
})

export const pageCountBySearch = createReducer(1, {
  [getBySearchSuccess]: (_, {payload}) => payload.data.page_count,
})

export const currentSearchPage = createReducer(1, {
  [getBySearchSuccess]: (_, {payload}) => payload.data.current_page,
})

export const isEmptySearchResult = createReducer(false, {
  [setIsSearchEmpty]: (_, {payload}) => payload,
  [clearSearch]: (_, {payload}) => false,
})

export default combineReducers({
  notifications,
  loading,
  unreadedNotificationsCount,
  currentPage,
  totalPage,
  itemsBySearch,
  searchLoading,
  pageCountBySearch,
  currentSearchPage,
  isEmptySearchResult,
})

