import api from '../../api'
import {
  getNotificationsSuccess,
  getNotificationsRequest,
  getBySearchSuccess,
  searchRequest,
  setIsSearchEmpty,
} from './notifications-actions'
import moment from "moment";

export const getNotifications = (page = 1) => async dispatch => {
  dispatch(getNotificationsRequest())
  try {
    const data = await api.notifications.getNotifications(page)
    dispatch(getNotificationsSuccess(data))
  } catch (e) {
    console.log(e);
  }
}

export const getNotificationsBySearch = (query, start, end, page = 1) => async dispatch => {
  const startFormat = start && moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")
  const endFormat = end && moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")

  dispatch(searchRequest())
  try {
    const data = await api.notifications.getBySearch(query, startFormat, endFormat, page)
    if (data.data.items.length === 0) {
      dispatch(setIsSearchEmpty(true))
    } else {
      dispatch(setIsSearchEmpty(false))
    }
    dispatch(getBySearchSuccess(data))
  } catch (e) {
    console.log(e.message);
  }
}