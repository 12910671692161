import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { addNotification, removeNotifications } from '../../../redux/popup/popup-actions';
import '../NotificationProvider/notificationProvider.scss'
import deleteIcon from '../../../style/icons/Delete/delete.svg'
import { getConsultationInfo } from '../../../redux/consultations/consultations-operations';
import notificationSound from '../../../audios/telegram.mp3'
import useSound from 'use-sound';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { useHistory } from 'react-router-dom';

const NotificationItem = (props) => {
  const [exit, setExit] = useState(false)
  const [width, setWidth] = useState(0)
  const [intervalId, setIntervalId] = useState(null)
  const [play] = useSound(notificationSound, {volume: 0.1})
  const audioRef = useRef()
  const history = useHistory()

  // if (props.cardCreated) {
  //   history.push(props.parameters?.redirectUrl)
  // }
  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth(prev => {
        if (prev < 100) {
          return prev + 1
        }
        return prev
      })
    }, 50)
    setIntervalId(id)
  }

  const handlePauseTimer = () => {
    clearInterval(intervalId)
  }

  useEffect(() => {
    handleStartTimer()
  }, [])

  useEffect(() => {
    if (play) {
      play()
    }
  }, [play])

  const handleCloseNotification = (e) => {
    if (e) {
      e.stopPropagation()
    }
    handlePauseTimer()
    setExit(true)
    setTimeout(() => {
      props.removeNotifications(props.id)
    }, 700)
  }

  useEffect(() => {
    if (width === 100) {
      handleCloseNotification()
    }
  }, [width])

  const handleClick = () => {
    if (props.type === "ERROR") return
    if (props.noClick) return
    handleCloseNotification()
    if (props.redirectUrl) {
      history.push(props.redirectUrl)
    }
  }

  return (
    <div
      onClick={handleClick}
      onMouseLeave={() => handleStartTimer()}
      onMouseEnter={() => handlePauseTimer()}
      className={`notification-item ${props.type === 'SUCCESS' ? 'success' : 'error'}
      ${exit ? ' exit' : ''}`}
    >
      <div onClick={handleCloseNotification} className="closeBox">
        <CloseTwoToneIcon style={{
          color: props.type === "ERROR" ? 'red' : '#3288cd'
        }} />
      </div>
      <div className='notificationMessage'>{props.message}</div>
      <div className='bar' style={{ width: `${width}%` }}></div>
    </div>
  )
}

const mapDispatchToProps = {
  removeNotifications,
  getConsultationInfo,
}



export default connect(null, mapDispatchToProps)(NotificationItem);
