import { useTranslation } from 'react-i18next'
import previousIcon from '../../../style/icons/Subscription/previousIcon.svg'
import './pageHeader.scss'
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const PageHeader = ({ withPrevious, title, double, image }) => {
  const history = useHistory()
  const {t} = useTranslation()
  return (
    <div className='titleContainerMain'>
      {withPrevious && (
        <IconButton
          style={{ marginRight: "15px", color: '#3288cd' }}
          variant='contained'
          color='primary'
          onClick={() => history.push('/clinic')}
          title={t("back")}
        >
          <ArrowBackIosTwoToneIcon />
        </IconButton>
      )}
      <div className='imageBox'>
        {image && (
          <img
            id='spec'
            width='50'
            height='50'
            src={image}
            preload='auto'
            alt="specializationPhoto"
          />
        )}
      </div>
      <div className='pageTitle'>{title}</div>
    </div>
  )
}

export default PageHeader