import { v4 } from 'uuid'
import api from '../../api'
import i18n from '../../i18n'
import { disableBookingHome } from '../consultations/consultations-actions'
import { addNotification } from '../popup/popup-actions'
import {
  getAddressesSuccess,
  getDistrictsSuccess,
  getProvincesSuccess,
  getRequest,
} from './addresses-actions'
// filter[specialty]=38&filter[fullName]=&filter[type]=0

export const getAddresses = (doctorId, specialtyId) => async dispatch => {
  try {
    const data = await api.addresses.getAddresses()
    dispatch(getAddressesSuccess(data))
  } catch (e) {
    console.log(e);
  }
}

export const addNewAddress = (data) => async dispatch => {
  try {
    await api.addresses.addNewAddress(data)
    dispatch(addNotification({
      type: "SUCCESS",
      noClick: "true",
      id: v4(),
      message: i18n.t("addressCreated"),
    }))
  } catch (e) {
    console.log(e);
  }
}

export const getProvinces = (specialtyId = '') => async dispatch => {
  dispatch(getRequest())
  try {
    const data = await api.addresses.getProvinces(specialtyId)
    dispatch(getProvincesSuccess(data))
  } catch (e) {
    console.log(e);
  }
}

export const getDistricts = (provinceId, specializationId = '') => async dispatch => {
  dispatch(getRequest())
  try {
    const data = await api.addresses.getDistricts(provinceId, specializationId)
    dispatch(getDistrictsSuccess(data))
    dispatch(disableBookingHome(false))
  } catch (e) {
    dispatch(disableBookingHome(true))
  }
}