import moment from "moment";

const sortNotifications = (arr) => {
  if(!Array.isArray(arr)) return []
  let array = []
  return arr
}
// const 
const compar = (a, b) => {
	if (b > a) {
		return -1;
	}
	if (b < a) {
		return 1;
	}
	return 0;
};

export const descendingComparator = (a, b, orderBy) => {
	if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
		const value = (a[orderBy]) ?.toLowerCase();
		const value2 = (b[orderBy]) ?.toLowerCase();

		return compar(value, value2);
	}

	return compar(b[orderBy], a[orderBy]);
};


export const getComparator = (
	order,
	orderBy,
) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
};

export function copyAndSort(items, orderBy, order) {
	const key = orderBy;
	return items
		.slice(0)
		.sort((a, b) =>
			(order === 'asc' ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1,
		);
}
const compareByMiliSeconds = (order, key) => (a, b) => {
  const _a = new Date(a[key]).getTime()
  const _b = new Date(b[key]).getTime()
  return order === 'asc' ? _a < _b : _a > _b ? 1 : -1
}
export const copyAndSortOfDate = (items, orderBy, order) => {
	return items
		.slice(0)
    .sort((compareByMiliSeconds(order, orderBy)));
}
export const getDatesObject = (list) => {
  return list.reduce((acc, elem) => {
    let date = elem.createdAt.split("T")[0];

    if (acc[date]) {
      acc[date].push(elem);
    } else {
      acc[date] = [elem];
    }
    return acc;
  }, {})
}

export const getIsCurrentCardSubscribed = (subArr, cardId) => {
	if(!subArr || !cardId) return ''
	return subArr.some(sub => sub.booking.patientCard.id == cardId)
}

export const sortByDate = (array) => {
	if (!array) return {}
	return array.reduce((acc, item) => {
		const date = moment(item.dateSent).format('DD MMMM')
		if (acc[date] == null) acc[date] = []
		acc[date].push(item)
		return acc
	}, {})
}