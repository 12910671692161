import Recorder from 'recorder-js';

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext = null
let isRecording = false;
let blob1 = null;
let streamRef = null;
let recorder = null;
let input = null;
 
export async function startRecordingAudio() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
  audioContext = new AudioContext()
  recorder = new Recorder(audioContext, {})
  streamRef = stream
  recorder.init(stream)
  recorder.start()
}
 
export async function stopRecordingAudio() {
  const {blob, buffer} = await recorder.stop()
  blob1 = blob
  const audioUrl = window.URL.createObjectURL(blob1);
  isRecording = false;
  recorder = null;
  audioContext = null;
  stopAudioStream()
  streamRef = null;
  return {
    audioUrl,
    blob: blob1,
  }
}
 
const stopAudioStream = () => {
  streamRef.getTracks().forEach((tr) => tr.stop())
}


export function dataURLtoFile(dataurl, filename) {
 
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
            
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
        
  return new File([u8arr], filename, { type: mime });
}