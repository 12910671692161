import React from 'react';
import { useTranslation } from 'react-i18next';
import fishkaRed from '../../../style/images/Fishka/fishkaRed.jpg'
import fishkaGrey from '../../../style/images/Fishka/fishkaGrey.jpg'
import './fishkaMain.scss'
import { Button, IconButton } from '@material-ui/core';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { connect, useSelector } from 'react-redux';
import { closeFishka } from '../../../redux/fishka/fishka-actions';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import CardForm from '../../forms/CardForm/CardForm';
import { parserForm } from '../../../utils/parserForm';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import fishkaLogo from '../../../style/icons/Fishka/fiskaMainCircle.svg'
import moment from 'moment';
import { getFishkaButtonLoading, getIsAuthorized, getIsDirectlyToRegister, getIsRegistered, getPatientBonusesSelector } from '../../../redux/fishka/fishka-selectors';
import useStyles from './materialStyles'
import { getPatientBonuses, registrationInFishka } from '../../../redux/fishka/fishka-operations';
import { getUserMainCardSelector } from '../../../redux/auth/auth-selectors';

const steps = {
  UNREGISTERED: "UNREGISTERED",
  REGISTERED: "REGISTERED",
  FORMSTEP: "FORMSTEP",
  SUCCESSSTEP: "SUCCESSSTEP",
}

const FishkaMain = ({
  closeFishka,
  getPatientBonuses,
  patientBonuses,
  isAuthorized,
  isRegistered,
  registrationInFishka,
  directlyToRegister,
}) => {
  const [step, setStep] = React.useState(steps.UNREGISTERED)
  const [imageLoaded, setImageLoaded] = React.useState([])
  const [loadingImages, setLoadingImages] = React.useState(true)
  const [birthDate, setBirthDate] = React.useState(null)
  const [birthDateError, setBirthDateError] = React.useState(false)
  const [isOnFormStep, setIsOnFormStep] = React.useState(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const containerRef = React.useRef()

  React.useEffect(() => {
    if (imageLoaded.length === 2) {
      setTimeout(() => {
        setLoadingImages(false)
      }, 700)
    }
  }, [imageLoaded])

  React.useEffect(() => {
    getPatientBonuses()
  }, [])

  React.useEffect(() => {
    if (isOnFormStep && step === steps.UNREGISTERED) {
      containerRef.current.style.animation = 'decreaseHeight 0.3s ease-in'
      setIsOnFormStep(false)
      return
    }
    if (step === steps.FORMSTEP) {
      if (isAuthorized) {
        containerRef.current.style.animation = 'noAuthirizedHeight 0.3s ease-in forwards'
        setIsOnFormStep(true)
        return
      }
      containerRef.current.style.animation = 'addHeight 0.3s ease-in forwards'
      setIsOnFormStep(true)
      return
    }
    if (step === steps.SUCCESSSTEP) {
      containerRef.current.style.animation = 'successStep 0.3s ease-in forwards'
      setIsOnFormStep(true)
      return
    }
  }, [step])

  React.useEffect(() => {
    if (directlyToRegister) {
      setStep(steps.FORMSTEP)
    }
  }, [directlyToRegister])

  const handleButtonClick = (e) => {
    switch (step) {
      case steps.UNREGISTERED:
        setStep(steps.FORMSTEP)
        break;
      case steps.FORMSTEP:
        setStep(steps.SUCCESSSTEP)
        break;
      case steps.REGISTERED:
        setStep(steps.SUCCESSSTEP)
        break;
      case steps.SUCCESSSTEP:
        getPatientBonuses()
        closeFishka()
        break;
      default: return
    }
  }

  const handleBackClick = () => {
    setStep(steps.UNREGISTERED)
  }

  const formSubmit = (e) => {
    if (birthDateError) {
      [...e.target].find(el => el.id === 'input')?.focus()
      return
    }
    e.preventDefault()
    const { ...fields } = parserForm(e)
    const requestBody = {
      integrationName: 'fishka',
      profile: {
        ...fields,
        birthDate: moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        gender: +fields.gender,
      }
    }
    registrationInFishka(requestBody).then(() => {
      setStep(steps.SUCCESSSTEP)
    })
  }

  return (
    <div ref={containerRef} className={`fishkaContainer ${isRegistered ? 'tall' : ''}`}>
      <div className="fishkaTitle">{t("fishkaTitle")}</div>
      <IconButton
        className={classes.buttonBlack}
        variant='contained'
        color='primary'
        onClick={() => closeFishka()}
        title={t("closeButton")}
      >
        <CloseTwoToneIcon style={{color: "#3287cd"}}/>
      </IconButton>
      {step === steps.FORMSTEP && !directlyToRegister && (
        <IconButton
          className={classes.buttonBack}
          variant='contained'
          color='primary'
          onClick={handleBackClick}
          title={t("back")}
        >
          <ArrowBackIcon style={{color: "#3287cd"}}/>
        </IconButton>
      )}
      <div className={`${step !== steps.UNREGISTERED ? 'dnone' : ''}`}>
        <FishkaIntro
          patientBonuses={patientBonuses}
          isRegistered={isRegistered}
          loadingImages={loadingImages}
          setImageLoaded={setImageLoaded}
        />
      </div>
      {step === steps.FORMSTEP && (
        <FishkaFormStep
          isAuthorized={isAuthorized}
          formSubmit={formSubmit}
          setBirthDateError={setBirthDateError}
          setBirthDate={setBirthDate}
        />
      )}
      {step === steps.SUCCESSSTEP && (
        <FishkaSuccess/>
      )}
      {step !== steps.FORMSTEP && !isRegistered && (
        <div className='registerButton'>
          <Button
            fullWidth
            variant='contained'
            className={classes.button}
            onClick={handleButtonClick}
            loading={true}
          >{step !== steps.SUCCESSSTEP ? t("registerAction") : t("goodButton")}
          </Button>
        </div>
      )}
    </div>
  );
}

const FishkaSuccess = () => {
  const {t} = useTranslation()
  return (<>
    <div className="fishkaSuccessContainer">
      <img className='fishkaSuccessLogo' src={fishkaLogo} alt="fishka" />
      <div className='successText'>{t("fishkaSuccessText")}</div>
    </div>
  </>)
}
    
const FishkaFormStep = ({
  formSubmit,
  setBirthDateError,
  setBirthDate,
  isAuthorized,
}) => {
  const mainCard = useSelector(getUserMainCardSelector)
  const buttonLoading = useSelector(getFishkaButtonLoading)
  const [genderOptions, setGenderOptions] = React.useState(null)
  const { t } = useTranslation()
  
  React.useEffect(() => {
    setGenderOptions([
      { label: t("maleSex"), value: 0 },
      { label: t("femaleSex"), value: 1 }
    ])
  }, [])

  const userGender = mainCard && genderOptions && genderOptions.find(el => el.value === mainCard.gender)

  return (
    <div className='fishkaFormStepContainer'>
      <CardForm
        fishkaMode
        isAuthorized={isAuthorized}
        buttonLoading={buttonLoading}
        formSubmit={formSubmit}
        setBirthDate={setBirthDate}
        setBirthDateError={setBirthDateError}
        genderOptions={genderOptions}
        card={mainCard}
        userGender={userGender}
      />
    </div>
  )
}

const FishkaIntro = ({
  loadingImages,
  setImageLoaded,
  isRegistered,
  patientBonuses,
}) => {
  const { t } = useTranslation()
  return (<>
    {!isRegistered && (
      <div className="fishkaDescription">
        <span className='mainTitle'>
          <img className='logoSmall' src={fishkaLogo} alt="" /> Fishka - </span>
        {t("fishkaDescription")}
      </div>
    )}
    {isRegistered && (
      <div className="balanceBox">
        <div className='patientBonuses'>
          <img src={fishkaLogo} alt='fishka' className='fishkaLogo'/>
          <div className="balance">{patientBonuses}</div>
        </div>
        <div className="balanceText">Доступно баллами фишка, грн</div>
      </div>
    )}
      
    <div className={`fishkaBannerBox ${loadingImages ? 'imgNone' : ""}`}>
      <LoadingComponent loading={loadingImages} color={'#f04e51'} width={50} height={50} />
      <img
        className='fishkaBanner'
        src={fishkaRed}
        alt="Fishka"
        onLoad={() => setImageLoaded(prev => [...prev, 1])}
      />
      <img
        className='fishkaBanner'
        src={fishkaGrey}
        alt="Fishka"
        onLoad={() => setImageLoaded(prev => [...prev, 1])}
      />
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  patientBonuses: getPatientBonusesSelector(state),
  isAuthorized: getIsAuthorized(state),
  isRegistered: getIsRegistered(state),
  directlyToRegister: getIsDirectlyToRegister(state)
})

const mapDispatchToProps = {
  closeFishka,
  getPatientBonuses,
  registrationInFishka,
}
export default connect(mapStateToProps, mapDispatchToProps)(FishkaMain);
