
import Home from './views/pages/Home/Home'
import React, { useEffect, useState } from 'react';
import {Authorization} from './hoc';

function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default Authorization(App)
