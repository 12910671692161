import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getConsultationHistory: (cardId, page) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/patients/cards/${cardId}/history/appointments?page=${page ? page : 1}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  getAllConsultations: (page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/patients/cards/history/appointments/all?page=${page}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  getBySearch: (query, start, end, page) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/search/appointments?q=${query}${start ? `&dateFrom=${start}` : ""}${end ? `&dateTo=${end}` : start ? `&dateTo=${start}` : ""}&page=${page}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  getBookingDetails: (specialtyId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/booking/specialty/${specialtyId}`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
  getConsultationAnamnesis: (consultationId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/${consultationId}/anamnesis?onlyEmpty=false`,
      headers: setHeader()
    }
    return axios(config).then((res) => res.data)
  },
  getAnamnesis: (consultId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/${consultId}/anamnesis/all`,
      headers: setHeader()
    }
    return axios(config).then((res) => res.data)
  },
  answerAnamnesis: (consultId, body) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/consultations/${consultId}/anamnesis`,
      headers: setHeader(),
      data: body,
    }
    return axios(config).then((res) => res.data)
  },
  disableAnamnesis: (consulId) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/appointments/${consulId}/anamnesis/disable`,
      headers: setHeader()
    }
    return axios(config).then((res) => res.data)
  },
  bookConsultation: (specialtyId, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/consultations/booking/doctor/specialty/${specialtyId}`,
      headers: {
        ...setHeader(),
        "payment-success-url": `${window.location.origin}/consultations/payment/success`,
      },
      data,
    }
    return axios(config).then(res => res.data)
  },
  getCurrentConsultationInfo: (appointmentId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/appointments/${appointmentId}?drugs_provider=apteka24`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  calculateConsultationPrice: (requestString) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/calculate/${requestString}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  cancelConsultation: (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/appointments/${id}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  
  getInitialConsultationTypeStatus: (specialtyId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/booking/initial/specialty/${specialtyId}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },

  repayConsultation: (consultId) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/appointments/${consultId}/pay`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  }
}