import { createSelector } from "@reduxjs/toolkit";

const defaultSpecializations = {
  items: null,
  itemsBySearch: null,
};

const defaultState = { specializations: { ...defaultSpecializations }};

const getSpecs = (state = defaultState) =>
  state.specializations;

export const getSpecBySearchSelector = createSelector(
  [getSpecs],
  ({itemsBySearch}) => itemsBySearch?.items || null
);
export const getSpecializationsSelector = createSelector(
  [getSpecs],
  ({items}) => items?.items || null
);
export const getTotalCount = createSelector(
  [getSpecs],
  ({items}) => items?.total_item_count || null
);
export const getReseted = createSelector(
  [getSpecs],
  ({reseted}) => reseted
);