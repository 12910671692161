import React from 'react'
import {routers} from '../config'
import { Route, Switch, Redirect } from 'react-router'
// import LoadingPlaceholder from '../views/components/LoadingPlaceholder/LoadingPlaceholder'
import ChatModeObserver from '../chatHoc/ChatModeObserver';
// import { LoadingComponent } from '../views/pages/ConsultationBooking/ConsultationBooking';
const Clinic = React.lazy(() => import('../views/pages/Clinic/Clinic'));
const ConsultationBooking = React.lazy(() => import('../views/pages/ConsultationBooking/ConsultationBooking'));
const MedicalCardsPage = React.lazy(() => import('../views/pages/MedicalCardsPage/MedicalCardsPage'));
const Notifications = React.lazy(() => import('../views/pages/Notifications/Notifications'));
const CardInfo = React.lazy(() => import('../views/pages/CardInfo/CardInfo'));
const NewCardPage = React.lazy(() => import('../views/pages/NewCardPage/NewCardPage'));
const AnalysesAndDrugsBooking = React.lazy(() => import('../views/pages/AnalysesBooking/AnalysesAndDrugsBooking'));
// const ChatModeObserver = React.lazy(() => import('../chatHoc/ChatModeObserver'));

const AllConsultations = React.lazy(() => import('../views/pages/AllConsultations/AllConsultations'));
const Subscriptions = React.lazy(() => import('../views/pages/Subscriptions/Subscriptions'));
const SuccessPaymentPage = React.lazy(() => import('../views/pages/SuccessPaymentPage/SuccessPaymentPage'));
const DoctorsPage = React.lazy(() => import('../views/pages/DoctorsPage/DoctorsPage'));


const AllRoutes = (props) => {
  const currentPath = localStorage.getItem('path1')
  const defaultPath = currentPath !== '/' && (currentPath || '/clinic')
  
  return (
    <React.Suspense fallback={<div></div>}>
      <div className='mainContentContainer'>
        <Switch>
          <Route
            path={routers.clinic}
            exact
            render={() => <Clinic isSearching={props.isSearching} />}
          />
          <Route
            path={routers.supportChat}
            exact
            render={() => <ChatModeObserver supportMode />}
          />
          <Route
            path={routers.subscriptions}
            exact
            render={() => <Subscriptions />}
          />
          <Route
            path={routers.consultations}
            exact
            render={() => <AllConsultations />}
          />
          <Route
            path={routers.doctorChat}
            exact
            render={() => <ChatModeObserver />}
          />
          <Route
            path={routers.medicalCards}
            exact
            render={() => <MedicalCardsPage />}
          />
          <Route
            path={routers.notifications}
            exact
            render={() => <Notifications />}
          />
          <Route
            path={routers.consultationBooking}
            exact
            render={() => <ConsultationBooking />}
          />
          <Route
            path={routers.cardInfo}
            exact
            render={() => <CardInfo />}
          />
          <Route
            path={routers.newCard}
            exact
            render={() => <NewCardPage />}
          />
          {/* <Route
          path={routers.analysesBooking}
          exact
          render={() => <>
            <AnalysesAndDrugsBooking {...props} />
          </>}
        /> */}
          <Route
            path={routers.drugs}
            exact
            render={() => <AnalysesAndDrugsBooking />}
          />
          <Route
            path={routers.successConsultation}
            exact
            render={() => <SuccessPaymentPage consultation />}
          />
          <Route
            path={routers.successSubscription}
            exact
            render={() => <SuccessPaymentPage subscription />}
          />
          <Route
            path={routers.doctors}
            exact
            render={() => <DoctorsPage />}
          />
          <Route path='/'>
            <Redirect to={defaultPath || '/clinic'} />
          </Route>
        </Switch>
      </div>
    </React.Suspense>
  )
}


export default AllRoutes