import { createAction } from "@reduxjs/toolkit";

export const getNotificationsSuccess = createAction('notifications/getNotificationsSuccess')
export const getNotificationsRequest = createAction('notifications/getNotificationsRequest')

export const createMessagingConnection = createAction('notifications/createMessagingConnection')
export const disconnectFromMessaging = createAction('notifications/disconnectFromMessaging')
export const setUnreadedNotificationsCount = createAction("notifications/setUnreadedNotificationsCount")
export const clearUnreadedCount = createAction("notifications/clearUnreadedCount")
export const getBySearchSuccess = createAction("notifications/getBySearchSuccess")
export const clearSearch = createAction("notifications/clearSearch")
export const searchRequest = createAction("notifications/searchRequest")
export const setIsSearchEmpty = createAction("notifications/setIsSearchEmpty")
// export const getSymptomsError = createAction('symptoms/getError')