import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  getSubscriptionSuccess,
  getSubscriptionRequest,
  getSubscriptionListSuccess,
  bookSubscriptionSuccess,
  requestError,
  defineCurrentCardSubscription,
  resetFondyUrl,
  subscriptionBookingRequest,
} from './subscription-actions'


const subscription = createReducer([], {
  [getSubscriptionSuccess]: (_, { payload }) => payload.data.items,
})

const isSubscribed = createReducer(false, {
  [getSubscriptionSuccess]: (_, { payload }) => !!payload.data.items.length,
})

const subscriptionList = createReducer([], {
  [getSubscriptionListSuccess]: (_, {payload}) => payload.data.items,
})

const loading = createReducer(false, {
  [getSubscriptionRequest]: (_, __) => true,
  [getSubscriptionSuccess]: (_, __) => false,
  [getSubscriptionListSuccess]: (_, __) => false,
  [bookSubscriptionSuccess]: (_, __) => false,
  [requestError]: (_, __) => false
})

const otherLoading = createReducer(false, {
  [subscriptionBookingRequest]: (_, __) => true,
  [bookSubscriptionSuccess]: (_, __) => false,
  [requestError]: (_, __) => false
})

const fondyUrl = createReducer(null, {
  [bookSubscriptionSuccess]: (_, { payload }) => payload.entity?.payment?.paymentUrl,
  [resetFondyUrl]: (_, __) => null,
})

const isBooked = createReducer(false, {
  [bookSubscriptionSuccess]: (_, __) => true
})

// const isCurrentCardSubscribed = createReducer(false, {
//   [getSubscriptionSuccess]: ({ payload }) => 
//   }
// })
// const error = createReducer(false, {
//   [requestError]: true,
//   [getSubscriptionSuccess]: (_, __) => false,
//   [getSubscriptionListSuccess]: (_, __) => false,
//   [bookSubscriptionSuccess]: (_, __) => false
// })

export default combineReducers({
  subscription,
  isSubscribed,
  subscriptionList,
  loading,
  isBooked,
  fondyUrl,
  otherLoading,
  // error,
})