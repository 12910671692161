import { createAction } from "@reduxjs/toolkit";

export const anamnezRequest = createAction("consultations/anamnezRequest")
export const getConsultationHistorySuccess = createAction("consultations/getConsultationHistorySuccess")
export const getConsultationError = createAction("consultations/getConsultationError")
export const getBookingDetailsSuccess = createAction("consultations/getBookingDetailsSuccess")
export const getAnamnesisSuccess = createAction("consultations/getAnamnesisSuccess")
export const bookConsultationSuccess = createAction("consultations/bookConsultationSuccess")
export const consultationRequest = createAction("consultations/consultationRequest")
export const setCardId = createAction("consultations/setCardId")
export const getAllConsultationsSuccess = createAction("consultations/getAllConsultationsSuccess")
export const gotInfo = createAction("consultations/gotInfo")
export const setCancelledFlag = createAction('consultations/wasCancalledFlag')
export const calculatePrice = createAction("consultations/calculatePrice")
export const priceRequest = createAction("consultations/priceRequest")
export const resetFondyUrl = createAction('consultations/resetFondyUrl')
export const cancelConsultationSuccess = createAction("consultations/cancelConsultationSuccess")
export const getAllConsultationInfoSuccess = createAction('consultations/getAllConsultationInfoSuccess')
export const changeDrugQuantity = createAction("consultations/changeDrugQuantity")
export const getInitialTypeStatus = createAction('consultations/getInitialTypeStatus')
export const repaySuccess = createAction("consultations/repaySuccess")
export const disableBookingHome = createAction("consultations/disableBookingHome")
export const searchRequest = createAction("consultations/searchRequest")
export const getBySearchSuccess = createAction("consultations/getBySearchSuccess")
export const clearSearch = createAction("consultations/clearSearch")
export const setIsSearchEmpty = createAction("consultations/setIsSearchEmpty")
export const getConsultAnamnesisSuccess = createAction("consultations/getConsultAnamnesisSuccess")
export const anamnesisDisabledSuccess = createAction("consultations/anamnesisDisabledSuccess")
export const setUsedAnamnesis = createAction("consultations/setUsedAnamnesis")
export const anamnezDisabledSuccess = createAction("consultations/anamnezDisabledSuccess")
export const anamnezDisableRequest = createAction("consultations/anamnezDisableRequest")
export const resetAnamnesis = createAction("consultations/resetAnamnesis")
export const resetCurrentConsultation = createAction("consultations/resetCurrentConsultation")
