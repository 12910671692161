import { setHeader, TEST_API } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHospitalGroups: (specialtyId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/hospitals/specialty/${specialtyId}`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
  getHospitals: (groupId, specialtyId, query='') => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/hospitals/${groupId}/specialty/${specialtyId}?search=${query}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  }
}