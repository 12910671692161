import { createAction } from "@reduxjs/toolkit";

export const registerSuccess = createAction('auth/registerSuccess')
export const registerError = createAction('auth/registerError')

export const loginSuccess = createAction('auth/loginSuccess')
export const loginError = createAction('auth/loginError')

export const logOutSuccess = createAction('auth/logOutSuccess')
export const logOutError = createAction('auth/logOutError')
export const logoutRequest = createAction('auth/logoutRequest')

export const getCurrentUserSuccess = createAction('auth/getCurrentUserSuccess')
export const getCurrentUserError = createAction('auth/getCurrentUserError')

export const getProfileSuccess = createAction("auth/getProfileSuccess")

export const sendCodeSuccess = createAction('auth/sendCodeSuccess')

export const getUserCardsSuccess = createAction("cards/getUserCardsSuccess")

export const getRequest = createAction("auth/request")
export const getUserRequest = createAction("auth/userRequest")
export const setToken = createAction('auth/setToken')
