import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  getConsultationHistorySuccess,
  getConsultationError,
  getBookingDetailsSuccess,
  getAnamnesisSuccess,
  bookConsultationSuccess,
  consultationRequest,
  anamnezRequest,
  setCardId,
  getAllConsultationsSuccess,
  getCurrentConsultationInfo,
  gotInfo,
  setCancelledFlag,
  calculatePrice,
  priceRequest,
  resetFondyUrl,
  cancelConsultationSuccess,
  getAllConsultationInfoSuccess,
  changeDrugQuantity,
  getInitialTypeStatus,
  repaySuccess,
  disableBookingHome,
  searchRequest,
  getBySearchSuccess,
  clearSearch,
  setIsSearchEmpty,
  getConsultAnamnesisSuccess,
  anamnesisDisabledSuccess,
  setUsedAnamnesis,
  anamnezDisabledSuccess,
  anamnezDisableRequest,
  resetAnamnesis,
  resetCurrentConsultation,
} from './consultations-actions'
import { configureDrugList } from "./consultations-selectors";

export const consultations = createReducer([], {
  [getConsultationHistorySuccess]: (state, { payload }) => payload.items,
  [getAllConsultationsSuccess]: (state, { payload }) => payload.items,
})

export const itemsBySearch = createReducer([], {
  [getBySearchSuccess]: (_, {payload}) => payload?.items || [],
  [clearSearch]: () => [],
})

export const searchLoading = createReducer(false, {
  [searchRequest]: () => true,
  [getBySearchSuccess]: () => false,
})

export const bookingDetails = createReducer(null, {
  [getBookingDetailsSuccess]: (state, {payload}) => payload
})

export const anamnesis = createReducer([], {
  [getConsultAnamnesisSuccess]: (_, { payload }) => payload.data.items,
  [anamnesisDisabledSuccess]: () => [],
  [resetAnamnesis]: () => [],
})

export const isAnamnezDisabled = createReducer(false, {
  [getConsultAnamnesisSuccess]: (_, { payload }) => payload?.data?.items?.length === 0,
  [anamnezDisabledSuccess]: () => true,
})

export const usedAnamnesis = createReducer([], {
  [setUsedAnamnesis]: (state, { payload }) => [...state, ...payload],
})

export const allConsultationInfo = createReducer(null, {
  [getAllConsultationInfoSuccess]: (_, {payload}) => payload?.entity
})

export const currentConsultation = createReducer(null, {
  [getAllConsultationInfoSuccess]: (_, { payload }) => payload?.entity,
  [resetCurrentConsultation]: () => null,
})

export const drugs = createReducer(null, {
  [getAllConsultationInfoSuccess]: (_, { payload }) => payload?.entity?.drugs?.length ? configureDrugList(payload.entity.drugs) : null,
  [changeDrugQuantity]: (state, { payload }) => {
    const newState = JSON.parse(JSON.stringify(state))
    newState[payload.id].quantity = payload.quantity
    return newState
  }
})

const loading = createReducer(false, {
  [consultationRequest]: (_, __) => true,
  [bookConsultationSuccess]: (_, __) => false,
  [getAllConsultationsSuccess]: (_, __) => false,
  [getConsultationHistorySuccess]: (_, __) => false,
  [getConsultationError]: () => false,
})

const anamnezLoading = createReducer(false, {
  [anamnezRequest]: (_, __) => true,
  [getAnamnesisSuccess]: (_, __) => false,
  [getAllConsultationInfoSuccess]: (_, __) => false
})

const bookedConsultationId = createReducer(null, {
  [bookConsultationSuccess]: (_, { payload }) => payload?.entity?.id,
  [cancelConsultationSuccess]: (_, __) => null,
})

export const isNeedToPay = createReducer(true, {
  [bookConsultationSuccess]: (_, {payload}) => payload?.entity?.isNeedToPay
})

export const fondySrc = createReducer('', {
  [bookConsultationSuccess]: (_, { payload }) => payload?.entity?.payment?.paymentUrl ?? '',
  [resetFondyUrl]: (_, __) => ''
})


export const currentCardId = createReducer(null, {
  [setCardId]: (_, {payload}) => payload
})

export const currentPage = createReducer(null, {
  [getAllConsultationsSuccess]: (_, { payload }) => payload?.current_page,
  [getConsultationHistorySuccess]: (_, {payload}) => payload?.current_page,
})

export const pageCount = createReducer(null, {
  [getAllConsultationsSuccess]: (_, { payload }) => payload?.page_count,
  [getConsultationHistorySuccess]: (_, {payload}) => payload?.page_count,
})

export const isGotInfo = createReducer(null, {
  [gotInfo]: (_, {payload}) => payload
})

export const wasCancelled = createReducer(false, {
  [setCancelledFlag]: (_, {payload}) => payload
})

const priceLoading = createReducer(false, {
  [priceRequest]: (_, __) => true,
  [calculatePrice]: (_, __) => false,
})

const consultationPrice = createReducer(0, {
  [calculatePrice]: (_, {payload}) => payload
})

const initialSpecializationStatus = createReducer(null, {
  [getInitialTypeStatus]: (_, { payload }) => {
    return payload
  }
})

const repaymentInfo = createReducer(null, {
  [repaySuccess]: (_, {payload}) => payload?.entity?.payment.paymentUrl || ''
})

const isDisabledBookingHome = createReducer(false, {
  [disableBookingHome]: (_, {payload}) => payload
})

export const pageCountBySearch = createReducer(1, {
  [getBySearchSuccess]: (_, {payload}) => payload.page_count,
})

export const currentSearchPage = createReducer(1, {
  [getBySearchSuccess]: (_, {payload}) => payload.current_page,
})

export const isEmptySearchResult = createReducer(false, {
  [setIsSearchEmpty]: (_, {payload}) => payload,
  [clearSearch]: (_, {payload}) => false,
})

const disableAnamnezLoading = createReducer(false, {
  [anamnezDisableRequest]: () => true,
  [anamnezDisabledSuccess]: () => false,
})

export default combineReducers({
  consultations,
  bookingDetails,
  anamnesis,
  loading,
  fondySrc,
  isNeedToPay,
  currentCardId,
  currentPage,
  pageCount,
  anamnezLoading,
  isGotInfo,
  bookedConsultationId,
  wasCancelled,
  priceLoading,
  consultationPrice,
  allConsultationInfo,
  drugs,
  initialSpecializationStatus,
  repaymentInfo,
  isDisabledBookingHome,
  itemsBySearch,
  searchLoading,
  pageCountBySearch,
  currentSearchPage,
  isEmptySearchResult,
  usedAnamnesis,
  disableAnamnezLoading,
  isAnamnezDisabled,
  currentConsultation,
})