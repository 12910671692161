import React from 'react';
import '../analysesBooking.scss'
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';

export const StepHeaderComponent = ({
  title,
  formStep,
  setFormStep,
  stepCount,
  onCancelConsultation,
}) => {
  const {t} = useTranslation()
  return (
    <div className='analysisStepTools'>
      {formStep > 1 && (
        <IconButton
          style={{width: "20px", height: "20px", color: '#3288cd'}}
          // style={{ zIndex: "99", position: "absolute", left: "0", top: "50%", transform: "translateY(-50%)" }}
          variant='contained'
          color='primary'
          onClick={() => {
            setFormStep(prev => prev - 1)
            onCancelConsultation && onCancelConsultation()
          }}
          title={t("back")}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <div className='analysisStepTitle'>{title}</div>
      <div className='analysisStepCount'>{`${t("step")} ${formStep}/${stepCount}`}</div>
    </div>
  )
}

export default StepHeaderComponent