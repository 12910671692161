const methodsFromType = {
  'atChat': 0,
  'phoneCall': 1,
  'videoCall': 2,
  'home': 3,
  'hospital': 4,
}

export const getConsultationMethod = (consultType) => {
  if (!consultType) return
  return methodsFromType[consultType]
}
