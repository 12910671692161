import React from 'react'
import { useTranslation } from 'react-i18next'
import './popupPermission.scss'

export const PopupPermissions = () => {
  const {t} = useTranslation()
  return (
    <div className='permissionPopup'>
    <div className='triangle'></div>
      <div className='permissionText'>{t('permissionRequest')}</div>
    </div>
  )
}