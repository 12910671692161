import React, { useRef } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { muteAudio, muteVideo, sendPeerTracks, startVideoChat, endCall, updateCall } from '../../../redux/chat/chat-actions';
import videoActive from '../../../style/icons/VideoChat/videoActive.svg'
import videoMuted from '../../../style/icons/VideoChat/videoMuted.svg'
import microActive from '../../../style/icons/VideoChat/microActive.svg'
import microMuted from '../../../style/icons/VideoChat/microMuted.svg'
import end_сall from '../../../style/icons/VideoChat/end_call.svg'
import make_full_screen from "../../../style/icons/VideoChat/make_full_screen_video.svg";
import close_full_screen from "../../../style/icons/VideoChat/close_full_screen_video.svg";
import chatIcon from "../../../style/icons/VideoChat/chat.svg";
import './videoChat.scss'
import { useState } from 'react';
import reject from '../../../style/icons/Call/reject.svg'


const VideoChat = ({
  startVideoChat,
  muteVideo,
  muteAudio,
  videoStatus,
  audioStatus,
  handleFullScreen,
  fullScreenChat,
  endCall,
  setChatOpenedWithVideo,
  chatOpenedWithVideo,
  isActiveCall,
  updateCall,
  doctorVideoRef,
  userVideoRef,
}) => {
  const patientVideo = React.useRef(null)
  const doctorVideo = React.useRef(null)

  // useEffect(() => {
  //   // if (isActiveCall) {
  //   //   updateCall()
  //   // } else {
  //     setTimeout(() => {
  //     startVideoChat()
  //   }, 500)
  //   // }
  // }, [])

  useEffect(() => {
    if (userVideoRef.current && doctorVideoRef.current && isActiveCall) {
      updateCall({
        patientRef: userVideoRef,
        doctorRef: doctorVideoRef,
      })
    }
  }, [userVideoRef.current, doctorVideoRef.current])

  return (
    <div className={`videoChatContainer ${fullScreenChat ? 'fullscreen' : ''}`}>
      <video
        ref={userVideoRef}
        id='patientVideo'
        playsInline
        autoPlay
        className='patientVideoChat'
        muted
      />
      <video
        ref={doctorVideoRef}
        id='doctorsVideo'
        playsInline
        autoPlay
        className='doctorVideoChat'
      />
      <div className="toolPanel">
        <div className='toolContainer'>
          <img
            onClick={() => muteVideo()}
            src={videoStatus ? videoActive : videoMuted}
            alt="video-tool"
            title={'mute video'}
          />
          <img
            onClick={() => muteAudio()}
            src={audioStatus ? microActive : microMuted}
            alt="micro-tool"
            title={'mute audio'}
          />
          <img
            onClick={() => endCall()}
            style={{ width: "58px", height: "58px" }}
            src={reject} alt="end-call"
            title={'end call'}
          />
        </div>
        <div className='tool2Container'>
          <img
            onClick={() => handleFullScreen()}
            src={fullScreenChat ? close_full_screen : make_full_screen}
            alt="fullscreen"
            title={fullScreenChat ? 'выйти из полноэкранного режима' : 'полноэкранный режим'}
          />
          <img
            onClick={() => setChatOpenedWithVideo(true)}
            className='showChatIcon'
            src={chatIcon}
            alt="open-chat"
            title={'показать чат'}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  videoStatus: state.chat.videoStatus,
  audioStatus: state.chat.audioStatus,
  isActiveCall: state?.chat?.isPeerConnected,
})
const mapDispathToProps = {
  startVideoChat,
  muteVideo,
  muteAudio,
  sendPeerTracks,
  endCall,
  updateCall,
}
export default connect(mapStateToProps, mapDispathToProps)(VideoChat);
