import React from 'react';
import { connect } from 'react-redux';
import answer from '../../../style/icons/Call/answer.svg'
import reject from '../../../style/icons/Call/reject.svg'
import { answerCall, rejectCall } from '../../../redux/chat/chat-actions';

const AudioChat = ({
  callInfo,
  rejectCall,
  answerCall,
  isActive,
}) => {
  return (
    <div className='callChatContainer'>
      {isActive && (
        <audio autoPlay id='doctorAudio'></audio>
      )}
      <div className='callType'>{callInfo.call_type === 'audio' ? 'Аудиозвонок' : "Видеозвонок" }</div>
      <div className="callInfoContainer">
        <img className='callImage' src={callInfo.doctor_photo} alt="" />
        <div className='callSpecialization'>{callInfo.doctor_specialty}</div>
        <div className='callDoctorName'>{callInfo.doctor_name}</div>
        <div className='incomingCall'>Входящий звонок...</div>
      </div>
      <div className='toolsContainer'>
        {/* <img onClick={() => answerCall()} className='toolImage' src={answer} alt="answer" /> */}
        <img onClick={() => rejectCall()} className='toolImage' src={reject} alt="reject" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  callInfo: state.chat.callInfo
})

const mapDispatchToProps = {
  rejectCall,
  answerCall,
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioChat);