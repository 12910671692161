import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit'
import {
  getRelationsSuccess,
} from './relations-actions'

const relations = createReducer([], {
  [getRelationsSuccess]: (_, { payload }) => payload.data.items,
})

export default relations