import api from '../../api'
import { setUsedAnamnesis } from '../consultations/consultations-actions';
import {
  getSupportHistorySuccess,
  getSupportHistoryError,
  getChatHistoryRequest,
  sendMessage,
  getAppointmentChatHistorySuccess,
  getNextPageRequest,
  defineLastPage,
  getBySearchSuccess,
  requestNoResults,
  sendGreetingMessage,
} from './chat-actions'


export const getSupportChatHistory = (userId, page = 0) => async dispatch => {
  console.log(page);
  if (page === 0) {
    dispatch(getChatHistoryRequest())
  } else {
    dispatch(getNextPageRequest())
  }

  try {
    const data = await api.chat.getOperatorChatHistory(userId, page)
    console.log({data});
    if (data?.data?.items?.length > 0) {
      dispatch(getSupportHistorySuccess(data))
    } else {
      dispatch(defineLastPage(true))
    }
  } catch (e) {
    dispatch(getSupportHistoryError(e.message))
  }
}

export const getMessagesBySearch = ({userId, page, startDate, endDate, inSupportChat}) => async dispatch => {
  dispatch(getNextPageRequest())
  const filterQuery = `&startDate=${startDate}${endDate ? `&endDate=${endDate}` : ""}`
  try {
    const data = await api.chat.getOperatorChatHistory(userId, page, filterQuery)
    // if (data?.data?.items?.length > 0) {
      dispatch(getBySearchSuccess(data))
    // } else {
    //   dispatch(requestNoResults(true))
    // }
  } catch (e) {
    console.log(e.message);
  }
}

export const getAppoitmentChatHistory = (appointmentId, page, options) => async dispatch => {
  if (page === 0) {
    dispatch(getChatHistoryRequest())
  } else {
    dispatch(getNextPageRequest())
  }
  
  try {
    const data = await api.chat.getChatHistory(appointmentId, page)
    if (data?.data?.items?.length === 0 && page === 0) {
      dispatch(sendGreetingMessage(options))
    }
    if (data?.data?.items?.length > 0 || page == 0) {
      dispatch(getAppointmentChatHistorySuccess(data))
    } else {
      dispatch(defineLastPage(true))
    }
  } catch (e) {
    console.log(e.message);
  }
}

export const uploadChatFile = (
  messageBody = Object,
  socketBody = Object
) => async dispatch => {
  try {
    const data = await api.chat.chatFileUpload(messageBody)
    dispatch(sendMessage({
      messageBody: {
        ...socketBody,
        message: data.entity.file
      }
    }))
  } catch (e) {
    console.log(e.message);
  }
}

export const uploadSupportFile = (
  messageBody = Object,
  socketBody = Object
) => async dispatch => {
  try {
    const data = await api.chat.chatOperatorFileUpload(messageBody)
    dispatch(sendMessage({
      messageBody: {
        ...socketBody,
        message: data.entity.file
      }
    }))
  } catch (e) {
    console.log(e.message);
  }
}

export const sendPushToOperator = (data) => async dispatch => {
  await api.chat.sendOperatorPushNotification(data)
}


