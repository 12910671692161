import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
    registerSuccess,
    registerError,
    loginSuccess, loginError,
    getCurrentUserSuccess,
    getCurrentUserError,
    logOutSuccess,
    sendCodeSuccess,
    getUserCardsSuccess,
    getRequest,
    logOutError,
    getProfileSuccess,
    setToken,
    getUserRequest,
    logoutRequest,
} from './auth-actions'


const userInfo = createReducer(null, {
    [registerSuccess]: (_, { payload }) => payload?.user,
    [loginSuccess]: (_, { payload }) => payload?.user,
    [logOutSuccess]: () => null,
    [getCurrentUserSuccess]: (_, {payload}) => ({...payload?.data?.items[0]}),
})

const userCards = createReducer([], {
    [getUserCardsSuccess]: (_, {payload}) => payload.data.items,
    [logOutSuccess]: () => [],
})

const isRegisteredNeeded = createReducer(null, {
    [getCurrentUserSuccess]: (_, { payload }) => {
        const userCards = payload?.data?.items
        return (Array.isArray(userCards) && userCards?.length > 0)
            ? false
            : true
    },
    [logOutSuccess]: () => null,
})

const defaultToken = localStorage.getItem('token')

const token = createReducer(defaultToken, {
    [registerSuccess]: (_, { payload }) => payload.token,
    [loginSuccess]: (_, { payload }) => payload.token,
    [logOutSuccess]: (_, __) => null,
    [setToken]: (_, {payload}) => payload.token
})
const error = createReducer(null, {
    [registerError]: (_, action) => action.payload,
    [loginError]: (_, action) => action.payload,
    [logOutError]: (_, { payload }) => payload,
    [getCurrentUserError]: (_, {payload}) => payload
})
const isLoggedIn = createReducer(false, {
    [registerSuccess]: () => true,
    [loginSuccess]: () => false,
    [getCurrentUserSuccess]: () => true,
    [logOutSuccess]: () => false,
})

const isSentCode = createReducer(false, {
    [sendCodeSuccess]: (_, __) => true,
    [logOutSuccess]: () => false,
})

const logoutLoading = createReducer(false, {
    [logoutRequest]: () => true,
    [logOutSuccess]: () => false,
})

const loading = createReducer(false, {
    [getRequest]: (_, __) => true,
    [getUserCardsSuccess]: (_, __) => false,
})

const userLoading = createReducer(false, {
    [getUserRequest]: (_, __) => true,
    [getCurrentUserSuccess]: (_, __) => false
})

const profile = createReducer(null, {
    [getProfileSuccess]: (_, { payload }) => payload?.entity,
    [logOutSuccess]: () => null,
})

const bonuses = createReducer(null, {
    [getProfileSuccess]: (_, { payload }) => payload?.bonuses,
    [logOutSuccess]: () => null,
})

const isUser = createReducer(false, {
    [getCurrentUserSuccess]: (_, {payload}) => (payload?.data?.items && payload.data.items[0]?.id) ? true : false,
    [logOutSuccess]: () => false,
})

export default combineReducers({
    userInfo,
    token,
    isLoggedIn,
    isSentCode,
    userCards,
    loading,
    error,
    profile,
    isRegisteredNeeded,
    isUser,
    userLoading,
    logoutLoading,
})