const CHAT_HOST = process.env.REACT_APP_SIGNAL_SERVER;
const chatPort = process.env.REACT_APP_PORT_CHAT_SOCKET;
const callPort = process.env.REACT_APP_PORT_CALL;
const supportPort = process.env.REACT_APP_PORT_CHAT_SUPPORT;
const personalDoctorPort = process.env.REACT_APP_PORT_CHAT_ATTENDING_DOCTOR;
export const SOCKET_HOST = `${CHAT_HOST}${chatPort}/chat`
export const SOCKET_HOST_CALL = `${CHAT_HOST}${callPort}/call`
export const SOCKET_HOST_SUPPORT = `${CHAT_HOST}${supportPort}/support`
// const chatPort = "8082";
// const callPort = "8083"
// const supportPort = "8084"
// const personalDoctorPort = "8080"
// const CHAT_HOST = "https://stage.signals.zdras.te.ua:";


export const callTypes = {
  CALL: 'call'
}





