import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import Button from '../../ui/Button/Button'
import '../navbar.scss'

import { useDispatch } from 'react-redux'
import { deleteAccount } from '../../../../redux/auth/auth-operations'

const DeleteAccountDialog = ({
    setOpenMenu,
    setDeleteAccountPopupOpen,
    t,
}) => {
    const dispatch = useDispatch()

    const handleDeleteAccount = () => {
        setDeleteAccountPopupOpen(false)
        setOpenMenu(false)
        dispatch(deleteAccount())
    }
    
    return (
        <div className='dropDownContainer'>
            <div className='listTool'>
                <IconButton
                style={{ width: "20px", height: "20px", color: "#3288cd" }}
                variant='contained'
                color='primary'
                onClick={() => setDeleteAccountPopupOpen(false)}
                title={t("back")}
                >
                <ChevronLeftIcon />
                </IconButton>
                <div className='title'>{t("deleteAccountTitle")}</div>
            </div>
            <div>{t("deleteAccountConfirm")}</div>
            <Button
                onClick={handleDeleteAccount}
                type='button'
                className='btn red'
                style={{ marginTop: '20px' }}
                text={t("deleteButton")}
            />
            <Button
                onClick={() => setDeleteAccountPopupOpen(false)}
                type='button'
                className='btn'
                secondary
                style={{ marginTop: '20px' }}
                text={t("cancelButton")}
            />
        </div>
    );
}

export default DeleteAccountDialog