import api from '../../api'
import {
  getSymptomsSuccess,
  getSymptomsError,
  getSymptomsRequest,
} from './symptoms-actions'

export const getSymptoms = (query) => async dispatch => {
  dispatch(getSymptomsRequest())
  try {
    const data = await api.symptoms.getSymptoms(query)
    dispatch(getSymptomsSuccess(data))
  } catch (e) {
    dispatch(getSymptomsError(e.message))
  }
}