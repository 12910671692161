import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit'
import {
  getSymptomsSuccess,
  getSymptomsError,
  getSymptomsRequest,
} from './symptoms-actions'

const symptoms = createReducer([], {
  [getSymptomsSuccess]: (_, { payload }) => payload.data.items,
  [getSymptomsError]: (_, {payload}) => payload
})
const specializations = createReducer([], {
  [getSymptomsSuccess]: (_, { payload }) => payload.specialty.items,
  [getSymptomsError]: (_, {payload}) => payload
})

const loading = createReducer(false, {
  [getSymptomsRequest]: (_, __) => true,
  [getSymptomsSuccess]: (_, __) => false
})

export default combineReducers({
  symptoms,
  specializations,
  loading,
})