import React from "react";
import i18n from "../../../i18n";

import logo from "../../../style/icons/footerLogo.svg";

import "./errorBoundary.scss";

export default class ErrorBoundary extends React.Component {
  state = {
    errorCatch: false,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorCatch: true,
    });
  }

  render() {
    if (this.state.errorCatch) {
      return (<>
        <div className='errorPageContainer'>
          <div className="errorWrapper">
            <img className="errorLogo" src={logo} alt="DobroDoc logo" />
             <h1 className="error-title">{i18n.t("errorBoundary")}</h1>
          </div>
        </div>
      </>);
    }
    return this.props.children;
  }
}