import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  icon: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#3288cd',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectItem: {
    background: "#f4f9fd",
    color: "#3288cd",
    '&:hover': {
      background: "#f4f9fd"
    }
  },
  menuStyle: {
    boxShadow: "0px 0px 4px 1px #3287cd69",
    color: "#3288cd",
    borderRadius: "4px",
    backgroundColor: '#f4f9fd',
    fontSize: "15px",
    // width: "100%",
    // paddingLeft: "7px",
  },
  selectControl: {
    maxHeight: "100px",
    paddingLeft: "7px",
  },
  select: {
    textAlign: "center",
    width: "70px",
    paddingBottom: "-2px",
    // paddingLeft: "7px",
    minWidth: "70px",
    // minHeight: "30px",
    height: "100%",
    // paddingLeft: "5px",
    background: "#f4f9fd",
    borderRadius: "8px",
    fontSize: "16px",
    color: "#3288cd",
    "& .MuiSelect-select:focus": {
      backgroundColor: "#f4f9fd",
    },
    margin: "0 auto",
  }
})

export default useStyles;