import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit'
import {
  getSpecializationsSuccess,
  getSpecializationsError,
  clearSearch,
  setSpecializationsDiscount,
} from './specializations-actions'
import {
  getSymptomsSuccess,
} from '../symptoms/symptoms-actions'
import { getSetFromLocalStorage } from "../../utils/helpers";

const specializationsReducer = createReducer(null, {
  [getSpecializationsSuccess]: (_, { payload }) => {
    let newItems = payload.data.items
    const specialtyDiscounts = getSetFromLocalStorage("specializationDiscount", "object")
    if (specialtyDiscounts) {
      newItems = payload.data.items.map((specialty) => specialtyDiscounts[specialty.id] ? ({
        ...specialty,
        discount: specialtyDiscounts[specialty.id].discount,
        promocode: specialtyDiscounts[specialty.id].promocode,
      }) : specialty)
    }
    return {
      ...payload.data,
      items: newItems,
    }
  },
  [getSpecializationsError]: (_, { payload }) => payload,
  [setSpecializationsDiscount]: (state, { payload }) => {
    const { idsList, discount, promocode } = payload;
    const localStorageInfo = JSON.stringify(
      idsList.reduce((acc, id) => {
        acc[id] = ({ discount, promocode })
        return acc
      }, {})
    )
    localStorage.setItem("specializationDiscount", localStorageInfo)
    return {
      ...state,
      items: state.items.map((specialty) => idsList.includes(specialty.id) ? ({
        ...specialty,
        discount: discount,
        promocode: promocode,
      }) : specialty)
    }
  },
})

const specializationsBySearch = createReducer(null, {
  [getSymptomsSuccess]: (_, { payload }) => payload?.specialty || null,
  [clearSearch]: (_, __) => null
})

const reseted = createReducer(false, {
  [clearSearch]: (state) => !state,
})

// export default specializationsReducer
export default combineReducers({
  items: specializationsReducer,
  itemsBySearch: specializationsBySearch,
  reseted,
});