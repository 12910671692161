import { createSelector } from "@reduxjs/toolkit";

const defaultUser = {
  userInfo: null,
  userCards: [],
  token: null,
  isLoggedIn: false,
  isSentCode: false,
  loading: false,
  profile: null,
  bonuses: null,
  isRegisteredNeeded: false,
  isUser: false,
  userLoading: false,
  logoutLoading: false
};

const defaultState = { user: { ...defaultUser }};

export const getLogoutLoading = (state) => state.user.logoutLoading;

const getUser = (state = defaultState) =>
  state.user;

export const getUserCardsSelector = createSelector(
  [getUser],
  ({userCards}) => userCards
);

export const getIsToken = createSelector(
  [getUser],
  ({token}) => !!token
);

export const getIsUser = createSelector(
  [getUser],
  ({isUser}) => isUser
);

export const getIsUserLoading = createSelector(
  [getUser],
  ({userLoading}) => userLoading
);

export const getIsRegisterCardNeeded = createSelector(
  [getUser],
  ({isRegisteredNeeded}) => isRegisteredNeeded
);
export const getUserMainCardSelector = createSelector(
  [getUser],
  ({userCards}) => userCards.find(el => el?.relationShip?.id === 1)
)
export const getCardsLoading = createSelector(
  [getUser],
  ({loading}) => loading
)
