import { TailSpin } from "react-loader-spinner";

const Spinner = ({ width, height, color }) => {
  return (
    <TailSpin
      color={`${color ? color : "#3288CD"}`}
      height={height ?? 60}
      width={width ?? 60}
      timeout={20000}
    />
  );
};

export default Spinner;
