import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const resources = {
  ru: {
    translation: {
      activatePromocode: "Активировать промокод",
      forConsultation: "на Консультацию",
      forSubscription: "на Подписку",
      forAllConsultation: "Распространяется на все специальности",
      promocodeNotExist: "Введен несуществующий промокод",
      connection: "Соединение",
      internetError: "Нет подключения к Интернету",
      emptySearchResults: "Поиск не дал результатов",
      filled: "Заполнена",
      bookAgain: "Заказать заново",
      bookingError: "Возникла ошибка при заказе",
      fishkaAvailability: "Доступно баллами Fishka, грн",
      fishkaResetPoints: "Списать баллами Fishka, грн",
      fishkaWillReceive: "Будет начислено баллами Fishka, грн",
      fishkaCodeError: "Код должен состоять из 6 цифр",
      searchInConsultations: "Искать в консультациях",
      searchInNotifications: "Искать в уведомлениях",
      searchByDate: "Искать по дате",
      authReg: "Регистрация/Авторизация",
      maxCardsText: "Максимально допустимое количество медкарт: 7",
      orDragNDropHere: "или перетащите сюда",
      disabledSpecialization: "На данный момент специализация недоступна в Вашем городе",
      district: 'Район',
      chooseDistrict: "Выберите район",
      chooseCity: "Выберите город",
      cardsText: "Забудьте об очередях в регистратуре и потерянную медицинскую карту, история болезней и назначения врача теперь всегда в вашем смартфоне.",
      whyDoWeNeedCards: "Зачем нужны медкарты?",
      endConsult: 'Консультация завершена. ',
      noContent: "Нет информации",
      notAvailable: "Нет в наличии",
      fishkaError: "Значение недопустимо",
      received: "Получить",
      fishkaPoints: "грн. баллами Fishka",
      fishkaPolicyText: "Я согласен на передачу своих персональный данных организатору программы лояльности Fishka для их обработки и регистрации меню в Fishka на условиях программы",
      byFishka: "Оплатить баллами Fishka",
      fishkaPercent: "Будет начислено, грн баллами Fishka",
      fishkaSuccessText: "Вы успешно зарегистрированы в программе лояльности Fishka",
      registerAction: "Зарегистрироватся",
      fishkaTitle: "Программа лояльности Fishka",
      fishkaDescription: "это программа лояльности, которая объединяет более 6 млн. клиентов, уоторые делают свои ежедневные покупки более выгодными",
      choosePhoto: "Выбрать фото",
      deleteCard: "Удалить медкарту",
      purchasePeriod: "Период приобретения пакета",
      noPurchased: "Нет купленных",
      errorBoundary: "Извините, произошла ошибка, пожалуйста перезагрузите страницу. Если это не поможет, обратитесь в службу поддержки по телефону 0800330036.",
      receivedFromDoctor: 'Вам пришло сообщение от доктора',
      addressCreated: "Адрес успешно добавлен",
      activeCards: "Активные медкарты",
      informPart1: 'Регистрируясь, Вы даете согласие на ',
      informPart2: 'обработку персональных данных ',
      informPart3: 'и согласны c ',
      informPart4: 'политикой конфиденциальности',
      confirmButton: "Подтвердить",
      appartmentIsIncorrect: "Некорректная квартира",
      subscriptionSuccess: "Подписка успешно оформлена",
      ///subscription
      P6M: "6 месяцев",
      P9M: "9 месяцев",
      P3M: "3 месяца",
      P12M: "12 месяцев",
      periodActive: "Период активности",
      fromTo: 'Срок действия подписки',
      noDoctors: "К сожалению, по данной специальности на данный момент недоступен ни один врач",
      //validates
      permissionRequest: "У вас не включены Уведомления, Видео и Аудио на этом сайте. Нажмите на кнопку слева от поля поиска чтобы включить их.",
      downloadCSV: "Скачать csv",
      requiredLoginValidate: "Введите логин",
      dd_mm_yyyyValidate: "Дата должна быть в формате хх/хх/хххх",
      phoneFormatValidate: "Номер телефона должен быть в формате +380ХХХХХХХХХ",
      durationTimeInMinValidate: "Количество минут должно быть до 60 мин",
      timeInMinValidate: "Время должно быть в формате 23ч:59 мин",
      emailValidate: "Неправильный формат e-mail",
      requiredPasswordValidate: "Введите пароль",
      requiredValidate: "Это обязательное поле",
      notNegativeValidate: "Не может быть отрицательным числом",
      createShiftButton: "Создать смену",
      cancelOfConsultation: "Отменить консультацию",
      consultationDate: "Время консультации",
      pushTitle: "Заголовок уведомления",
      createPassword: "Установите пароль для вашего аккаунта",
      month: "Месяца",
      newSubscription: "Новая подписка",
      percentileDiscount: "Процент скидки",
      priceDiscount: "Цена подписки",
      company: "Компания",
      //sendPDFFile
      exportButton: "Экспортировать",
      //Companies
      fileUploaded: "Файл успешно загружен",
      subscriptionEdited: "Компания успешно обновлена",
      description: "Описание",
      noDescription: "Описания пока нет",
      // ERRORS
      codeNotCorrect: "Неверный код",
      birthDateInFuture: "Невозможно родиться в будущем",
      templateTitle: "Шаблоны",
      min: "мин",
      from: "с",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону",
      medQuestion: "Мед. вопрос",
      techSupport: "Техподдержка",
      infoMedQueation: "B случае, если хотите уточнить медицинский вопрос",
      missedCall: "Пропущенный звонок",
      canceledCall: "Отменённый вызов",
      canceledConsultationMessage:
        "Уважаемый пациент, ваша консультация была отменена врачом.",
      finishConsultationMessage:
        "Ваша консультация завершена. Заключение врача: ",
      //ConsultConfirmation
      consultConfirmation: "Консультация назначена на ",
      doctorConfirmation: "Вы записаны к доктору ",
      at: "на",
      Января: "Января",
      Февраля: "Февраля",
      Марта: "Марта",
      Апреля: "Апреля",
      Мая: "Мая",
      Июня: "Июня",
      Июля: "Июля",
      Августа: "Августа",
      Сентября: "Сентября",
      Октября: "Октября",
      Ноября: "Ноября",
      Декабря: "Декабря",
      //Sider
      shifts: "Расписание",
      employees: "Сотрудники",
      specializations: "Специализации",
      allSpecialties: "Все специальности",
      subscriptions: "Подписки",
      rates: "Тарифы",
      patients: "Пациентов",
      promocodes: "Промокоды",
      onboarding: "Онбоардинг",
      client: "Клиент",
      //=====
      //MODAL
      helpModalText_1:
        "В случае, если нужна техническая поддержка - просьба позвонить по телефону:",
      helpModalText_2: "В случае, если хотите уточнить медицинский вопрос:",
      techSupportButton: "Техподдержка",
      medQuestionButton: "Мед. вопрос",
      help: "Помощь",
      //NetworkErrorPopup
      errorCode: "Ошибка. Код:",
      errorDescription: "Описание ошибки:",
      //UserTool
      notifications: "Уведомления",
      //Validate
      addressValidate: "Все поля с * должны быть заполнены",
      //Address Component
      address: "Адрес",
      city: "Город",
      district: "Район",
      street: "Улица",
      building: "Дом",
      apartment: "Квартира",
      codeOrNote: "Код или примечание",
      //СardData
      noPayment: "Не требует оплаты",
      currentDiseaseHistory: "Анамнез текущего заболевания",
      allergicDiseases: "Аллергические реакции",
      medicalHistory: "Перенесённые заболевания",
      treatmentPlan: "План лечения",
      recommendations: "Рекомендации",
      //Client
      warning: "Внимание!",
      consultationIsNotPayed: "Косультация не была оплачена",
      consultationCard: "Карта консультации",
      cardData: "Данные карты",
      //HistoryItemDetails
      consultationDetails: "Детали консультации",
      onlineConsultation: "Онлайн консультация",
      consultationWithDoctor: "Консультация с доктором",
      complaints: "Жалобы",
      presumptiveDiagnosis: "Предположительный диагноз (по МКБ-10)",
      TreatmentPlan: "План лечения",
      conclusion: "Вывод",
      medications: "Препараты",
      examinations: "Исследования",
      tests: "Анализы",
      specialtyRecommend: "Рекомендованные врачи",
      //History
      january: "января",
      february: "февраля",
      march: "марта",
      april: "апреля",
      may: "мая",
      june: "июня",
      july: "июля",
      august: "августа",
      september: "сентября",
      october: "октября",
      november: "ноября",
      december: "декабря",
      //Anamnez
      medCardTitle: "Медицинская карта",
      anamnez: "Анамнез",
      history: "История",
      deleteConfirm: "Вы уверены, что хотите удалить карту?",
      //MedCard
      sex: "Пол",
      maleSex: "Мужской",
      femaleSex: "Женский",
      //Monitoring
      monitoring: "Мониторинг",
      patient: "Пациент",
      cause: "Причина обращения",
      status: "Статус",
      new: "Новая",
      newConsultation: "Новая",
      filled: "Заполнена",
      filledConsultation: "Заполнена",
      closed: "Завершена",
      finishSaveButton: "Завершить и сохранить",
      closedConsultation: "Завершенная",
      in_process: "В процессе",
      inProgressConsultation: "В процессе",
      cancelled: "Отменена",
      cancelledConsultation: "Отменена",
      //Specializations
      newSpecialty: "Новая специализация",
      addSpecialty: "Добавить специализацию",
      title: "Наименование",
      priceOnline: "Стоимость online-консультации",
      priceHome: "Стоиомость вызова на дом",
      priority: "Приоритет",
      showOnMainScreen: "Показывать на главном экране",
      showOnDoctorsList: "Показывать в списке врачей",
      //Banners
      banners: "Баннеры",
      //Subscribers
      fullName: "Ф.И.О.",
      policeStartDate: "Время начала действия полиса",
      policeExpireDate: "Время окончания действия полиса",
      email: "Электронный адрес",
      birthDate: "Дата рождения",
      insuranceCompany: "Название страховой компании",
      authorization: "Авторизация",
      scheduleListTitle: "Смены",
      specializationTitle: "Доктор",
      appointmentsTime: "Смена",
      durationTitle: "Длительность консультации",
      filterToday: "На сегодня",
      filterTomorrow: "На завтра",
      filterWeek: "На неделю",
      filterAll: "Все",
      filterBooked: "Купленные",
      filterFormTitle: "Фильтр",
      filterAllSchedules: "Все смены",
      filterTypeOfService: "Желаемый формат",
      common: "Общее",
      name: "Имя",
      lastName: "Фамилия",
      patronymic: "Отчество",
      gender: "Пол",
      experience: "Опыт работы",
      old: "Лет",
      specializationDescription: "Описание",
      phoneNumber: "Номер телефона",
      // Specialty
      cost: "Стоимость",
      priceType: "Онлайн-консультация",
      callOnHome: "Вызов на дом",
      doctor: "Доктор",
      addSchedule: "Добавить смену",
      placeholderSpecialties: "Специализации",
      placeholderSpecialty: "Специализация",
      placeholderSearch: "Поиск",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Визит на дом",
      optionsServiceTypeInVideo: "Видеозвонок",
      optionsServiceTypeCall: "Звонок",
      optionsServiceTypeInChat: "В чате",
      optionsServiceTypeInHospital: "Визит в клинику",
      newScheduleFilterFormTitle: "Новая смена",
      newScheduleFilterFormTitleEdit: "Редактирование",
      manually: "Вручную",
      fromFile: "Из файла",
      medcardSuccessUpdate: 'Медкарта была успешно обновлена',
      duration: "Длительность консультации",
      date: "Дата смены",
      timeStart: "Время начала",
      timeEnd: "Время окончания",
      fileUploaderTitle: "Загрузка файла .xlsx",
      // Employees
      employeesFlowTitle: "Сотрудники",
      experienceIsRequire: "Опыт обязателен",
      buildingIsIncorrect: "Некорретный дом",
      experienceIsIncorrect: "Опыт превышает максимально возможный",
      operators: "Операторы",
      operator: "Оператор",
      operatorAndAdmin: "Оператор / Админ",
      phone: "Телефон",
      //Auth
      login: "Вход",
      loginError: "Неверный логин",
      passwordError: "Неверный пароль",
      //onBoarding
      edit: "Редактировать",
      showInList: "Показать в списке",
      //PromoCodes Page
      promocodeStatus: "Статус промокода",
      promocodeStatus_0: "Не использован",
      promocodeStatus_1: "Использован",
      promocodeType: "Тип промокода",
      promocodeType_0: "Подписка",
      promocodeType_1: "Консультация",
      promocodeName: "Промокод",
      promocodeCreated: "Создан",
      promocodeExpityDate: "Действителен до",
      promocodeCount: "Кол-во промокодов",
      promocodeFrom: "Выдан",
      deletePromocode: "Удалить",
      //Symptoms Page
      noTitle: "Нет названия",
      chooseSpecialty: "Выберите специальность",
      symptomsAndSpeciality: "Специализации и симптомы",
      symptoms: "Симптомы",
      emptyList: "Список пуст",
      existingSymptoms: "Существующие симптомы",
      //Placeholders
      placeholderLogin: "Логин",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторите пароль",
      placeholderAddSymptom: "Добавить симптом",
      placeholderText: "Текст",
      placeholderNumber: "Введите число от 0 до 100",
      placeholderLink: "Ссылка",
      placeholderTitle: "Заголовок",
      //Feedbacks
      placeholderFeedbacks: "Отзывы",
      //Buttons
      addMedicalCard: "Добавить медкарту",
      addButton: "Добавить",
      changeButton: "Изменить",
      saveButton: "Сохранить",
      deleteButton: "Удалить",
      editButton: "Редактировать",
      closeButton: "Закрыть",
      createButton: "Создать",
      acceptButton: "Применить",
      // OPERATOR
      requestCreated: "Заявка создана",
      mainCard: "Главная медкарта",
      relations: "Степень родства",
      adress: "Адрес",
      policeNumber: "№ полиса",
      insurance: "Компания/страховка",
      registration: "Оформление",
      //Specializations
      activePeriod: "Срок действия",
      active: "АКТИВНАЯ",
      inActive: "НЕ АКТИВНАЯ",
      priceTitle: "Стоимость",
      //Placeholders
      success: "Успех",
      placeholderMedCard: "Медкарта",
      bookDate: "Дата бронирования",
      paymentType: "Способ оплаты",
      hospitals: "Клиники",
      hospitalDepartments: "Отделения клиники",
      //Buttons
      arrangeButton: "Оформить консультацию",
      goodButton: "Хорошо",
      cancelButton: "Отменить",
      placeholderChatMessage: "Введите сообщение",
      placeholderImageOrVideo: "Выберите изображение или видеозапись",
      placeholderDocument: "Выберите документ",
      chat: "Чат",
      changeLang: "Язык",
      exitButton: "Выйти",
      firstNameIsIncorrect: "Некорректное имя",
      lastNameIsIncorrect: "Некорректная фамилия",
      patronymicIsIncorrect: "Некорректное отчество",
      phoneIsIncorrect: "Номер телефона +380*********",
      phoneIsRequire: 'Введите номер телефона',
      overstatedExperience: 'Завыщен опыт',
      emailIsIncorrect: 'Некорректно набран email',
      firstNameRequire: "Введите имя",
      lastNameIsRequire: "Введите фамилия",
      patronymicIsRequire: "Введите отчество",
      emailIsRequire: "Введите email",
      birthDateIsIncorrect: 'Некорректная дата рождения',
      birthDateIsRequire: 'Введите дату рождения',
      ////NEW TRANSLATIONS
      code: "код",
      buy: "Купить",
      medicalCards: "Медкарты",
      Next: "Далее",
      PersonalData: "персональных данных",
      PrivacyPolicy: "политикой конфиденциальности",
      Agreement: "Регистрируясь, вы даете согласие на обработку %@ и согласны с %@",
      Registration: "Регистрация",
      // InputSmsCode: "Введите смс код",
      ResendMessage: "Получить смс еще раз",
      CreatePassword: "Создать пароль",
      ForSecure: "Для защиты ваших персональных данных",
      Login: "Вход",
      Clinic: "Клиника",
      doctorTypeEndocrin: "Эндокринолог",
      doctorTypeLor: "Лор",
      doctorTypeFamilyDoctor: "Семейный врач",
      doctorTypeGinecolog: "Гинеколог",
      whatIsYourDisturbing: "Что вас беспокоит?",
      whatSymptomWorriesYou: "Какие симптомы вас беспокоят ?",
      frequentlyAskedRequests: "Частые запролсы",
      analysises: "Анализы",
      ourDoctors: "Наши доктора",
      // medicalCards: "Медицинские карты",
      whoNeedDoctor: "Кому нужен врач?",
      createMedicalCard: "Создать медкарту",
      updateMedicalCard: "Обновить медкарту",
      theNewMedicalCard: "Новая медкарта",
      Lastname: "Фамилия",
      Name: "Имя",
      Surname: "Отчество",
      Phone: "Телефон",
      Email: "E-mail",
      Birthday: "Дата рождения",
      Sex: "Пол",
      Relationship: "Cтепень родства",
      Address: "Адрес",
      NumberOfPolice: "№ полиса",
      Notifications: "Уведомления",
      Account: "Учетная запись",
      Talks: "Разговоры",
      Man: "Мужской",
      Woman: "Женский",
      Me: "Я",
      Son: "Сын",
      Daughter: "Дочь",
      Husband: "Муж",
      Wife: "Жена",
      OtherRelationship: "Другое родство",
      Complete: "Завершить",
      legalInformation: "Юридическая информация",
      aboutCompany: "О нас",
      FAQ: "Вопросы и ответы",
      // legalInformation: "Legal information",
      EnterPasscode: "Введите свой пин-код",
      ConfirmPasscode: "Подтвердите ваш пин-код",
      InputSmsCode: "Введите код из смс",
      ResendCode: "Отправить код еще раз",
      sendCode: "Отправить код по смс",
      Error: "Ошибка",
      Consultations: "Консультации",
      medcardSuccessCreate: 'Медкарта была успешно создана',
      Support: "Поддержка",
      Medcard: "Медкарта",
      Confirm: "Подтвердить",
      AddPhoto: "Добавить фото",
      changePhoto: "Изменить фото",
      SelectFromGallery: "Выбрать из галереи",
      TakePhoto: "Сфотографировать",
      Delete: "Удалить",
      City: "Город",
      cityIsIncorrect: "Некорректная улица",
      Street: "Улица",
      Building: "Дом",
      Flat: "Квартира",
      Stage: "Этаж",
      stageIsIncorrect: "Некорректный этаж",
      EntranceNumber: "№ подъезда",
      WayDetails: "Как добраться",
      DefineMyLocation: "Определить мое местоположение",
      AddAddress: "Добавить адрес",
      cityShort: "г.",
      streetShort: "ул.",
      houseShort: "д.",
      flatShort: "кв.",
      medicalTests: "Анализы",
      bookAnalysis: "Заказать анализы",
      whatAnalysisYouNeed: "Какие анализы Вам нужны?",
      whatDrugsYouNeed: "Какие препараты Вам нужны?",
      step: "Шаг",
      findAnalysis: "Найти анализ",
      analysisName: "Наименование",
      analysisPrice: "Цена, грн",
      subscriptionPrice: "Стоимость, грн",
      specialties: "Специализации",
      analysisTime: "Срок, дни",
      price: "Стоимость",
      inLab: "В пункте забора",
      atHome: "На дом",
      analysisAddress: "Пункт забора",
      howToTake: "Как будете забирать?",
      selectTakePlace: "Выберите пункт забора",
      enterYourAddress: "Введите Ваш адрес",
      book: "Оформить заказ",
      confirmInformation: "Подтверждение информации",
      howToTakeSummary: "Как собрать анализы",
      analysisSuccessBooking: "Анализы успешно заказаны!",
      drugsSuccessBooking: "Препараты успешно заказаны!",
      myConsulattions: "Мои консультации",
      today: "Сегодня",
      tomorrow: "Завтра",
      yesterday: "Вчера",
      enterMessage: "Введите сообщение",
      callEnded: "Звонок завершен",
      // doctor: "Доктор",
      selectClinicAddress: "Введите адрес клиники",
      // subscriptions: "Подписки",
      faceIdReason: "Авторизация в приложении",
      logIn: "Войти",
      faceIdNotSuccess: "Не удалось авторизировать пользователя",
      faceIdPolicy: "Не удалось получить разрешение",
      faceIdNotAvailable: "Эта возможность недоступна",
      noDoctorsAvailable: "Все врачи заняты",
      doctorGreetingFirstPart: "Здравствуйтe! Я Ваш врач –",
      doctorGreetingSecondPart: "Консультация начнется",
      // at: "в",
      doctorGreetingThirdPart: "По возможности опишите Ваше самочувствие и жалобы заранее. При наличии результатов анализов или обследований, добавьте их, пожалуйста, в этот чат. До встречи!",
      consultationHistory: "История консультаций",
      lifeAnamnesis: "Анамнез заболевания",
      diagnos: "Предварительный диагноз (По МКБ-10)",
      treatment: "План лечения",
      drugs: "Препараты",
      tools: "Инструментальные исследования",
      analysis: "Анализы",
      historyIsEmpty: "Врач еще не заполнил историю Вашей консультации.",
      photoOrVideo: "Фото или видео",
      file: "Файл",
      files: "Файлы",
      cancel: "Отмена",
      back: "Назад",
      thanks: "Спасибо!",
      payment: "Оплата",
      paymentDesc: "Выбраная Вами консультация не была оплачена. Желаете оплатить?",
      yes: "Да",
      no: "Нет",
      purchase: "Приобрести",
      showMore: "Читать еще",
      showLess: "Свернуть",
      inProcess: "В процессе",
      whoNeedSubscription: "Кому нужна подписка?",
      noInternetError: "Отсутствует подключение к интернету.",
      recommendedDoctors: "Рекомендованные врачи",
      undefinedGender: "Неопределенный",
      selectClinic: "Выберите клинику",
      clinicDepartments: "отделений",
      clinicDepartment: "отделение",
      enterClinicAddress: "Введите адрес клининки",
      disconnectFromChat: "Вас отключили от чата.",
      selectMedicalCard: "Выберите медкарту",
      selectConsultationType: "Выберите вид консультации",
      whatTreats: "Что лечит",
      online: "Онлайн",
      inClinic: "В клинике",
      bookConsultation: "Заказать консультацию",
      bookConsultationOnline: "Заказать онлайн консультацию",
      bookConsultationInClinic: "Заказ визита в клинику",
      bookConsultationAtHome: "Вызвать врача на дом",
      selectDoctor: "Выбрать врача",
      randomDoctor: "Случайный врач",
      selectDoctorTitle: "Выбор врача",
      selectAddress: "Выберите Ваш адрес",
      selectConvenientTime: "Выберите удобное время",
      nearestTime: "Ближайшее время",
      selectTime: "Выбрать дату и время",
      selectDesiredTime: "Выбрать желаемую дату и время",
      inChat: "В чате",
      call: "Звонок",
      videocall: "Видеозвонок",
      dateAndTime: "Дата и время",
      dateAndDesiredTime: "Желаемая дата и время",
      enterDoctorName: "Введите имя врача",
      informationConfirm: "Подтверждение информации",
      specialty: "Специальность",
      consultationType: "Вид консультации",
      // date: "Дата",
      time: "Время",
      place: "Место",
      onlineBookingSuccess: "Онлайн консультация успешно заказана!",
      atHomeBookingSuccess: "Врач на дом успешно заказан!",
      inClinicBookingSuccess: "Визит в клинику успешно заказан!",
      qaId: "Вопросы и ответы",
      byPromocode: "Оплата промокодом",
      byCard: "Оплата картой",
      logoutButton: "Выход",
      branchesCount: "Количество отделений:",
      appVersion: "Версия приложения",
      promoButton: "Ввести промокод",
      insertPromo: "Введите промокод",
      subscription: "Подписка",
      noSctiveSubscriptions: "Нет активных подписок",
      enterPromocodeDesc: "Введите Ваш промокод для того, чтоб активировать выбранную подписку",
      activate: "Активировать",
      successActivation: "Активация успешна",
      promocodeSuccess: "Уважаемый пользователь! Вы успешно активировали промокод и получили подписку на пользование. Спасибо!",
      promocode: "Промокод",
      promocodeError409: "Даная подписка уже доступна для Вас",
      promocodeError400: "Введенный код недействительный",
      data: "Данные",
      language: "Язык",
      news: "Новости",
      incomingCall: "Входящий звонок",
      whyDoWeNeedAnalysis: "Зачем нужны анализы?",
      analysisDescription: "Лаборато́рная диагно́стика — совокупность методов, направленных на анализ исследуемого материала с помощью различного специализированного оборудования. Профессиональный праздник лаборанты отмечают 25 октября. Биохими́ческий ана́лиз кро́ви — лабораторный метод диагностики, применяемый в клинической практике для определения функционального состояния систем и тканей организма человека. Биохимический анализ помогает выявить нарушение функции печени, почек, расстройство белкового, липидного и углеводного обмена, диагностировать наличие активного воспалительного процесса, в том числе ревматического, а также различные расстройства водно-электролитного обмена и степень дисбаланса микроэлементов. Таким образом, проведение биохимического анализа крови позволяет не только выявить патологию, но и определить степень остроты воспалительного процесса, назначить или скорректировать лечение.",
      nobodyFound: "Никого не найдено",
      newAppointment: "Новая консультация",
      newAppointmentGuide: "Уважаемый пользователь! Вы забронировали консультацию. Пожалуйста, перейдите в чат с врачом и ответьте на некоторые вопросы. Это поможет врачу во время консультации",
      nothingFound: "Ничего не найдено",
      frequentRequests: "Частые запросы",
      noAppointments: "У Вас пока что нет консультаций.",
      pay: "Оплатить",
      promocodeAppointment: "Введите Ваш промокод для того, чтоб забронировать выбранную консультацию",
      doctors: "Врачи",
      doctorDescription: "Описание",
      doctorEducation: "Образование",
      doctorExperience: "Опыт",
      doctorYears: "Лет",
      turnOfChatBot: "Отключить вопросы",
      chatBotTurnedOff: "Вопросы выключены",
      versionTitle: "Доступно обновление",
      versionDescription: "Ми опубликовали новую версию даного приложения. Для того, чтоб продолжить использовать его, пожалуйста, скачайте обновление из AppStore.",
      updateApp: "Update app",
      personalDoctor: "Личный врач",
      leaveFeedback: "Оставьте отзыв",
      enterFeedback: "Введите отзыв",
      addToCalendar: "Добавить событие в календарь",
      inClinicConsultation: "Консультация в клинике у врача",
      inClinicConsultationDesc: "по адресу",
      copyAddress: "Копировать адрес",
      noDoctorInClinic: "В данной клинике нет возможности забронировать докторов выбранной специализации",
      successAddToCalendar: "Событие успешно добавлено в приложение Календарь",

      pharmacy: "Аптека",
      pharmacyCount: "Количество,\nшт",
      bookPreparats: "Заказать препараты",
      whatPreparatsYouNeed: "Какие препараты Вам нужны?",
      findPreparats: "Найти препарат",
      howToTakePreparats: "Как собрать препараты",
      preparatsSuccessBooking: "Заказ принят",
      preparatsErrorBooking: "Сбой заказа. Просим вернуться на предыдущую страницу",
      consultationConclusion: 'Заключение',

      deleteAccount: 'Удалить аккаунт',
      deleteAccountTitle: 'Удаление аккаунта',
      deleteAccountConfirm: 'Вы уверены, что хотите навсегда удалить учетную запись и все связанные данные?'
    },
  },
  ua: {
    translation: {
      activatePromocode: "Активувати промокод",
      forConsultation: "на Консультацію",
      forSubscription: "на Підписку",
      forAllConsultation: "Поширюється на всі спеціальності",
      promocodeNotExist: "Введено неіснуючий промокод",
      connection: "Підключення",
      internetError: "Немає підключення до Інтернету",
      emptySearchResults: "Пошук не дав результатів",
      filled: "Заповнена",
      bookAgain: "Заказати ще раз",
      bookingError: "Виникла помилка при заказі",
      fishkaWillReceive: "Буде начислено балами Fishka, грн",
      fishkaResetPoints: "Списати балами Fishka, грн",
      fishkaAvailability: "Доступно балами Fishka, грн",
      fishkaCodeError: "Код має містити 6 символів",
      searchInConsultations: "Шукати в консультаціях",
      searchInNotifications: "Шукати в повідомленнях",
      searchByDate: "Шукати по даті",
      authReg: "Реєстрація/Авторизація",
      maxCardsText: "Максимально допустима кількість медкарт: 7",
      orDragNDropHere: "або перетягніть сюди", 
      disabledSpecialization: "Наразі спеціалізація недоступна у Вашому місті",
      chooseDistrict: "Виберіть район",
      chooseCity: "Виберіть місто",
      district: 'Район',
      cardsText: "Забудьте про черги у реєстратурі та загублену медичну картку, історія хвороб та призначення лікаря завжди у вашому смартфоні.",
      whyDoWeNeedCards: "Навіщо потрібні медкарти?",
      endConsult: 'Консультація завершена. ',
      noContent: "Немає інформації",
      notAvailable: "Немає в наявності",
      fishkaError: "Недопустиме значення",
      received: "Отримати",
      fishkaPoints: "грн. балами Fishka",
      fishkaPolicyText: "Я згоден на передачу своїх персональний даних організатору програми лояльності Fishka для їх обробки і реєстрації меню в Fishka на умовах програми",
      byFishka: "Оплатити балами Fishka",
      fishkaPercent: "Буде нараховано, грн балами Fishka",
      fishkaSuccessText: "Ви успішно зареєстровані в програмі лояльності Fishka",
      registerAction: "Зареєструватись",
      fishkaTitle: "Програма лояльності Fishka",
      fishkaDescription: "це програма лояльності, яка об'єднує понад 6 млн. клієнтів, які роблять свої щоденні покупки більш вигідними",
      choosePhoto: "Виберіть фото",
      deleteCard: "Видалити медкарту",
      purchasePeriod: "Період придбання пакета",
      noPurchased: "Немає куплених",
      errorBoundary: "Вибачте, сталася помилка, будь ласка перезавантажте сторінку. Якщо це не допоможе, зверніться в службу підтримки по телефону 0800330036.",
      receivedFromDoctor: 'Вам надійшло повідомлення від доктора',
      medcardSuccessCreate: 'Медкарта була успішно створена',
      news: "Новини",
      addressCreated: "Адреса успішно додана",
      activeCards: "Активні медкарти",
      subscriptionSuccess: "Підписка успішно оформлена",
      buildingIsIncorrect: "Некоректний будинок",
      appartmentIsIncorrect: "Некоректна квартира",
      periodActive: "Період активності",
      fromTo: 'Термін дії пiдписки',
      noDoctors: "На жаль, за даною спеціальністю на даний момент недоступний жоден лікар.",
      //NEW TRANSLATIONS
      medicalCards: "Медкарти",
      code: "код",
      buy: "Купити",
      Next: "Далі",
      PersonalData: "персональних даних",
      PrivacyPolicy: "політикою конфіденційності",
      Agreement: "Реєструючись, ви даєте згоду на обробку %@ і погоджуєтесь з %@",
      Registration: "Реєстрація",
      // InputSmsCode: "Введіть смс код",
      ResendMessage: "Отримати смс ще раз",
      CreatePassword: "Створити пароль",
      ForSecure: "Для захисту ваших персональних даних",
      Login: "Вхід",
      Clinic: "Клініка",
      doctorTypeEndocrin: "Ендокринолог",
      doctorTypeLor: "Лор",
      doctorTypeFamilyDoctor: "Сімейний лікар",
      doctorTypeGinecolog: "Гінеколог",
      whatIsYourDisturbing: "Що вас турбує?",
      whatSymptomWorriesYou: "Які симптоми вас турбують?",
      frequentlyAskedRequests: "Повторювані запити",
      analysises: "Аналізи",
      ourDoctors: "Наші лікарі",
      
      // medicalCards: "Медичні карти",
      whoNeedDoctor: "Кому потрібен лікар?",
      createMedicalCard: "Створити медкарту",
      updateMedicalCard: "Оновити медкарту",
      theNewMedicalCard: "Нова медкарта",
      Lastname: "Прізвище",
      Name: "Ім'я",
      Surname: "По батькові",
      Phone: "Телефон",
      Email: "E-mail",
      Birthday: "День народження",
      Sex: "Стать",
      Relationship: "Відношення до родини",
      Address: "Адреса",
      NumberOfPolice: "№ полісу",
      Notifications: "Повідомлення",
      Account: "Обліковий запис",
      Talks: "Розмови",
      Man: "Чоловіча",
      Woman: "Жіноча",
      Me: "Я",
      Son: "Син",
      Daughter: "Дочка",
      Husband: "Чоловік",
      Wife: "Дружина",
      OtherRelationship: "Інше відношення до родини",
      Complete: "Завершити",
      legalInformation: "Юридична інформація",
      aboutCompany: "Про нас",
      FAQ: "Питання і відповіді",
      EnterPasscode: "Введіть ваш пін-код",
      ConfirmPasscode: "Підтвердіть ваш пін-код",
      InputSmsCode: "Введіть код з смс",
      ResendCode: "Відправити код ще раз",
      sendCode: "Відправити код по смс",
      Error: "Помилка",
      Consultations: "Консультації",
      Support: "Підтримка",
      Medcard: "Медкарта",
      Confirm: "Підтвердити",
      AddPhoto: "Додати фото",
      changePhoto: "Змінити фото",
      SelectFromGallery: "Обрати з галереї",
      TakePhoto: "Сфотографувати",
      Delete: "Видалити",
      City: "Місто",
      cityIsIncorrect: "Некоректна вулиця",
      Street: "Вулиця",
      Building: "Будинок",
      Flat: "Квартира",
      Stage: "Поверх",
      stageIsIncorrect: "Некоректний поверх",
      EntranceNumber: "№ під'їзду",
      WayDetails: "Як дістатись",
      DefineMyLocation: "Визначити моє розташування",
      AddAddress: "Додати адресу",
      cityShort: "м.",
      streetShort: "вул.",
      houseShort: "буд.",
      flatShort: "кв.",
      medicalTests: "Аналізи",
      bookAnalysis: "Замовити аналізи",
      whatAnalysisYouNeed: "Які аналізи Вам потрібні?",
      whatDrugsYouNeed: "Які препарати Вам потрібні?",
      step: "Крок",
      findAnalysis: "Знайти аналіз",
      analysisName: "Найменування",
      analysisPrice: "Ціна,\nгрн",
      subscriptionPrice: "Вартість, грн",
      specialties: "Спеціальності",
      analysisTime: "Строк, дні",
      price: "Вартість",
      inLab: "В пункті забору",
      atHome: "До дому",
      howToTake: "Як будете забирати?",
      analysisAddress: "Пункт збору",
      selectTakePlace: "Оберіть пункт забору",
      enterYourAddress: "Введіть Вашу адресу",
      book: "Оформити замовлення",
      confirmInformation: "Підтвердженя інформації",
      howToTakeSummary: "Як зібрати аналізи",
      analysisSuccessBooking: "Аналізи успішно замовлені!",
      drugsSuccessBooking: "Препарати успішно замовлені!",
      myConsulattions: "Мої консультації",
      today: "Сьогодні",
      tomorrow: "Завтра",
      yesterday: "Вчора",
      enterMessage: "Введіть повідомлення",
      callEnded: "Дзвінок завершено",
      doctor: "Лікар",
      subscriptions: "Підписки",
      faceIdReason: "Авторизація в додатку",
      logIn: "Увійти",
      faceIdNotSuccess: "Не вдалось аторизувати користувача",
      faceIdPolicy: "Не вдалось отримати дозвіл",
      faceIdNotAvailable: "Ця можливість недоступна",
      noDoctorsAvailable: "Усі лікарі зайняті",
      doctorGreetingFirstPart: "Вітаю! Я Ваш лікар –",
      doctorGreetingSecondPart: "Консультація розпочнеться",
      at: "o",
      doctorGreetingThirdPart: "По можливості опишіть Ваше самопочуття та скарги заздалегідь. При наявності результатів аналізів чи обстежень, додайте їх, будь ласка, у цей чат. До зустрічі!",
      consultationHistory: "Історія консультацій",
      lifeAnamnesis: "Анамнез захворювання",
      complaints: "Скарги",
      diagnos: "Попередній діагноз (По МКБ-10)",
      treatment: "План лікування",
      conclusion: "Висновок",
      drugs: "Препарати",
      tools: "Інструментальні дослідження",
      analysis: "Аналізи",
      historyIsEmpty: "Лфкар ще не заповнив історію Вашої консультації.",
      photoOrVideo: "Фото або відео",
      file: "Файл",
      files: "Файли",
      cancel: "Відміна",
      back: "Назад",
      thanks: "Дякую!",
      payment: "Оплата",
      paymentDesc: "Обрана Вами консультація не була оплачена. Бажаєте оплатити?",
      yes: "Так",
      no: "Ні",
      purchase: "Придбати",
      showMore: "Читати ще",
      showLess: "Згорнути",
      new: "Нова",
      closed: "Завершена",
      inProcess: "В процесі",
      whoNeedSubscription: "Кому потрібна підписка?",
      noInternetError: "Підлючення до інтернету відсутнє.",
      recommendedDoctors: "Рекомендовані лікарі",
      undefinedGender: "Невизначена",
      selectClinic: "Оберіть клініку",
      clinicDepartments: "відділень",
      clinicDepartment: "відділення",
      selectClinicAddress: "Оберіть адресу клініки",
      enterClinicAddress: "Введіть адресу клінінки",
      disconnectFromChat: "Вас було відключено від чату.",
      selectMedicalCard: "Оберіть медкарту",
      selectConsultationType: "Оберіть вид консультації",
      whatTreats: "Що лікує",
      bookConsultation: "Замовити консультацію",
      bookConsultationOnline: "Замовити онлайн консультацію",
      bookConsultationInClinic: "Заказ візиту до клініки",
      bookConsultationAtHome: "Виклик лікаря додому",
      selectDoctor: "Обрати лікаря",
      randomDoctor: "Будь-який лікар",
      online: "Онлайн",
      inClinic: "В клініці",
      selectDoctorTitle: "Вибір лікаря",
      selectAddress: "Выберіть Вашу адресу",
      selectConvenientTime: "Оберіть зручний час",
      nearestTime: "Найближчий час",
      selectTime: "Обрати дату та час",
      selectDesiredTime: "Вибрати бажану дату і час",
      inChat: "В чаті",
      call: "Дзвінок",
      videocall: "Відеодзвінок",
      dateAndTime: "Дата та час",
      dateAndDesiredTime: "Бажана дата та час",
      enterDoctorName: "Введіть ім'я лікаря",
      informationConfirm: "Підтвердження інформації",
      specialty: "Спеціальність",
      consultationType: "Вид консультації",
      date: "Дата",
      time: "Час",
      place: "Місце",
      onlineBookingSuccess: "Онлайн консультація успішно замовлена!",
      atHomeBookingSuccess: "Лікар додому успішно замовлений!",
      inClinicBookingSuccess: "Візит до клініки успішно замовлений!",
      qaId: "Запитання та відповіді",
      byPromocode: "Оплатити промокодом",
      byCard: "Оплатити карткою",
      branchesCount: "Кількість відділень:",
      logoutButton: "Вихід",
      appVersion: "Версія додатку",
      promoButton: "Ввести промокод",
      insertPromo: "Введіть промокод",
      subscription: "Підписка",
      noSctiveSubscriptions: "Немає активних підписок",
      enterPromocodeDesc: "Введіть Ваш промокод для того, щоб активувати обрану підписку",
      activate: "Активувати",
      successActivation: "Активація успішна",
      promocodeSuccess: "Шановний користувач! Ви успішно активували промокод та отримали підписку на користування. Дякуємо!",
      promocode: "Промокод",
      promocodeError409: "Дана підписка вже доступна для Вас",
      promocodeError400: "Введений код недійсний",
      data: "Дані",
      history: "Історія",
      language: "Мова",
      incomingCall: "Вхідний дзвінок",
      whyDoWeNeedAnalysis: "Навіщо потрібні аналізи?",
      analysisDescription: "Лабораторна діагностика - сукупність методів, спрямованих на аналіз досліджуваного матеріалу за допомогою різного спеціалізованого обладнання. Професійне свято лаборанти відзначають 25 жовтня. Біохімічний аналіз крові - лабораторний метод діагностики, який застосовується в клінічній практиці для визначення функціонального стану систем і тканин організму людини. Біохімічний аналіз допомагає виявити порушення функції печінки, нирок, розлад білкового, ліпідного і вуглеводного обміну, діагностувати наявність активного запального процесу, в тому числі ревматичного, а також різні розлади водно-електролітного обміну і ступінь дисбалансу мікроелементів. Таким чином, проведення біохімічного аналізу крові дозволяє не тільки виявити патологію, а й визначити ступінь гостроти запального процесу, призначити або скорегувати лікування.",
      nobodyFound: "Нікого не знайдено",
      newAppointment: "Нова консультація",
      newAppointmentGuide: "Шановний користувач! Ви забронювали консультацію. Будь ласка, перейдіть в чат с лікарем та дайте відповіді на певні запитання. Це допоможе лікарю під час консультації",
      nothingFound: "Нічого не знайдено",
      frequentRequests: "Часті запити",
      noAppointments: "У Вас поки що немає консультацій.",
      pay: "Оплатити",
      promocodeAppointment: "Введіть Ваш промокод для того, щоб забронювати обрану консультацію",
      doctors: "Лікарі",
      doctorDescription: "Опис",
      doctorEducation: "Освіта",
      doctorExperience: "Досвід",
      doctorYears: "Років",
      turnOfChatBot: "Вимкнути питання",
      chatBotTurnedOff: "Питання вимкнені",
      versionTitle: "Доступне оновлення",
      versionDescription: "Ми опублікували нову версію даного додатку. Для того, щоб продовжити використовувати його, завантажте оновлення з AppStore, будь ласка.",
      updateApp: "Оновити додаток",
      personalDoctor: "Особистий лікар",
      filled: "Заповнена",
      leaveFeedback: "Залиште відгук",
      enterFeedback: "Введіть відгук",
      addToCalendar: "Додати подію в календар",
      inClinicConsultation: "Консультація в клініці у лікаря",
      inClinicConsultationDesc: "за адресою",
      copyAddress: "Копіювати адресу",
      noDoctorInClinic: "У цій клініці немає можливості забронювати докторів обраної спеціалізації",
      successAddToCalendar: "Подія успішно додано в додаток Календар",

      pharmacy: "Аптека",
      pharmacyCount: "Кількість,\nшт",
      bookPreparats: "Замовити препарати",
      whatPreparatsYouNeed: "Які препарати Вам потрібні?",
      findPreparats: "Знайти препарат",
      howToTakePreparats: "Як забрати препарати",
      preparatsSuccessBooking: "Замовлення прийняте",
      preparatsErrorBooking: "Збій замовлення. Просимо повернутися на попередню сторінку",
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///subscription
      P6M: "6 місяців",
      P9M: "9 місяців",
      P3M: "3 місяця",
      P12M: "12 місяців",
      ////
      permissionRequest: "У вас не включені Сповіщення, Відео та Аудіо на цьому сайті. Щоб включити нажміть кнопку зліва від пошуку в браузері.",
      confirmButton: "Пiдтвердити",
      changeAnamnezData: "Змінити дані анамнезу",
      finishSaveButton: "Завершити і зберегти",
      divisions: "Філії",
      newDivisions: "Нова філія",
      divisionsName: "Назва філії",
      //validates
      downloadCSV: "Завантажити csv",
      requiredLoginValidate: "Введіть логін",
      dd_mm_yyyyValidate: "Дата повинна бути в форматі хх/хх/хххх",
      phoneFormatValidate: "Номер телефону повинен бути в форматі +380ХХХХХХХХХ",
      durationTimeInMinValidate: "Кількість хвилин має бути до 60 хв.",
      timeInMinValidate: "Час має бути в форматі 23ч:59хв",
      emailValidate: "Неправильный формат e-mail",
      requiredPasswordValidate: "Введіть пароль",
      requiredValidate: "Це обов'язкове поле",
      notNegativeValidate: "Не може бути негативним числом",
      createShiftButton: "Створити зміну",
      cancelOfConsultation: "Скасувати консультацію",
      consultationDate: "Час консультації",
      pushNotificationPageTitle: "Відправлення PUSH нотифікації",
      pushAgeFrom: "Вік від",
      pushAgeTo: "Вік до",
      pushTitle: "Тема повідомлення",
      createPassword: "Призначте пароль для вашого акаунту",
      month: "Місяці",
      newSubscription: "Нова підписка",
      percentileDiscount: "Відсоток знижки",
      priceDiscount: "Ціна підписки",
      company: "Компанія",
      companySuccessCreated: "Підписка створена",
      activateSubscription: "Активувати підписку",
      addPatient: "Додати пацієнта",
      isAdmin: "Админістратор компанiї",
      informPart1: 'Реєструючись, Ви даєте згоду на ',
      informPart2: 'обробку персональних даних ',
      informPart3: 'і згодні з ',
      informPart4: 'політикою конфіденційності',
      //sendPDFFile
      exportButton: "Єкспортувати",
      sendToEmail: "Надіслати на ел. адресу",
      sendPDF: "Будь ласка, оберіть компанію, до якої бажаєте надіслати файл",
      sendSuccess: "Успішно надіслано",
      //Companies
      fileUploaded: "Файл успішно загрузився",
      description: "Опис",
      subscriptionEdited: "Компания успішно оновлена",
      noDescription: "Опису поки немає",
      companies: "Компанії",
      createCompany: "Стоворити компанію",
      deleteSubText: "Вы дійсно хочете видалити підписника?",
      deleteSubSuccess: "Підписник був успішно видалений",
      //Success
      successOperatorUpdate: "Оновлення оператора успішне",
      successDoctorUpdate: "Оновлення доктора успішне",
      successOperatorDelete: "Оператора успішно видалено",
      successDoctorDelete: "Доктора успішно видалено",
      successBlockUser: "Працівника було заблоковано",
      successUnblockUser: "Працівника було розблоковано",
      successOperatorCreate: "Оператора успішно створено",
      successDoctorCreate: "Доктора успішно створено",
      // ERRORS
      codeNotCorrect: "Невірний код",
      failedBlockUser: "У працівника є консультації",
      submitConfirm:
        "Ви впевнені, що бажаєте зберегти та завершити консультацію?",
      cancelConfirm:
        "Ви впевнені, що бажаєте закрити та скасувати консультацію?",
      birthDateInFuture: "Неможливо народитись у майбутньому",
      templateTitle: "Шаблони",
      min: "хв",
      from: "з",
      to: "до",
      empty: "Пусто",
      //cChat - CALL
      infoText:
        "У разі, якщо потрібна технічна підтримка - прохання зателефонувати",
      medQuestion: "Мед. питання",
      techSupport: "Техпідтримка",
      infoMedQueation: "B разі, якщо хочете уточнити медичне питання",
      missedCall: "Пропущений дзвінок",
      canceledCall: "Скасований виклик",
      canceledConsultationMessage:
        "Шановний пацієнт, ваша консультацiя була вiдмiнена лiкарем.",
      finishConsultationMessage:
        "Ваша консультація завершена. Висновок лікаря:",
      //ConsultConfirmation
      consultConfirmation: "Консультація призначена на ",
      doctorConfirmation: "Ви записані до доктора",
      Января: "Січня",
      Февраля: "Лютого",
      Марта: "Березня",
      Апреля: "Квітня",
      Мая: "Травня",
      Июня: "Червня",
      Июля: "Липня",
      Августа: "Серпня",
      Сентября: "Вересня",
      Октября: "Жовтня",
      Ноября: "Листопада",
      Декабря: "Грудня",
      //Sider
      shifts: "Розклад",
      employees: "Співробітники",
      specializations: "Спеціалізації",
      allSpecialties: "Всі спеціальності",
      promocodes: "Промокоди",
      rates: "Тариф",
      patients: "Пацієнтів",
      onboarding: "Онбоардінг",
      client: "Клієнт",
      //=====
      //MODAL
      help: "Допомога",
      helpModalText_1:
        "У випадках, якщо потрібна технічна допомога - будь-ласка наберіть за телефоном:",
      helpModalText_2: "Якщо ви бажаєте, задати медичне питання",
      techSupportButton: "Технічна допомога",
      medQuestionButton: "Медична допомога",
      //NetworkErrorPopup
      errorCode: "Помилка. Код:",
      errorDescription: "Опис помилки:",
      //UserTool
      notifications: "Повідомлення",
      //Validate
      addressValidate: "Усі поля з * повинні бути заповнені",
      //Address Component
      address: "Адреса",
      city: "Місто",
      district: "Район",
      street: "Вулиця",
      building: "Будинок",
      apartment: "Квартира",
      codeOrNote: "Код або примітка",
      //СardData
      noPayment: "Не потребує оплати",
      currentDiseaseHistory: "Анамнез поточного захворювання",
      allergicDiseases: "Алергічні реакції",
      medicalHistory: "Перенесенні захворювання",
      treatmentPlan: "План лікування",
      recommendations: "Рекомендації",
      //Client
      personalPatient: "Особисті пацієнти",
      isFavourite: "Пацієнт вже у списку",
      addToFavourite: "Додати в список особистих пацієнтів",
      warning: "Увага!",
      consultationIsNotPayed: "Косультація не була сплачена",
      consultationCard: "Картка консультації",
      cardData: "Дані картки",
      //HistoryItemDetails
      consultationDetails: "Деталі консультації",
      onlineConsultation: "Онлайн консультація",
      consultationWithDoctor: "Консультація з лікарем",
      presumptiveDiagnosis: "Можливий діагноз (по МКХ-10)",
      TreatmentPlan: "План лікування",
      medications: "Препарати",
      examinations: "Iнструментальні дослідження",
      tests: "Аналізи",
      specialtyRecommend: "Рекомендовані лікарі",
      //History
      january: "січня",
      february: "лютого",
      march: "березня",
      april: "квітня",
      may: "травня",
      june: "червня",
      july: "липня",
      august: "серпня",
      september: "вересня",
      october: "жовтня",
      november: "листопада",
      december: "грудня",
      //Anamnez
      medCardTitle: "Медична карта",
      anamnez: "Анамнез",
      deleteConfirm: "Ви впевнені, що бажаете видалити картку?",
      //MedCard
      sex: "Стать",
      maleSex: "Чоловіча",
      femaleSex: "Жіноча",
      //Monitoring
      monitoring: "Моніторинг",
      patient: "Пацієнт",
      cause: "Причина звернення",
      status: "Статус",
      newConsultation: "Нова",
      filledConsultation: "Заповнена",
      closedConsultation: "Завершена",
      in_process: "У процесі",
      patientNotFound: "Пацієнта не знайдено",
      inProgressConsultation: "У процесі",
      cancelled: "Скасована",
      cancelledConsultation: "Скасована",
      //Specializations
      acceptButton: "Застосувати",
      requestCreated: "Заявка створена",
      newPacient: "Новий пацієнт",
      mainCard: "Головна медкарта",
      relations: "Відношення до сім'ї",
      adress: "Адреса",
      policeNumber: "№ полісa",
      insurance: "Компанія/страховка",
      registration: "Оформлення",
      //Specializations
      newSpecialty: "Нова спеціалізація",
      title: "Найменування",
      activePeriod: "Період активності",
      active: "АКТИВНА",
      inActive: "НЕ АКТИВНА",
      priceTitle: "Вартість",
      priceOnline: "Вартість online-консультаціі",
      priceHome: "Вартість виклику додому",
      priority: "Пріоритет",
      showOnMainScreen: "Показати на головному екрані",
      showOnDoctorsList: "Показати в списку лікарів",
      //Banners
      banners: "Банери",
      newBanner: "Новий банер",
      //Subscribers
      subscribers: "Підписані пацієнти",
      fullName: "П.І.Б.",
      policeStartDate: "Час початку дії полісу",
      policeExpireDate: "Час закінчення дії полісу",
      insuranceCompany: "Назва страхової компанії",
      authorization: "Авторизація",
      scheduleListTitle: "Зміни",
      specializationTitle: "Лікар",
      appointmentsTime: "Зміна",
      durationTitle: "Тривалість консультації",
      filterToday: "На сьогодні",
      filterTomorrow: "На завтра",
      filterWeek: "На тиждень",
      filterAll: "Усі",
      filterBooked: "Куплені",
      filterFormTitle: "Фільтр",
      filterAllSchedules: "Всі зміни",
      filterActiveSchedules: "Активні зміни",
      filterTypeOfService: "Бажаний формат",
      // Specialty
      cost: "Вартість",
      priceType: "Онлайн консультація",
      callOnHome: "Виклик додому",
      // =====
      common: "Загальне",
      name: "Ім'я",
      lastName: "Прізвище",
      patronymic: "По батькові",
      birthDate: "Дата народження",
      gender: "Стать",
      experience: "Досвід роботи",
      old: "Років",
      email: "Електронна адреса",
      specializationDescription: "Опис",
      education: "Освіта",
      typeEducation: "Вид освіти",
      addSpecialty: "Додати спеціалізацію",
      saveBanner: "Зберегти банер",
      addBanner: "Додати банер",
      phoneNumber: "Номер телефону",
      newEmployee: "Новий співпрацівник",
      createButton: "Створити",
      createUserProfileButton: "Створити профіль клієнта",
      lockButton: "Заблокувати",
      unLockButton: "Розблокувати",
      cancelButton: "Скасувати",
      addSchedule: "Додати зміну",
      placeholderSpecialties: "Спеціальності",
      placeholderSpecialty: "Спеціалізація",
      placeholderSearch: "Пошук",
      optionsServiceTypeOnline: "Онлайн",
      optionsServiceTypeAtHome: "Виклик додому",
      optionsServiceTypeInHospital: "Візит до клініки",
      optionsServiceTypeInVideo: "Відеодзвінок",
      optionsServiceTypeCall: "Дзвінок",
      optionsServiceTypeInChat: "У чаті",
      newScheduleFilterFormTitle: "Нова зміна",
      newScheduleFilterFormTitleEdit: "Редагування",
      manually: "Вручну",
      fromFile: "З файлу",
      duration: "Тривалість консультації",
      timeStart: "Час початку",
      timeEnd: "Час закінчення",
      fileUploaderTitle: "Завантаження файлу .xlsx",
      // Employees
      employeesFlowTitle: "Співпрацівники",
      experienceIsIncorrect: "Досвід перевищує максимально можливий",
      experienceIsRequire: "Досвід обов'язковий",
      operators: "Оператори",
      operator: "Оператор",
      operatorAndAdmin: "Оператор / Адмін",
      phone: "Телефон",
      //Auth
      login: "Вхід",
      loginError: "Невірний логін",
      passwordError: "Невірний пароль",
      //onBoarding
      onBoarding: "Онбоардінг",
      edit: "Редагувати",
      newOnBoard: "Новий онбоардінг",
      addImage: "Додати зображеня",
      showInList: "Показати у списку",
      //PromoCodes Page
      promocodeStatus: "Статус промокоду",
      promocodeStatus_0: "Невикористаний",
      promocodeStatus_1: "Використаний",
      promocodeType: "Тип промокоду",
      promocodeType_0: "Підписка",
      promocodeType_1: "Консультація",
      promocodeGenerateSuccess: "Промокоди успішно створено",
      promocodesCount: "Усього промокодів:",
      promocodesActivateCount: "Промокодів активовано:",
      promoCodes: "Промокоди",
      addPromoCodes: "Додати промокод",
      promocodeName: "Промокод",
      promocodeCreated: "Створено",
      promocodeExpityDate: "Дійсний до",
      promocodeCount: "Кількість промокодів",
      promocodeFrom: "Виданий",
      deletePromocode: "Видалити",
      //Feedbacks
      placeholderFeedbacks: "Відгуки",

      //Symptoms Page
      addSymptom: "Додайте симптом",
      chooseSpecialty: "Виберіть спеціальність",
      symptomsAndSpeciality: "Симптоми і спеціалізації",
      noTitle: "Немає назви",
      symptoms: "Симптоми",
      emptyList: "Пустий список",
      existingSymptoms: "Існуючі симптоми",
      deleteSymptom: "Видалити симптом",
      //Placeholders
      success: "Успіх",
      successCreatingPatient: "Пацієнта успішно створено!",
      placeholderLogin: "Логін",
      placeholderPassword: "Пароль",
      placeholderRepeatPassword: "Повторіть пароль",
      placeholderAddSymptom: "Додайте симптоми",
      placeholderText: "Текст",
      placeholderNumber: "Введіть число від 0 до 100",
      placeholderLink: "Посилання",
      placeholderTitle: "Заголовок",
      placeholderMedCard: "Медкартка",
      bookDate: "Дата бронювання",
      paymentType: "Спосіб оплати",
      hospitals: "Клініки",
      hospitalDepartments: "Відділи клінік",
      //Buttons
      addButton: "Додати",
      addMedicalCard: "Додати медкарту",
      changeButton: "Змінити",
      saveButton: "Зберегти",
      deleteButton: "Видалити",
      editButton: "Редагувати",
      closeButton: "Закрити",
      arrangeButton: "Оформити консультацію",
      goodButton: "Добре",
      placeholderChatMessage: "Введіть повідомлення",
      placeholderImageOrVideo: "Оберіть зображення або відеозапис",
      placeholderDocument: "Оберіть документ",
      placeholderStartConsultation: "Почати консультацію",
      chat: "Чат",
      changeLang: "Мова",
      exitButton: "Вийти",
      firstNameIsIncorrect: "Некорректне ім'я",
      lastNameIsIncorrect: "Некорректне прізвище",
      patronymicIsIncorrect: "Некорректне ім'я по батькові",
      overstatedExperience: 'Завищений досвід',
      emailIsIncorrect: 'Некорректний email',
      firstNameRequire: "Введіть ім'я",
      lastNameIsRequire: "Введіть прізвище",
      patronymicIsRequire: "Введіть ім'я по батькові",
      emailIsRequire: "Введіть email",
      phoneIsIncorrect: "Номер телефону має бути +380*********",
      phoneIsRequire: 'Введіть номер телефону',
      birthDateIsIncorrect: 'Некорректна дата народження',
      birthDateIsRequire: 'Введіть дату народження',
      divisionsNameRequire: 'Введіть назву філії',
      divisionsNameIsIncorrect: 'Некоректна назва філії',
      medcardSuccessUpdate: 'Медкарта була успішно оновлена',
      consultationConclusion: 'Висновок',

      deleteAccount: 'Видалити акаунт',
      deleteAccountTitle: 'Видалення акаунту',
      deleteAccountConfirm: 'Ви впевнені, що хочете назавжди видалити обліковий запис і всі пов\'язані дані?'
    },
  },
  en: {
    translation: {
      activatePromocode: "Activate promocode",
      forConsultation: "for Consultation",
      forSubscription: "for Subscription",
      forAllConsultation: "Applies to all specialties",
      promocodeNotExist: "Invalid promo code entered",
      connection: "Connecting",
      internetError: "No internet connection",
      emptySearchResults: "The search has not given any results",
      filled: "Filled",
      bookAgain: "Book again",
      bookingError: "An error occurred while ordering",
      fishkaAvailability: "Available in Fishka points, UAH",
      fishkaWillReceive: "Will be credited with Fishka points, UAH",
      fishkaResetPoints: "Write off with Fishka points, UAH",
      fishkaCodeError: "The code must be 6 digits",
      searchByDate: "Search by date",
      searchInConsultations: "Search in consultations",
      searchInNotifications: "Search in notifications",
      authReg: "Registration/Login",
      maxCardsText: "Maximum allowed number of medical cards: 7",
      orDragNDropHere: "or drag and drop here",
      disabledSpecialization: "This specialisation is not currently available in your city.",
      chooseCity: "Choose city",
      district: 'District',
      chooseDistrict: "Choose district",
      whyDoWeNeedCards: "Why do we need medical cards?",
      cardsText: "Forget about queues at the reception and lost medical cards.  Your medical history and your doctor’s prescriptions are always in your smartphone.",
      endConsult: 'Consultation completed. ',
      noContent: "No information",
      notAvailable: "Not available",
      fishkaError: "The value is not valid",
      received: "Get",
      fishkaPoints: "UAH in Fishka points",
      fishkaPolicyText: "I agree to the transfer of my personal data to the organizer of the Fishka loyalty program for their processing and registration of the menu in Fishka on the terms of the program",
      byFishka: "Pay with Fishka points",
      fishkaPercent: "Will be credited, UAH with Fishka points",
      fishkaSuccessText: "You have successfully registered in the Fishka loyalty program",
      registerAction: "Register",
      fishkaTitle: "Fishka loyalty program",
      fishkaDescription: "loyalty program that brings together over 6 million customers who make their daily purchases more profitable",
      choosePhoto: "Choose photo",
      deleteCard: "Delete card",
      purchasePeriod: "Package purchase period",
      noPurchased: "No purchased yet",
      emptyList: "List is empty",
      errorBoundary: "Sorry, there was an error, please reload the page. If this does not help, please contact support at 0800330036.",
      receivedFromDoctor: 'You have received message from a doctor',
      medcardSuccessUpdate: 'Medical card was successfully updated',
      medcardSuccessCreate: 'Medical card was successfully created',
      news: "News",
      addressCreated: "Address successfully added",
      activeCards: "Active medical cards",
      subscriptionSuccess: "Subscription successfully booked",
      buildingIsIncorrect: "Building is incorrect",
      appartmentIsIncorrect: "Appartment is incorrect",
      ///subscription
      P6M: "6 month",
      P9M: "9 month",
      P3M: "3 month",
      P12M: "12 month",
      fromTo: 'Subscription expiration date',
      periodActive: "Active period",
      noDoctors: "Unfortunately, no doctor is available for this specialty at the moment.",
      ////
      permissionRequest: "Notifications, Video and Audio are unable for this website. To enable them, click the button above.",
      confirmButton: "Confirm",
      changeAnamnezData: "Change case history data",
      divisions: "Divisions",
      newDivisions: "New division",
      divisionsName: "Division name",
      //validates
      downloadCSV: "Download csv",
      requiredLoginValidate: "Enter login",
      dd_mm_yyyyValidate: "Date must be in хх/хх/хххх",
      phoneFormatValidate: "The phone number must be in the format +380ХХХХХХХХХ",
      durationTimeInMinValidate: "The number of minutes must be up to 60",
      timeInMinValidate: "Time must be  in the format 23h:59m",
      emailValidate: "Invalid e-mail format",
      requiredPasswordValidate: "Enter password",
      requiredValidate: "This is a required field",
      notNegativeValidate: "Cannot be negative",
      createShiftButton: "Create a shift",
      cancelOfConsultation: "Cancel the consultation",
      consultationDate: "Consulation time",
      pushNotificationPageTitle: "Send PUSH notification",
      pushAgeFrom: "Age from",
      pushAgeTo: "Age to",
      pushTitle: "Notification header",
      createPassword: "Create password to your account",
      month: "month",
      newSubscription: "New subscription",
      percentileDiscount: "Discount percentage",
      priceDiscount: "Discount price",
      company: "Company",
      companySuccessCreated: "Subscription created",
      activateSubscription: "Activate subscription",
      addPatient: "Add patient",
      isAdmin: "Company admin",
      //sendPDFFile
      exportButton: "Export",
      sendToEmail: "Send to email",
      sendPDF:
        "Please, select insurance company, witch you want to send the file",
      sendSuccess: "Success",
      //Companies
      fileUploaded: "File has been successfully uploaded",
      noDescription: "No description yet",
      subscriptionEdited: "Company edited successfully",
      description: "Description",
      companies: "Companies",
      createCompany: "Create company",
      deleteSubText: "Do you really want to delete subscriber?",
      deleteSubSuccess: "The Subscriber was deleted successfully",
      //Success
      successOperatorUpdate: "Operator update was successful",
      successDoctorUpdate: "Doctor update was successful",
      successOperatorDelete: "Operator was deleted successful",
      successDoctorDelete: "Doctor was deleted successful",
      successBlockUser: "Employee was blocked successfully",
      successUnblockUser: "Employee was unblocked successfully",
      successOperatorCreate: "Operator was created successful",
      successDoctorCreate: "Doctor was created successful",
      // ERRORS
      codeNotCorrect: "Code not correct",
      failedBlockUser: "The Employee has consultation",

      submitConfirm: "Do you want to close the consultation and finish it?",
      cancelConfirm: "Do you want to cancel the consultation and reject it?",
      birthDateInFuture: "You can`t be born in future",
      templateTitle: "Templates",
      min: "min",
      from: "from",
      to: "to",
      empty: "Empty",
      // Chat - CALL
      infoText: "If you need technical support - please call",
      medQuestion: "Medical question",
      techSupport: "Support",
      infoMedQueation: "If you want to clarify a medical question",
      missedCall: "Missed call",
      canceledCall: "Canceled call",
      canceledConsultationMessage:
        "Dear patient, your consultation has been canceled by a doctor.",
      finishConsultationMessage:
        "Your consultation is now complete. Doctor's conclusion:",
      //ConsultConfirmation
      consultConfirmation: "The consultation is scheduled on the ",
      doctorConfirmation: "You are registered with a doctor ",
      at: "at",
      Января: "January",
      Февраля: "February",
      Марта: "March",
      Апреля: "April",
      Мая: "May",
      Июня: "June",
      Июля: "July",
      Августа: "August",
      Сентября: "September",
      Октября: "October",
      Ноября: "November",
      Декабря: "December",
      //Sider
      shifts: "Shifts",
      employees: "Employees",
      specializations: "Specializations",
      subscriptions: "Subscriptions",
      allSpecialties: "All specializations",
      promocodes: "Promocodes",
      rates: "Rate",
      patients: "Patients",
      onboarding: "Onboarding",
      client: "Client",
      //=====
      //MODAL
      help: "Help",
      helpModalText_1: "If you need a technical help - please call: ",
      helpModalText_2: "If you have a medical question: ",
      techSupportButton: "Technical help",
      medQuestionButton: "Medical help",
      //NetworkErrorPopup
      errorCode: "Error. Code:",
      errorDescription: "Error description:",
      //UserTool
      notifications: "Notifications",
      //Validate
      addressValidate: "All fields with * need to be fulled",
      //Address Component
      address: "Address",
      city: "City",
      cityIsIncorrect: "City is incorrect",
      district: "District",
      street: "Street",
      building: "Building",
      apartment: "Apartment",
      codeOrNote: "Code or note",
      //CardData
      noPayment: "Payment not required",
      currentDiseaseHistory: "Current disease history",
      allergicDiseases: "Allergic reactions",
      medicalHistory: "Medical history",
      treatmentPlan: "Treatment",
      recommendations: "Recommendations",
      //Client
      personalPatient: "Рersonal patients",
      addToFavourite: "Add to personal clients list",
      isFavourite: "The patient is already on the list",
      warning: "Warning",
      consultationIsNotPayed: "Consultation is not payed",
      consultationCard: "Card of consultation",
      cardData: "Card data",
      //HistoryItemDetails
      consultationDetails: "Consultations detail",
      onlineConsultation: "Online consultation",
      consultationWithDoctor: "Consultation with a doctor",
      complaints: "Complaints",
      presumptiveDiagnosis: "Presumptive diagnosis (per ICD-10)",
      TreatmentPlan: "Treatment",
      conclusion: "Conclusion",
      medications: "Medications",
      examinations: "Examinations",
      tests: "Tests",
      specialtyRecommend: "Recommended doctors",
      //History
      january: "of january",
      february: "of february",
      march: "of march",
      april: "of april",
      may: "of may",
      june: "of june",
      july: "of july",
      august: "of august",
      september: "of september",
      october: "of october",
      november: "of november",
      december: "of december",
      //Anamnez
      medCardTitle: "Medical Card",
      anamnez: "Case history",
      history: "History",
      deleteConfirm: "Are you sure, you want to delete a medical card?",
      //MedCard
      sex: "Sex",
      maleSex: "Male",
      femaleSex: "Female",
      //Monitoring
      monitoring: "Monitoring",
      patient: "Patient",
      cause: "Reason for visit",
      status: "Status",
      new: "New",
      newConsultation: "New",
      filled: "Filled",
      filledConsultation: "Filled",
      closed: "Completed",
      closedConsultation: "Completed",
      in_process: "In progress",
      patientNotFound: "Patient not found",
      inProgressConsultation: "In progress",
      cancelledConsultation: "Canceled",
      newSpecialty: "New specialty",
      title: "Name",
      priceOnline: "Online consultation fee",
      priceHome: "Home visit fee",
      priority: "Priority",
      showOnMainScreen: "Show on main screen",
      showOnDoctorsList: "Show in doctors list",
      //Banners
      banners: "Banners",
      newBanner: "New banner",
      //Subscribers
      fullName: "Name",
      policeStartDate: "Policy start date",
      policeExpireDate: "Policy expiry date",
      email: "Email",
      birthDate: "Date of birth",
      insuranceCompany: "Insurance company",
      authorization: "Authorization",
      scheduleListTitle: "Shifts",
      specializationTitle: "Doctor",
      appointmentsTime: "Shift",
      durationTitle: "Consultation duration",
      filterToday: "For today",
      filterTomorrow: "Tomorrow",
      filterWeek: "For the week",
      filterAll: "All",
      filterBooked: "Purchased",
      filterFormTitle: "Filter",
      filterAllSchedules: "All shifts",
      filterActiveSchedules: "Active shifts",
      filterTypeOfService: "Desired format",
      // Specialty
      cost: "Price",
      priceType: "Online consultation",
      callOnHome: "Call on home",
      informPart1: 'By registering, you consent to the ',
      informPart2: 'processing of personal data ',
      informPart3: 'and agree to the ',
      informPart4: 'privacy policy',
      // =====
      // =====
      finishSaveButton: "Finish and save",
      common: "General",
      name: "First name",
      lastName: "Last name",
      patronymic: "Patronymic",
      gender: "Sex",
      experience: "Experience",
      old: "Years",
      specializationDescription: "Description",
      doctor: "Doctor",
      phoneNumber: "Tel. number",
      newEmployee: "New employee",
      createButton: "Create",
      lockButton: "Block",
      unLockButton: "Unlock",
      addSchedule: "Add a shift",
      cancelButton: "Cancel",
      placeholderSpecialties: "Specialties",
      placeholderSpecialty: "Specialty",
      placeholderSearch: "Search",
      optionsServiceTypeOnline: "Online",
      optionsServiceTypeAtHome: "Visit at home",
      optionsServiceTypeInHospital: "Visit at hospital",
      optionsServiceTypeInVideo: "Video call",
      optionsServiceTypeCall: "Call",
      optionsServiceTypeInChat: "In chat",
      newScheduleFilterFormTitle: "New shift",
      newScheduleFilterFormTitleEdit: "Edit",
      manually: "Manually",
      fromFile: "From a file",
      duration: "Duration",
      timeStart: "Start time",
      timeEnd: "End time",
      fileUploaderTitle: "Upload file .xlsx",
      // Employees
      experienceIsIncorrect: "experience is not correct",
      employeesFlowTitle: "Employees",
      experienceIsRequire: "Experience is required",
      operators: "Operators",
      operator: "Operator",
      operatorAndAdmin: "Operator / Admin",
      phone: "Telephone",
      //Auth
      login: "Login",
      loginError: "Incorrect login",
      passwordError: "Incorrect password",
      //onBoarding
      onBoarding: "Onboarding",
      edit: "Edit",
      newOnBoard: "New onboarding",
      addImage: "Add an image",
      showInList: "Show on the list",
      //PromoCodes Page
      promocodeStatus: "Promocode status",
      promocodeStatus_0: "Unused",
      promocodeStatus_1: "Used",
      promocodeType: "Promocode type",
      promocodeType_0: "Subscription",
      promocodeType_1: "Consultation",
      promocodesCount: "All promocodes:",
      promocodesActivateCount: "Promocodes activated:",
      promoCodes: "Promocodes",
      addPromoCodes: "Add promocodes",
      promocodeName: "Promocode",
      promocodeCreated: "Created",
      promocodeExpityDate: "Expiry Date",
      promocodeCount: "Promocodes count",
      promocodeFrom: "Issued by",
      deletePromocode: "delete",

      //Symptoms Page
      chooseSpecialty: "Choose specialty",
      symptoms: "Symptoms",
      noTitle: "No title",
      //Placeholders
      placeholderLogin: "Login",
      placeholderPassword: "Password",
      placeholderRepeatPassword: "Repeat password",
      placeholderAddSymptom: "Add a symptom",
      placeholderText: "Text",
      placeholderNumber: "Write number from 0 to 100",
      placeholderLink: "Link",
      placeholderTitle: "Title",
      //Buttons
      addButton: "Add",
      addMedicalCard: "Add a medical card",
      changeButton: "Change",
      saveButton: "Save",
      deleteButton: "Delete",
      editButton: "Edit",
      closeButton: "Close",
      acceptButton: "Apply",
      // OPERATOR
      requestCreated: "Request created",
      newPacient: "New patient",
      mainCard: "Main medical card",
      relations: "Relationship",
      adress: "Address",
      policeNumber: "Policy number",
      insurance: "Company/insurance",
      registration: "Registration",
      activePeriod: "Active period",
      active: "ACTIVE",
      inActive: "INACTIVE",
      priceTitle: "Price",
      // Specialty
      //Placeholders
      success: "Success",
      successCreatingPatient: "Patient has been created successful!",
      placeholderMedCard: "Medical cards",
      bookDate: "Booking date",
      hospitals: "Hospitals",
      hospitalDepartments: "Hospital departments",
      //Buttons
      arrangeButton: "Arrange",
      goodButton: "Good",
      placeholderChatMessage: "Type message",
      placeholderImageOrVideo: "Chose image or video",
      placeholderDocument: "Chose document",
      placeholderStartConsultation: "Start consultation",
      chat: "Chat",
      changeLang: "Lang",
      exitButton: "Log out",
      firstNameIsIncorrect: "First name is incorrect",
      lastNameIsIncorrect: "Last name is incorrect",
      patronymicIsIncorrect: "Patronymic is incorrect",
      overstatedExperience: 'Overstated experience',
      emailIsIncorrect: "Email is incorrect",
      firstNameRequire: "Enter first name",
      lastNameIsRequire: "Enter last name",
      patronymicIsRequire: "Enter patronymic",
      emailIsRequire: "Enter email",
      phoneIsIncorrect: "Phone will be +380*********",
      phoneIsRequire: 'Enter phone',
      birthDateIsIncorrect: 'Birthdate is incorrect',
      birthDateIsRequire: 'Enter birthdate ',
      divisionsNameRequire: 'Enter the name of the branch',
      divisionsNameIsIncorrect: 'Incorrect branch name',
      ///////NEW TRANSLATIONS
      legalInformation: "Legal information",
      medicalCards: "Medical Cards",
      code: "code",
      buy: "Buy",
      Next: "Next",
      PersonalData: "personal data",
      PrivacyPolicy: "privacy policy",
      Agreement: "By registering, you consent to the processing of %@ and agree to the %@",
      Registration: "Registration",
      // InputSmsCode: "InputSmsCode",
      ResendMessage: "Resend Message",
      CreatePassword: "Create Password",
      ForSecure: "To protect your personal data",
      Login: "Login",
      Clinic: "Clinic",
      doctorTypeEndocrin: "Endocrinologist",
      doctorTypeLor: "Otolaryngologist",
      doctorTypeFamilyDoctor: "Family doctor",
      doctorTypeGinecolog: "Gynecologist",
      whatIsYourDisturbing: "What bothers you?",
      whatSymptomWorriesYou: "What symptom worries you ?",
      frequentlyAskedRequests: "Frequently asked requests",
      analysises: "Analysises",
      ourDoctors: "Our doctors",
      // medicalCards: "Medical cards",
      whoNeedDoctor: "Who needs a doctor?",
      createMedicalCard: "Create medical card",
      updateMedicalCard: "Update medical card",
      theNewMedicalCard: "New medical card",
      Lastname: "Lastname",
      Name: "Name",
      Surname: "Patronymic",
      Phone: "Phone",
      Email: "E-mail",
      Birthday: "Date of birth",
      Sex: "Sex",
      Relationship: "Relationship",
      Address: "Address",
      NumberOfPolice: "Policy number",
      Notifications: "Messages",
      Account: "Account",
      Talks: "Talks",
      Man: "Man",
      Woman: "Woman",
      Me: "Me",
      Son: "Son",
      Daughter: "Daughter",
      Husband: "Husband",
      Wife: "Wife",
      OtherRelationship: "Other relationship",
      Complete: "Complete",
      FAQ: "FAQ",
      aboutCompany: "About Us",
      EnterPasscode: "Enter your PIN code",
      ConfirmPasscode: "Confirm your pincode",
      InputSmsCode: "Enter code form sms",
      ResendCode: "Resend Code",
      sendCode: "Send code via sms",
      Error: "Error",
      Consultations: "Consultations",
      Support: "Support",
      Medcard: "Medical card",
      Confirm: "Confirm",
      AddPhoto: "Add photo",
      changePhoto: "Change photo",
      SelectFromGallery: "Select from gallery",
      TakePhoto: "Take photo",
      Delete: "Delete",
      City: "City",
      Street: "Street",
      Building: "Building",
      Flat: "Apartment",
      Stage: "Floor",
      stageIsIncorrect: "Floor is incorrect",
      EntranceNumber: "Entrance number",
      WayDetails: "Directions",
      DefineMyLocation: "My location",
      AddAddress: "Add an address",
      cityShort: "",
      streetShort: "",
      houseShort: "",
      flatShort: "",
      medicalTests: "Tests",
      bookAnalysis: "Book analysises",
      whatAnalysisYouNeed: "What tests do you need?",
      whatDrugsYouNeed: "What drugs do you need?",
      step: "Step",
      findAnalysis: "Find analysis",
      analysisName: "Name",
      analysisPrice: "Price, grn",
      subscriptionPrice: "Price, grn",
      specialties: "Specializations",
      analysisTime: "Term, days",
      price: "Price",
      inLab: "At the sampling point",
      atHome: "At home",
      howToTake: "How will you take?",
      analysisAddress: "Sampling point",
      selectTakePlace: "Select sampling point",
      enterYourAddress: "Enter your address",
      book: "Order",
      confirmInformation: "Data confirmation",
      howToTakeSummary: "How do we take samples",
      analysisSuccessBooking: "Analyzis successfully booked!",
      drugsSuccessBooking: "Drugs successfully booked!",
      myConsulattions: "My consultations",
      today: "Today",
      tomorrow: "Tomorrow",
      yesterday: "Yesterday",
      enterMessage: "Enter message",
      callEnded: "Call ended",
      faceIdReason: "Login into app",
      logIn: "Log in",
      faceIdNotSuccess: "Failed to authenticate user",
      faceIdPolicy: "Could not evaluate policy",
      faceIdNotAvailable: "This feature is not supported",
      noDoctorsAvailable: "All doctors are busy",
      doctorGreetingFirstPart: "Hello! I am your doctor -",
      doctorGreetingSecondPart: "The consultation will start",
      doctorGreetingThirdPart: "If possible, describe your condition and complaints in advance. If you have the results of tests or examinations, please add them to this chat. See you!",
      consultationHistory: "Consultations history",
      lifeAnamnesis: "Anamnesis of illness",
      diagnos: "Provisional diagnosis",
      treatment: "Treatment",
      drugs: "Drugs",
      tools: "Tools",
      analysis: "Tests",
      historyIsEmpty: "Doctor hasn't filled consultation history yet.",
      photoOrVideo: "Photo or video",
      file: "File",
      files: "Files",
      cancel: "Cancel",
      back: "Back",
      thanks: "Thank you!",
      payment: "Payment",
      paymentDesc: "Selected consultation seems to be not paid. Would you like to pay for it?",
      yes: "Yes",
      no: "No",
      purchase: "Purchase",
      showMore: "Show More",
      showLess: "Show Less",
      inProcess: "In process",
      cancelled: "Cancelled",
      whoNeedSubscription: "Who need subscription?",
      noInternetError: "The Internet connection appears to be offline.",
      recommendedDoctors: "Recommended doctors",
      undefinedGender: "Undefined",
      selectClinic: "Select clinic",
      clinicDepartments: "departments",
      clinicDepartment: "department",
      selectClinicAddress: "Select clinic address",
      enterClinicAddress: "Enter clinic address",
      disconnectFromChat: "You have been disconnected from chat.",
      selectMedicalCard: "Select a medical card",
      selectConsultationType: "Select consultation type",
      whatTreats: "area of expertise",
      bookConsultation: "Book a consultation",
      bookConsultationOnline: "Book an online consultation",
      bookConsultationInClinic: "Book consultation in clinic",
      bookConsultationAtHome: "Book consultation at home",
      selectDoctor: "Select a doctor",
      randomDoctor: "Random doctor",
      online: "Online",
      inClinic: "At the clinic",
      selectDoctorTitle: "Doctor selection",
      selectAddress: "Choose your address",
      selectConvenientTime: "Choose a convenient time",
      nearestTime: "Nearest availability",
      selectTime: "Select date and time",
      selectDesiredTime: "Select the desired date and time",
      inChat: "Chat",
      call: "Call",
      videocall: "Video call",
      dateAndTime: "Date and time",
      dateAndDesiredTime: "Desired date and time",
      enterDoctorName: "Enter the doctor's name",
      informationConfirm: "Information confirmation",
      specialty: "Specialty",
      consultationType: "Consultation type",
      date: "Date",
      time: "Time",
      place: "Place",
      onlineBookingSuccess: "Online consultation successfully booked!",
      atHomeBookingSuccess: "Consultation at home was successfully booked!",
      inClinicBookingSuccess: "Consultation in clinic was successfully booked!",
      qaId: "Q&A",
      byPromocode: "Pay with promocode",
      byCard: "Pay with card",
      branchesCount: "Number of departments:",
      logoutButton: "Logout",
      appVersion: "Application version",
      promoButton: "Enter promocode",
      insertPromo: "Enter promocode",
      subscription: "Subscription",
      noSctiveSubscriptions: "No active subscriptions",
      enterPromocodeDesc: "Enter your promocode in order to activate selected subscription",
      activate: "Activate",
      successActivation: "Activation success",
      promocodeSuccess: "Dear user! You have successfully activated promocode and got subscription for usage. Thank you!",
      promocode: "Promocode",
      promocodeError409: "This subscription is already available for you",
      promocodeError400: "The code is invalid",
      data: "Data",
      language: "Language",
      incomingCall: "Incoming call",
      whyDoWeNeedAnalysis: "Why do you need analysis?",
      analysisDescription: "Laboratory diagnostics is a set of methods aimed at analyzing the material under study using various specialized equipment. Laboratory technicians celebrate their professional holiday on October 25. Biochemical analysis of blood is a laboratory diagnostic method used in clinical practice to determine the functional state of the systems and tissues of the human body. Biochemical analysis helps to identify abnormal liver and kidney function, a disorder of protein, lipid and carbohydrate metabolism, diagnose the presence of an active inflammatory process, including rheumatic, as well as various disorders of water and electrolyte metabolism and the degree of imbalance of trace elements. Thus, a biochemical blood test allows not only to identify pathology, but also to determine the severity of the inflammatory process, to prescribe or adjust treatment.",
      nobodyFound: "Nobody found",
      newAppointment: "New consultation",
      newAppointmentGuide: "You have a new consultation. Please go to chat with doctor and provide answers to some questions.",
      nothingFound: "Nothing found",
      frequentRequests: "Frequent requests",
      noAppointments: "You do not have any appointments yet.",
      paymentType: "Payment type",
      pay: "Pay",
      promocodeAppointment: "Enter your promocode in order to book selected appointment",
      doctors: "Doctors",
      doctorDescription: "Description",
      doctorEducation: "Education",
      doctorExperience: "Experience",
      doctorYears: "Years",
      turnOfChatBot: "Turn off questions",
      chatBotTurnedOff: "Questions off",
      versionTitle: "Update available",
      versionDescription: "We have released new update for the app. In order to continue using it please download update from AppStore.",
      updateApp: "Update app",
      personalDoctor: "Personal doctor",
      leaveFeedback: "Leave feedback",
      enterFeedback: "Enter feedback",
      addToCalendar: "Add event to calendar",
      inClinicConsultation: "Consultation at the clinic with a doctor",
      inClinicConsultationDesc: "at the address",
      copyAddress: "Copy address",
      noDoctorInClinic: "Doctors of chosen specialization are not available in this clinic currently",
      successAddToCalendar: "Event successfully added to Calendar app",

      pharmacy: "Pharmacy",
      pharmacyCount: "Quantity,\npcs",
      bookPreparats: "Book preparations",
      whatPreparatsYouNeed: "What drugs do you need?",
      findPreparats: "Find a drug",
      howToTakePreparats: "How do we take drugs",
      preparatsSuccessBooking: "Order is accepted",
      preparatsErrorBooking: "Failed order. Please return to the previous page",
      consultationConclusion: 'Conclusion',

      deleteAccount: 'Delete account',
      deleteAccountTitle: 'Account deletion',
      deleteAccountConfirm: 'Are you sure you want to permanently delete your account and all associated data?'
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || "ua",
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;