import { createSelector } from "@reduxjs/toolkit";

const defaultHospitals = {
  hospitalGroups: null,
  hospitals: null,
  loading: false,
};

const defaultState = { hospitals: { ...defaultHospitals }};

const getHospitals = (state = defaultState) =>
  state.hospitals;

export const getHospitalGroupsSelector = createSelector(
  [getHospitals],
  ({hospitalGroups}) => hospitalGroups
);
export const getHospitalsSelector = createSelector(
  [getHospitals],
  ({hospitals}) => hospitals
);
export const getHospitalsLoading = createSelector(
  [getHospitals],
  ({ loading }) => loading
);
