import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  getDrugsListSuccess,
  getDrugsProvidersSuccess,
  bookDrugsSuccess,
  getDrugsExcludedList,
  getProviderPharmaciesSuccess,
  setEmpty,
  clearDrugs,
  getDrugsRequest,
  loadMoreDrugsRequest,
  changeDrugQuantityAction,
  setSelected,
  changeDrugProvider,
  resetDrugs,
  getDrugsFromSearchSuccess,
  resetPageFromSearch,
  setDrugsCard,
  resetDrugsFromSearch,
} from './drugs-actions'

const configureDrugList = (drugs) => {
  if(!drugs) return []
  const drugList = drugs.map((el) => ({
    ...el.booking_info[0],
    quantity: 1,
  }))
  return drugList
}
const drugs = createReducer([], {
  [getDrugsListSuccess]: (state, { payload }) => {
    const newDrugs = configureDrugList(payload.data.items)
    return [
      ...state,
      ...newDrugs,
    ]
  },
  [changeDrugQuantityAction]: (state, { payload }) => {
    return state.map(el => el.id === payload.id ? payload : el)
  },
  [resetDrugs]: (_, __) => []
})

const drugsFromSearch = createReducer([], {
  [getDrugsFromSearchSuccess]: (state, { payload }) => {
    const newDrugs = configureDrugList(payload.data.items)
    return [
      ...state,
      ...newDrugs,
    ]
  },
  [changeDrugQuantityAction]: (state, { payload }) => {
    return state.map(el => el.id === payload.id ? payload : el)
  },
  [resetDrugs]: (_, __) => [],
  [resetDrugsFromSearch]: (_, __) => []
})

const excludedDrugs = createReducer([], {
  [getDrugsExcludedList]: (state, { payload }) => payload?.data?.items || [],
})

const providers = createReducer([], {
  [getDrugsProvidersSuccess]: (state, { payload }) => payload?.items || []
})

const currentProvider = createReducer('apteka24', {
  [changeDrugProvider]: (_, {payload}) => payload
})

const loading = createReducer(false, {
  [getDrugsRequest]: (_, __) => true,
  [getDrugsListSuccess]: (_, __) => false,
  // [getDrugsProvidersSuccess]: (_, __) => false,
  [getDrugsExcludedList]: (_, __) => false,
  [bookDrugsSuccess]: (_, __) => false,
  [getDrugsFromSearchSuccess]: (_, __) => false
})

const loadingMore = createReducer(false, {
  [loadMoreDrugsRequest]: (_, __) => true,
  [getDrugsListSuccess]: (_, __) => false,
})

const currentPage = createReducer(1, {
  [getDrugsListSuccess]: (page, __) => page + 1,
  [resetDrugs]: (_, __) => 1
})

const currentPageFromSearch = createReducer(1, {
  [getDrugsFromSearchSuccess]: (page, __) => page + 1,
  [resetDrugs]: (_, __) => 1,
  [resetPageFromSearch]: (_, __) => 1,
})

const pharmacies = createReducer([], {
  [getProviderPharmaciesSuccess]: (_, {payload}) => payload?.items || []
})

const allSelectedDrugs = createReducer([], {
  [setSelected]: (state, { payload }) => {
    const item = state.find(el => el.id === payload.id)
    if (item) {
      return state.filter(el => el.id !== payload.id)
    }
    const newState = [...state]
    newState.push(payload)
    return newState
  },
  [changeDrugQuantityAction]: (state, { payload }) => {
    const item = state.find(el => el.id === payload.id)
    if (item) {
      return state.map(el => el.id === payload.id ? payload : el)
    }
    return [...state]
  },
  [resetDrugs]: (_, __) => []
})

const drugsCard = createReducer(null, {
  [setDrugsCard]: (_, {payload}) => payload
}) 


export default combineReducers({
  drugs,
  providers,
  pharmacies,
  currentPage,
  loadingMore,
  loading,
  excludedDrugs,
  currentProvider,
  drugsFromSearch,
  allSelectedDrugs,
  currentPageFromSearch,
  drugsCard,
})
// export default consultations