import { createSelector } from "@reduxjs/toolkit";

const defaultChatState = {
  messages: null,
};

const defaultState = { chat: { ...defaultChatState }};

const getMessages = (state = defaultState) => state.chat
const getAnamnez = (state) => state.consultations
export const getIsConnectedToSocket = state => state.chat.isConnectedToSocket

export const getChatMessages = createSelector(
  getMessages,
  getAnamnez,
  ({ messages }, { anamnesis, isAnamnezDisabled }) => {
    if (!anamnesis.length || isAnamnezDisabled || !messages.length) return messages
    const reversedMsgs = [...messages].reverse()
    const lastAnamnezMsg = reversedMsgs.find(el => el?.customParameters?.question_id)
    return messages.map(el => {
      if (el._id === lastAnamnezMsg?._id) {
        return {...el, customParameters: {...el.customParameters, withButton: true}}
      }
      return el
    })
  }
)

export const getLastAnamnezId = createSelector(
  getMessages,
  getAnamnez,
  ({ messages }, { isAnamnezDisabled }) => {
    if (isAnamnezDisabled) return null
    const reversed = [...messages].reverse()
    const lastAnamnezMsg = reversed.find(el => el?.customParameters?.question_id)
    if (!lastAnamnezMsg) return null
    return lastAnamnezMsg.customParameters.question_id
  }
)