// import * as types from "./type";

import { createReducer } from "@reduxjs/toolkit";
import { changeLanguage } from "./lang-actions";

const initialState = {
  lang: window.localStorage.getItem("lang") || "ua",
};

const lang = createReducer(initialState, {
  [changeLanguage]: (state, {lang}) => lang
  // [types.CHANGE_LANG]: (state, { lang }) => {
  //   return {
  //     lang,
  //   };
  // },
});

export default lang;