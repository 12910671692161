import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { answerCall, rejectCall, setModalCallInfo } from '../../../redux/chat/chat-actions';
import answer from '../../../style/icons/Call/answer.svg'
import reject from '../../../style/icons/Call/reject.svg'
import './callModal.scss'

export const Callmodal = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const callModalInfo = useSelector(state => state?.chat?.callModalInfo)

  const handleRejectCall = () => {
    dispatch(rejectCall())
    dispatch(setModalCallInfo(null))
  }

  const handleAnswerCall = () => {
    const { appointmentId, doctorId, doctorSpecialtyId, type } = callModalInfo
    let callType = ""
    if (type === "2") callType = "video"
    if (type === "1") callType = "audio"

    history.push(`appointment?id=${appointmentId}&doctorId=${doctorId}&doctorSpecialtyId=${doctorSpecialtyId}`)
    dispatch(setModalCallInfo(null))
    dispatch(answerCall({type: callType}))
  }

  return (
    <div className='callingModalBox'>
      <div className='callingInfo'>Вам звонит {callModalInfo?.fullName}</div>
      <img className='docImage' src={callModalInfo?.photoUrl} alt="doctor-photo" />
      <div className='callingButtonsBox'>
        <img
          onClick={handleAnswerCall}
          className='toolImage'
          src={answer}
          alt="answer"
        />
        <img
          onClick={handleRejectCall}
          className='toolImage'
          src={reject}
          alt="reject"
        />
      </div>
    </div>
  );
}

