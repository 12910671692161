import React, { useRef } from 'react';
import '../chat1.scss'
import attachment from '../../../../style/icons/Chat/attachment.svg'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SendIcon from '@material-ui/icons/Send';
import Microphone from '@material-ui/icons/Mic';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AudioMessage from '../MessagesTypes/AudioMessage';
import { dataURLtoFile, startRecordingAudio, stopRecordingAudio } from '../audioRecording';
import { requestForAudioPermission } from '../../../../chatHoc/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { allowMicrophone } from '../../../../redux/chat/chat-actions';
import { getIsConnectedToSocket } from '../../../../redux/chat/chat-selectors';
import { InternetStateComponent, WebSocketStateComponent } from "../ConnectionState/ConnectionState"

const useStyles = makeStyles({
  iconButton: {

  },
  icon: {
    color: "#3288cd",
    width: "25px",
    height: "25px",
  },
  microRecordButton: {
    transform: "scale(1.15)",
    // border: "2px solid red",
    background: "#3288cd",
    transition: "all 0.2s linear",
    // boxShadow: "0px 0px 6px 1px #3287cd7c",
    boxShadow: "0px 0px 6px 1px rgba(255, 0, 0, 0.445)",
    '&:active': {
      background: "#3288cd"
    }
  },
  microRecordIcon: {
    color: "white",
    width: "25px",
    height: "25px",
    transform: "scale(1.2)"
  },
  iconSmallSend: {
    color: '#3288cd',
    opacity: "0.7",
    '&:hover': {
      cursor: "pointer",
      opacity: "1",
    }
  },
  iconSmallTrash: {
    color: 'red',
    opacity: "0.7",
    '&:hover': {
      cursor: "pointer",
      opacity: "1",
    }
  }
})

const ChatInputPanel = ({
  statusUploadFile,
  chatInputRef,
  handleAttach,
  fileInputRef,
  mediaInputRef,
  handleSendMessage,
  handleSendAudioMessage,
  audioAllowed,
  supportMode,
}) => {
  const [isVariantsOpen, setOpenInputVariants] = React.useState(false)
  const [recordingTime, setRecordingTime] = React.useState(0)
  const [isRecording, setIsRecording] = React.useState(false)
  const [recorded, setRecorded] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")
  const isConnectedToSocket = useSelector(getIsConnectedToSocket)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const intervalId = useRef(null)
  const savedAudioTime = useRef(null)
  const audioBlob = useRef(null)
  const audioSrc = useRef(null)

  React.useEffect(() => {
    setTimeout(() => {
      const savedValue = JSON.parse(localStorage.getItem("currentChatValue"))
      if (savedValue && savedValue.value) setInputValue(savedValue.value)
    }, 0)
    
    return () => clearInterval(intervalId.current)
  }, [])

  React.useEffect(() => {
    if (inputValue) {
      localStorage.setItem(
        "currentChatValue",
        JSON.stringify({ value: inputValue, isSupportMode: !!supportMode })
      )
    } else {
      localStorage.removeItem("currentChatValue",)
    }
    
  }, [inputValue])

  const startRecording = () => {
    setIsRecording(true)
    intervalId.current = setInterval(() => {
      setRecordingTime(prev => prev + 100)
    }, 100)
    startRecordingAudio()
  }

  const stopRecording = async () => {
    const { blob, audioUrl } = await stopRecordingAudio()
    audioBlob.current = blob;
    audioSrc.current = audioUrl;
    setIsRecording(false)
    savedAudioTime.current = recordingTime
    setRecordingTime(0)
    setRecorded(true)
    clearInterval(intervalId.current)
  }

  const handleDeleteAudio = () => {
    setRecorded(false)
    audioBlob.current = null;
    audioSrc.current = null;
  }

  const sendAudio = () => {
    const duration = savedAudioTime.current.toString()
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob.current);
    reader.onloadend = (e) => {
      const base64data = e.target.result;
      const audioFile = dataURLtoFile(base64data, 'audio.wav')
      handleSendAudioMessage(audioFile, duration)
      setRecorded(false)
    }
  }

  ////ACTIONS FOR SENDING ORDINARY MESSAGE
  const keyActions = (event) => {
    if (event.keyCode === 13 && event.ctrlKey) {
      let val = chatInputRef.current.value + "\r\n";
      chatInputRef.current.value = val
    } else if ((chatInputRef.current.value && chatInputRef.current.value.trim()) && event.keyCode === 13) {
      event.preventDefault();
      handleSendMessage(chatInputRef.current.value)
      chatInputRef.current.value = ''
      setInputValue("")
    }
  };

  if (!isConnectedToSocket) {
    return (
      <div className='textareaChatContainer'>
        <WebSocketStateComponent t={t}/>
      </div>
    )
  }

  const timeInFormat = moment(recordingTime).format("mm:ss.S");
  const showAudio = recorded && !isRecording && audioSrc.current;

  return (
    <div className='textareaChatContainer'>
      <div
        style={(recorded && !isRecording) ? { display: "grid", gridTemplateColumns: "2fr 10px 1fr 0.7fr" } : {}}
        className="textAreaBox"
      >
        <textarea
          ref={chatInputRef}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          data-autoresize
          placeholder={t('placeholderChatMessage')}
          id='text'
          type="text"
          className='chatInput'
          onKeyDown={keyActions}
          resize='none'
          rows={1}
        />
        <div className="gap"></div>
        {showAudio && (
          <AudioMessage
            recordedFormat
            message={audioSrc.current}
            isSentByUser={false}
          />
        )}
        {showAudio && (
          <div className="prevAudioTools">
            <SendIcon
              onClick={sendAudio}
              className={classes.iconSmallSend}
              alt='send-audio'
              title='send audio'
            />
            <DeleteIcon
              onClick={handleDeleteAudio}
              className={classes.iconSmallTrash}
              alt='delete-audio'
              title='delete audio'
            />
          </div>
        )}
        {isRecording && (
          <div className="recordingTime">
            <div className="redDot"></div>
            <div className="time">{timeInFormat}</div>
          </div>
        )}
      </div>
      <div className='chatTools'>
        <IconButton onClick={() => {
          if (chatInputRef.current.value) {
            handleSendMessage(chatInputRef.current.value)
            setInputValue("")
            chatInputRef.current.value = ''
          }
        }}>
          <SendIcon className={classes.icon} alt='send-message' />
        </IconButton>

        {/* {audioAllowed && (
          <IconButton
            disableRipple={true}
            disableFocusRipple={true}
            style={{ marginLeft: "7px" }}
            onClick={() => isRecording ? stopRecording() : startRecording()}
            className={isRecording ? classes.microRecordButton : ''}
          >
            <Microphone className={isRecording ? classes.microRecordIcon : classes.icon} alt='microphone' />
          </IconButton>
        )} */}
        <input
          ref={fileInputRef}
          className="fileinput"
          onChange={handleAttach}
          type="file"
          id="file"
          name="file"
          accept="text/plain,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*,video/mp4,video/x-m4v,video/*"
        />
        <input
          ref={mediaInputRef}
          className="mediaInput"
          onChange={handleAttach}
          type="file"
          id="media"
          name="medio"
          accept="image/*,video/mp4,video/x-m4v,video/*"
        />
        {statusUploadFile === 0 ? (
          <div
            onClick={() => setOpenInputVariants(prev => !prev)}
            className='attachmentImageBox'
          >
            {isVariantsOpen && (
              <div className='variantsContainer'>
                <div onClick={() => mediaInputRef.current.click()} className='variant'>{t("photoOrVideo")}</div>
                <div onClick={() => fileInputRef.current.click()} className='variant'>{t("file")}</div>
              </div>
            )}
            <IconButton>
              <AttachFileIcon className={classes.icon} alt="attachment" />
            </IconButton>
          </div>
        )
          :
          <div style={{ width: "50px", height: "50px" }}>
            <CircularProgressbar
              value={statusUploadFile}
              text={`${statusUploadFile}%`}
              counterClockwise
              initialAnimation
            />
          </div>
        }
      </div>
    </div>
  );
}

export default ChatInputPanel;
