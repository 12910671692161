import { makeStyles} from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  button: {
    color: "#3288cd",
    // background: "#3288cd",
    borderRadius: "6px",
    marginLeft: "10px",
    height: "35px",
    background: "#3288cdd9",
    "&$buttonDisabled": {
      color: theme.palette.grey[900],
      background: "lightgrey",
    },
    '&:hover': {
      background: "#3288cd"
    }
  },
  searchIcon: {
    color: "#3288cd",
    width: "25px",
    height: "25px",
  },
  searchButtonIcon: {
    color: "white",
    width: "25px",
    height: "25px",
  },
  buttonDisabled: {
    background: "lightgrey",
  },
  closeIcon: {
    color: "#3288cd",
    width: "25px",
    height: "25px",
    marginLeft: "10px",
    "&:hover": {
      cursor: "pointer",
    }
  }
}))