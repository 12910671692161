import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  getAddressesSuccess,
  getDistrictsSuccess,
  getProvincesSuccess,
  getRequest,
} from './addresses-actions'


const addresses = createReducer(null, {
  [getAddressesSuccess]: (_, {payload}) => payload.data.items.reverse()
})

const provinces = createReducer([], {
  [getProvincesSuccess]: (_, {payload}) => payload.data.items
})

const districts = createReducer([], {
  [getDistrictsSuccess]: (_, {payload}) => payload.data.items
})

const loading = createReducer(false, {
  [getRequest]: (_, __) => true,
  [getDistrictsSuccess]: (_, __) => false,
  [getProvincesSuccess]: (_, __) => false,
})

export default combineReducers({
  addresses,
  provinces,
  districts,
  loading,
})