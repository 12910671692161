import React, { useRef, useState } from "react";
import { genereteKey } from "../../../../utils/common";
import { getIncorectField } from "../../../../utils/parserForm";

export const SimpleForm = ({
  handleSubmit = () => {},
  children,
  name,
  className = "",
  style = {}
}) => {
  const formRef = useRef();
  const [key, setKey] = useState(genereteKey());

  const _handleSubmit = (evt) => {
    evt.preventDefault();

    const incorectElem = getIncorectField(evt);
    console.log(incorectElem);
    if (incorectElem) {
      if (incorectElem.disabled) {
        incorectElem.disabled = false;
        incorectElem.focus();
        incorectElem.disabled = true;
        return;
      }
      incorectElem.focus();

      return;
    }

    new Promise((resolve, reject) => {
      handleSubmit(evt, resolve, reject);
    })
      .then(() => {
        formRef.current.reset();
        setKey(genereteKey());
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <form
      name={name}
      onSubmit={_handleSubmit}
      ref={formRef}
      key={key}
      className={className}
      style={style}
      noValidate
    >
      {children}
    </form>
  );
};
