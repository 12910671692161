import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  addNotification,
  removeNotifications
} from './popup-actions'

const initialState = []

const popups = createReducer(initialState, {
  [addNotification]: (state, { payload }) => [...state, payload],
  [removeNotifications]: (state, {payload}) => [...state.filter(field => field.id == payload)]
})

export default popups