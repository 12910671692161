import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
    getBannersRequest,
    getBannersSuccess,
    openPromocodeModal,
} from './banners-actions'


const items = createReducer([], {
    [getBannersSuccess]: (_, {payload}) => payload || [],
})

const loading = createReducer(false, {
    [getBannersRequest]: (_, __) => true,
    [getBannersSuccess]: (_, __) => false,
})

const isPromocodeModalOpen = createReducer(false, {
    [openPromocodeModal]: (_, {payload}) => payload,
})

export default combineReducers({
    items,
    loading,
    isPromocodeModalOpen,
})