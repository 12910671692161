import React from 'react'
import './textPopover.scss'

const TextPopover = ({ text, positionX, positionY }) => {
  return (
    <div style={{
      top: positionY + 'px',
      left: positionX + 'px',
    }} className="popover">{text}</div>
  )
}

export default TextPopover;