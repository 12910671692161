import React from 'react';
import '../chat1.scss'
import oneArrowWhite from '../../../../style/icons/Chat/oneArrowWhite.svg'
import twoArrowsWhite from '../../../../style/icons/Chat/twoArrowsWhite.svg'
import { getMessageTime } from '../../../../utils/helpers';


const ImageMessage = ({
  areBothInChat = Boolean,
  message = String,
  customParameters = Object,
  dateSent = String,
  isSentByUser = Boolean,
  status = Number,
  type = String,
  userId = Number,
  handleOpenImage = () => {},
}) => {
  return (
    <div className={`imageMessageBox ${isSentByUser ? 'me' : 'you'}`}>
      <img onClick={handleOpenImage} className='messageImage' src={message} alt="sentImage" />
      <a href={message} target='_blanc' rel="noreferrer"><div className='imageName'>{customParameters.file_name}</div></a>
      <div className='messageInfo'>
        <div className='messageTime'>{getMessageTime(dateSent)}</div>
        <img
          className={`messageReaded ${(+status === 2 || areBothInChat) ? 'readed' : "sent"}`}
          src={(+status === 2 || areBothInChat) ? twoArrowsWhite : oneArrowWhite}
          alt=""
        />
      </div>
    </div>
  )
}

export default ImageMessage;