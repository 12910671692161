import React, {useEffect, useState} from 'react';
import { createPortal } from 'react-dom';
import closeIcon from '../../../style/icons/Close/cancel.svg'
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import close_icon from "../../../style/icons/Chat/close-icon.svg";
import './modalWindow.scss'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const ModalWindow = ({
  open,
  imageFormat,
  loading,
  handleCloseModal,
  withPayment,
  confirmMode,
  children,
  onlyContent,
}) => {
  const [loader, setLoader] = useState(false);
  const {t} = useTranslation()
  const handleOverlayClick = (e) => {
    if (!imageFormat) return
    if (e.target === e.currentTarget) {
      handleCloseModal()
    }
  }

  const handleClose = () => {
    handleCloseModal()
  }

  if (!open) return null

  return createPortal(
    <div onClick={handleOverlayClick} className='overlayBox'>
      {onlyContent && (
        <>{children}</>
      )}
      {confirmMode && (
        <div className='modalConfirmContainer'>
          {children}
        </div>
      )}
      {imageFormat && !confirmMode && (<>
          <div className='imageBox'>
            {children}
            <div onClick={handleClose} className='closeBox'>
              <img src={close_icon} alt="close" title={'Закрыть'} />
            </div>
          </div>
      </>)}
      {!imageFormat && !confirmMode && !onlyContent && (
        <div className={`modalWindowBox ${withPayment ? 'withPayment' : ''}`}>
          <IconButton
            style={{ position: "absolute", top: "10px", right: "10px", height: "20px", width: "20px" }}
            variant='contained'
            color='primary'
            onClick={handleCloseModal}
            title={t("close")}
          >
            <CloseTwoToneIcon />
          </IconButton>
          {/* <img
            onClick={handleCloseModal}
            className='closeIcon'
            src={closeIcon}
            alt="cancelIcon"
          /> */}
          <LoadingComponent loading={loading}>
            <div className='contentBox'>
              {children}
            </div>
          </LoadingComponent>
        </div>
      )}
    </div>,
    document.getElementById('portal')
  );
}

export default ModalWindow;
