import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllDoctors: (query) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/doctors?${query}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },

  getRandomDoctor: async (specialtyId, type, hospitalId = null, districtId) => {
    // console.log('TYPE', type);
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/booking/${type}${hospitalId ? `/${hospitalId}`: ''}/doctor/specialty/${specialtyId}${districtId ? `/district/${districtId}` : ''}`,
      headers: setHeader()
    }
    try {
      const { data } = await axios(config)
      return data
    } catch (e) {
      console.log(e);
    }
  },

  getDoctorPeriods: async (doctorId, specialtyId, type, hospitalId = null, districtId = null) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/consultations/booking/${type}${hospitalId ? `/${hospitalId}`: ''}/doctor/${doctorId}/specialty/${specialtyId}${districtId ? `/district/${districtId}` : ''}`,
      headers: setHeader()
    }
    try {
      const { data } = await axios(config)
      return data
    } catch (e) {
      console.log(e);
    }
  },

  getDoctorsBySearch: async(filterString = '', page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/doctors?${filterString}&page=${page}`,
      headers: setHeader()
    }
    try {
      const { data } = await axios(config)
      return data
    } catch (e) {
      console.log(e);
    }
  },
  getBySearch: async(query, page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/search/doctors?q=${query}&page=${page}`,
      headers: setHeader()
    }
    try {
      const { data } = await axios(config)
      return data
    } catch (e) {
      console.log(e);
    }
  },
}
// https://dd.zdras.te.ua/api/doctors?filter[specialty]=38&filter[fullName]=&filter[type]=0