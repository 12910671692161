import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  registerInFishka: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/patients/bonuses/register`,
      headers: setHeader(),
      data
    }
    return axios(config).then(res => res && res.data)
  },

  getPatientBonuses: async () => {
    // console.log('TYPE', type);
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/patients/bonuses`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },

  calculateSubscriptionBonuses: async ({subscriptionId, cardId, params}) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/subscriptions/${subscriptionId}/cards/${cardId}/payBonuses`,
      headers: setHeader(),
      data: params,
    }
    return axios(config).then(res => res && res.data)
  },
  calculateConsultationBonuses: async (filterString, data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/consultations/calculate/${filterString}`,
      headers: setHeader(),
      data
    }
    return axios(config).then(res => res && res.data)
  }
}