import api from '../../api'
import { addNotification } from '../popup/popup-actions'
import { v4 } from 'uuid';
import {
  fishkaRequest,
  getPatientBonusesSuccess,
  registerInFishka,
  calculateSubscriptionBonusesSuccess,
  calculateConsultationBonusesSuccess,
} from './fishka-actions'
import i18n from '../../i18n';

export const getPatientBonuses = () => async dispatch => {
  dispatch(fishkaRequest())
  try {
    const data = await api.fishka.getPatientBonuses()
    dispatch(getPatientBonusesSuccess(data))
  } catch (e) {
    console.log(e);
  }
}

export const registrationInFishka = (data) => async dispatch => {
  dispatch(fishkaRequest())
  try {
    await api.fishka.registerInFishka(data)
    dispatch(registerInFishka())
  } catch (e) {
    console.log(e);
  }
}
export const calculateSubscriptionBonuses = (data, params) => async dispatch => {
  try {
    const dat = await api.fishka.calculateSubscriptionBonuses({...data, params})
    dispatch(calculateSubscriptionBonusesSuccess(dat))
  } catch (e) {
    console.log(e);
  }
}
export const calculateConsultationBonuses = (filter, params) => async dispatch => {
  const filterString = Object.keys(filter).reduce((acc, el) => {
    if (filter[el] || filter[el] === 0) {
      acc.push(`${el}/${filter[el]}`)
    }
    return acc
  }, []).join('/')

  console.log(filterString);

  try {
    const dat = await api.fishka.calculateConsultationBonuses(filterString, params)
    dispatch(calculateConsultationBonusesSuccess(dat))
  } catch (e) {
    console.log(e);
  }
}






