import { createSelector } from "@reduxjs/toolkit";

const defaultConsultations = {
  consultations: null,
  bookingDetails: null,
  anamnesis: [],
  loading: false,
  fondySrc: '',
  isNeedToPay: false,
  currentCardId: null,
  currentPage: 0,
  pageCount: 0,
  isGotInfo: null,
  bookedConsultationId: null,
  wasCancelled: false,
  priceLoading: false,
  consultationPrice: 0,
  allConsultationInfo: null,
  drugs: null,
  initialSpecializationStatus: null,
  repaymentInfo: null,
  isDisabledBookingHome: false,
  itemsBySearch: [],
  searchLoading: false,
  pageCountBySearch: 1,
  currentSearchPage: 1,
  isEmptySearchResult: false,
  usedAnamnesis: [],
  disableAnamnezLoading: false,
  currentConsultation: null,
};

const defaultState = { consultations: { ...defaultConsultations }};

const getConsultations = (state = defaultState) =>
  state.consultations;
const getMessages = (state) => state.chat.messages;

const getAnamnes = (state) => state.consultations.anamnesis

export const getIsAnamnezDisabled = state => state.consultations.isAnamnezDisabled

export const getConsultationsList = createSelector(
  [getConsultations],
  ({consultations}) => consultations
);
export const getIsEmptySearch = createSelector(
  [getConsultations],
  ({isEmptySearchResult}) => isEmptySearchResult
);
export const getIsGotInfo = createSelector(
  [getConsultations],
  ({isGotInfo}) => isGotInfo
);
export const getBySearchSelector = createSelector(
  [getConsultations],
  ({itemsBySearch}) => itemsBySearch
);
export const getCurrentConsultationSelector = createSelector(
  [getConsultations],
  ({currentConsultation}) => currentConsultation
);
export const getCurrentCallInfoSelector = createSelector(
  [getConsultations],
  ({ currentConsultation }) => currentConsultation ? ({
    specialtyTitle: currentConsultation.specialty.title,
    fullName: currentConsultation.doctor.employee.fullName,
    photoUrl: currentConsultation.doctor.employee.photoUrl,
  }) : null
);
export const getSearchLoading = createSelector(
  [getConsultations],
  ({searchLoading}) => searchLoading
);
export const getRepaymentInfo = createSelector(
  [getConsultations],
  ({repaymentInfo}) => repaymentInfo
);
export const getBookingDetails = createSelector(
  [getConsultations],
  ({ bookingDetails }) => bookingDetails
);
export const getAllConsultationInfo = createSelector(
  [getConsultations],
  ({ allConsultationInfo }) => allConsultationInfo
);
export const getIsDisabledBookingHome = createSelector(
  [getConsultations],
  ({ isDisabledBookingHome }) => isDisabledBookingHome
);

export const getInitialStatusSelector = createSelector(
  [getConsultations],
  ({ initialSpecializationStatus }) => getTranformedStatus(initialSpecializationStatus)
);

export const getIsAllDrugsAvailable = createSelector(
  [getConsultations],
  ({ allConsultationInfo }) => defineIfAllUnavailable(allConsultationInfo?.drugs)
);

export const getPriceHome = createSelector(
  [getConsultations],
  ({bookingDetails}) => bookingDetails?.specialty?.detail?.priceHome
)
export const getDrugs = createSelector(
  [getConsultations],
  ({drugs}) => drugs
)
export const getPriceOnline = createSelector(
  [getConsultations],
  ({bookingDetails}) => bookingDetails?.specialty?.detail?.priceOnline
)
export const getPriceHospital = createSelector(
  [getConsultations],
  ({bookingDetails}) => bookingDetails?.specialty?.detail?.priceHospital
)
export const getPageCount = createSelector(
  [getConsultations],
  ({pageCount, itemsBySearch, pageCountBySearch}) => {
    return itemsBySearch && Object.keys(itemsBySearch).length > 0
      ? pageCountBySearch
      : pageCount
  }
);
export const getCurrentPage = createSelector(
  [getConsultations],
  ({ currentPage, itemsBySearch, currentSearchPage }) => {
    return itemsBySearch && Object.keys(itemsBySearch).length > 0
      ? currentSearchPage
      : currentPage
  }
);
export const getAnamnesisList = createSelector(
  getMessages,
  getAnamnes,
  (messages, anamnesis) => {
    if(!messages.length || !anamnesis.length) return []
    const allAnamnezQuestions = messages
      .filter(el => el?.customParameters?.question_id)
      .map(el => +el?.customParameters?.question_id)
    
    const isAlreadyExist = anamnesis.some(el => allAnamnezQuestions.includes(el.id))
    if(isAlreadyExist) return []
    return anamnesis
  }
);

export const getUsedAnamnesis = createSelector(
  [getConsultations],
  ({ usedAnamnesis }) => usedAnamnesis
);
export const getLoading = createSelector(
  [getConsultations],
  ({ loading }) => loading
);
export const getAnamnezLoading = createSelector(
  [getConsultations],
  ({ anamnezLoading }) => anamnezLoading
);
export const getFondySrc = createSelector(
  [getConsultations],
  ({ fondySrc }) => fondySrc
);
export const getIsNeesToPay = createSelector(
  [getConsultations],
  ({ isNeedToPay }) => isNeedToPay
);
export const getCurrentCardId = createSelector(
  [getConsultations],
  ({ currentCardId }) => currentCardId
);
export const getBookedConsultationId = createSelector(
  [getConsultations],
  ({bookedConsultationId}) => bookedConsultationId
)
export const getWasCancalled = createSelector(
  [getConsultations],
  ({wasCancelled}) => wasCancelled
)
export const getPriceLoading = createSelector(
  [getConsultations],
  ({priceLoading}) => priceLoading
)

export const getCurrentConsultationPrice = createSelector(
  [getConsultations],
  ({consultationPrice}) => consultationPrice
)

export const getIsSearchingConsult = createSelector(
  [getConsultations],
  ({itemsBySearch}) => itemsBySearch && itemsBySearch.length > 0
)

export const getDisableAnamnezLoading = (state) => state.consultations.disableAnamnezLoading

export const configureDrugList = (drugs) => {
  if (!drugs) return null
  return drugs.reduce((acc, el) => {
    acc[el.id] = {
      ...el.booking_info[0],
      quantity: 1,
      selected: false,
    }
    return acc
  }, {})
}

export const defineIfAllUnavailable = (drugs) => {
  if (!drugs) return false
  return drugs.every(el => el?.booking_info[0]?.status === 'unavailable')
}

const consultTypes = {
  '1': 'home',
  '0': 'online',
  '2': 'hospital',
}

export const getTranformedStatus = (statusObj) => {
  if (!statusObj) return null
  let newObj = {}
  for (let key in statusObj) {
    if (key === 'general') {
      newObj[key] = statusObj[key]
    } else {
      newObj[consultTypes[key]] = statusObj[key]
    }
  }
  return newObj
}