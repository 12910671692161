import { makeStyles } from '@material-ui/core';

export default makeStyles({
  button: {
    position: "relative",
    background: "#3288cd",
    height: "50px",
    color: "white",
    fontSize: '30px',
    fontWeight: "300",
    textTransform: "none",
    borderRadius: "8px",
    '&:hover': {
      background: "#3287cde1"
    },
    '& span .absoluteCenter': {
      right: "5px",
      left: 'auto',
      top: "29px"
    }
  },
  buttonBlack: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "black",
  },
  buttonBack: {
    position: "absolute",
    top: "5px",
    left: "5px",
    color: "black",
  }
})