import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './redux/store';
import './i18n';
import './index.scss'
import NotificationProvider from './views/components/NotificationProvider/NotificationProvider';
import ErrorBoundary from './views/components/ErrorBoundary/ErrorBoundary';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
