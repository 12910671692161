import { createAction } from "@reduxjs/toolkit";
import createTransform from "redux-persist/es/createTransform";

export const getSupportHistorySuccess = createAction("chat/getSupportHistorySuccess")
export const getSupportHistoryError = createAction("chat/getSupportHistoryError")
export const getChatHistoryRequest = createAction("chat/getChatHistoryRequest")
export const loadMoreRequest = createAction("chat/loadMoreRequest")
export const setChatMemberCount = createAction("chat/setChatMemberCount")
export const getAppointmentChatHistorySuccess = createAction("chat/getAppointmentChatHistorySuccess")
export const changeChatMode = createAction("chat/changeChatMode")
export const setCallInfo = createAction("chat/setCallInfo")
export const resetSearch = createAction("chat/resetSearch")

export const sendMessage = createAction("chat/sendMessage")
export const addMessage = createAction("chat/addMessage")
export const clearMessages = createAction('chat/clearMessages')
export const startAudioChat = createAction("chat/startAudioChat")
///getting host as payload
///ORDINARY CHAT
export const wsSupportConnect = createAction("chat/supportConnection")
export const wsSupportConnecting = createAction("chat/wsSupportConnecting")
export const wsSupportConnected = createAction("chat/wsSupportConnected")
export const wsSupportDisconnect = createAction("chat/wsSupportDisconnect")
export const wsSupportDisconnected = createAction("chat/wsSupportDisconnected")

// export const wsAppointmentConnect = createAction("chat/appointmentConnection")
// export const wsAppointmentDisconnect = createAction("chat/appointmentDisconnect")
///CALL CHAT
export const wsCallConnection = createAction("chat/callConnection")
export const wsCallDisconnect = createAction("chat/callSocketDisconnect")
export const rejectCall = createAction("chat/rejectCall")
export const answerCall = createAction("chat/answerCall")
export const sendPeerTracks = createAction("chat/sendPeerTracks")
export const getNextPageRequest = createAction("chat/getNextPageRequest")
export const defineLastPage = createAction("chat/defineLastPage")
export const getBySearchSuccess = createAction("chat/getBySearchSuccess")
export const requestNoResults = createAction("chat/requestNoResults")
export const endCall = createAction("chat/endCall")
export const muteAudio = createAction("chat/muteAudio")
export const muteVideo = createAction("chat/muteVideo")
export const setVideoStatus = createAction("chat/setVideoStatus")
export const setAudioStatus = createAction("chat/setAudioStatus")

export const startVideoChat = createAction("chat/startVideoChat")
export const setRemoteVideo = createAction("chat/setRemoteVideo")
export const setLocalVideo = createAction("chat/setLocalVideo")

export const setModalCallInfo = createAction("chat/setModalCallInfo")
export const setConnectedToPeer = createAction("chat/setConnectedToPeer")

export const sendNotificationToOperator = createAction('chat/sendNotificationToOperator')
export const allowMicrophone = createAction("chat/allowMicrophone")
export const leaveChatRoom = createAction("chat/chatRoomLeave")
export const moveCallToBackground = createAction("chat/moveCallToBackground")
export const isBackgroundCallNeeded = createAction("chat/isBackgroundCallNeeded")
export const updateCall = createAction("chat/updateCall")
export const sendGreetingMessage = createAction("chat/sendGreetingMessage")
export const sendAnamnezMessage = createAction("chat/sendAnamnezMessage")
export const openCallFromBackground = createAction("chat/openCallFromBackground")
export const resetStatus = createAction("chat/resetStatus")
export const setConnectionState = createAction("chat/setConnectionState")
export const setIsTheEndPage = createAction("chat/setIsTheEndPage")