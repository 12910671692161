export default {
  CONNECT: "connect",
  MESSAGE: "message",
  IMAGE: "image",
  VIDEO: "video",
  VOICE: "voice",
  FILE: "file",
  CHAT_BOT: "chatBot",
  CANCELATION: "cancelation",
  FINISHED: "finished",
  REJECT: "reject",
  ANSWER: "answer",
  OFFER: "offer",
  CANDIDATE: "candidate",
  CALL: "call",
  READY: "ready",
  END_CALL: "ended",
  CALL_ANSWERED: "call_answered",
  UNKNOWN: "unknown"
}