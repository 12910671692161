const types = {
  MICROPHONE: 'microphone',
  CAMERA: 'camera',
  NOTIFICATIONS: 'notifications',
}


const getPermissions = async (utilName = '') => {
  const perm = await navigator.permissions.query({ name: utilName })
  if(!perm?.state) return false
  switch (perm.state) {
    case 'granted':
      return true
    case 'prompt':
      return false
    case 'denied':
      return null
    default: return false
  }
}

export const requestForAudioPermission = async () => {
  const isAllowed = await getPermissions(types.MICROPHONE)
  if(isAllowed === null) return false
  if (isAllowed) return true
  if (!isAllowed) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      stream.getTracks().forEach((tr) => tr.stop())
      return true
    } catch (e) {
      return false
    }
  }
}

export const requestForVideoPermission = async () => {
  const isAllowed = await getPermissions(types.CAMERA)
  if (isAllowed) return
  if (!isAllowed) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      stream.getTracks().forEach((tr) => tr.stop())
    } catch (e) {
      console.log(e);
    }
  }
}