import { createSelector } from "@reduxjs/toolkit";

// const defaultState = { popup: { ...defaultConsultations }};

const getPopup = (state = {}) =>
  state.popup;

export const getPopups = createSelector(
  [getPopup],
  (popups) => popups
);
