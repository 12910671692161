import React from 'react'
import Button from '../../ui/Button/Button'
import {logOut} from '../../../../redux/auth/auth-operations'
import {getLogoutLoading} from '../../../../redux/auth/auth-selectors'
import '../navbar.scss'
import { useTranslation } from 'react-i18next'
import patientActive from '../../../../style/icons/Subscription/patient_active.svg'
import previousIcon from '../../../../style/icons/Subscription/previousIcon.svg'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { openPromocodeModal } from "../../../../redux/banners/banners-actions"

const UserTool = ({
  user,
  setCardListOpen,
  setDeleteAccountPopupOpen,
  listIsOpen,
  cardListOpen,
  promocodeOpen,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const logoutLoading = useSelector(getLogoutLoading)

  const handleLogOut = () => dispatch(logOut())

  const handleOpenPromocode = () => {
    dispatch(openPromocodeModal(true))
  }

  return (
    <div className='dropDownContainer'>
      {!listIsOpen && !cardListOpen && !promocodeOpen && (
        <img
          width='120'
          height='120'
          className="dropDownImage"
          src={user?.photoUrl || patientActive}
          alt="user-photo"
        />
      )}
      <div className='dropMenuItem'>
        <div className='dropTitle'>{t("name")}</div>
        <div className='dropInfo'>{user?.firstName}</div>
      </div>
      <div className='dropMenuItem'>
        <div className='dropTitle'>{t("lastName")}</div>
        <div className='dropInfo'>{user?.lastName}</div>
      </div>
      <div className='dropMenuItem'>
        <div className='dropTitle'>{t("Surname")}</div>
        <div className='dropInfo'>{user?.patronymic}</div>
      </div>
      <div className='dropMenuItem'>
        <div className='dropTitle'>{t("Phone")}</div>
        <div className='dropInfo'>{user?.phone}</div>
      </div>
      {user.email && (
        <div className='dropMenuItem'>
          <div className='dropTitle'>{t("Email")}</div>
          <div className='dropInfo'>{user?.email}</div>
        </div>
      )}
      <div onClick={() => {
        setCardListOpen(true)
      }} className='SubscriptionMenuItem'>
        <div style={{margin: "0px"}} className='dropInfo'>{t("subscription")}</div>
          <img className='openIcon' src={previousIcon} alt="previousIcon" />
      </div>

      <Button
        onClick={handleLogOut}
        buttonLoading={logoutLoading}
        type='button'
        className='btn'
        secondary
        text={t("logoutButton")}
      />
      <Button
        onClick={() => { setDeleteAccountPopupOpen(true) } }
        type='button'
        className='btn redOutlined'
        style={{ marginTop: '20px' }}
        text={t("deleteAccount")}
      />
    </div>
  )
}

export default UserTool