import { setCallInfo, setModalCallInfo } from "../chat/chat-actions";
import { setUnreadedNotificationsCount } from "../notifications/notifications-actions";
// import firebase from "firebase/compat/app";
// import "firebase/compat/messaging";
// import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import api from "../../api";
import { getNotifications } from "../notifications/notifications-operations";
import { addNotification } from "../popup/popup-actions";
import { v4 } from "uuid";
import { getAllConsultations } from "../consultations/consultations-operations";
import i18n from "../../i18n";
import { sendReady } from "./socketActions";

class NotificationService {
  constructor(dispatch) {
    // this.messaging = firebase.messaging();
    this.registerFirebase();
    this.dispatch = dispatch;
    this.unreadedCount = 0;
    this.currentLocation = window.location.pathname;
  }

  registerFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyB6_Dpzsy22DATLAiUwoy2HMFeXQomBRdM",
      authDomain: "dobrodoc-392f5.firebaseapp.com",
      databaseURL: "https://dobrodoc-392f5.firebaseio.com",
      projectId: "dobrodoc-392f5",
      storageBucket: "dobrodoc-392f5.appspot.com",
      messagingSenderId: "895113743631",
      appId: "1:895113743631:web:96b9d0283b5172b396c27a",
      measurementId: "G-W96KLKL1Z8",
    };
    const app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(app);
  }

  async createMessaging() {
    if (!("Notification" in window)) {
      console.log("NOTIFICATIONS OFF");
    } else {
      console.log("NOTIFICATIONS ON");
      try {
        const token = await getToken(this.messaging);
        localStorage.setItem("sentFirebaseMessagingToken", token);
        await api.user.sendFirebaseToken(token);
        this.messaging.onMessageHandler = this.onNotification;
      } catch (e) {
        console.log("Error in connection to firebase messaging", e.message);
      }
    }
  }

  showNotification({
    message,
    isSupport,
    redirectUrl,
    disableCounter = false,
  }) {
    if (!disableCounter) {
      this.setNotificationsCountAlert();
    }
    this.dispatch(
      addNotification({
        type: "SUCCESS",
        id: v4(),
        message,
        isSupport,
        redirectUrl,
      })
    );
  }

  setNotificationsCountAlert() {
    localStorage.setItem("unreadedCount", this.unreadedCount + 1);
    this.dispatch(setUnreadedNotificationsCount(this.unreadedCount + 1));
  }

  onNotification = (msg) => {
    console.log(msg);
    if (!msg?.data?.pushCategory) return;
    this.currentLocation = window.location.pathname;
    this.unreadedCount = +localStorage.getItem("unreadedCount") || 0;

    if (!this.currentLocation.includes("notifications")) {
      this.dispatch(getNotifications());
    }
    const { appointmentId, doctorId, doctorSpecialtyId } = msg.data;
    const { pushCategory, pushType, title, message } = msg.data;

    switch (pushCategory) {
      case "chat":
        switch (pushType) {
          case "doctor":
            if (this.currentLocation.includes("appointment")) return;
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: `/appointment?id=${appointmentId}&doctorId=${doctorId}&doctorSpecialtyId=${doctorSpecialtyId}`,
            });
            return;

          case "operator":
            if (this.currentLocation.includes("support")) return;
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: "/support",
            });
            return;
          default:
            break;
        }
        break;

      case "call":
        switch (pushType) {
          case "incoming":
            const { data } = msg;
            if (!this.currentLocation.includes("appointment")) {
              let callType = "";
              if (data.type === "2") {
                callType = "video";
              }
              if (data.type === "1") {
                callType = "audio";
              }
              this.dispatch(setModalCallInfo(data));
              this.dispatch(setCallInfo(data));
              this.dispatch(sendReady({ type: callType }));
            }
            return;

          case "missed":
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: null,
            });
            return;
          default:
            break;
        }
        break;

      case "appointment":
        this.dispatch(getAllConsultations());
        switch (pushType) {
          case "create":
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: "/consultations",
            });
            return;

          case "start":
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: "/consultations",
            });
            return;

          case "cancel":
            this.showNotification({
              message: message || title,
              isSupport: !!msg.data.isSupport,
              redirectUrl: "/consultations",
            });
            return;

          case "done":
            this.showNotification({
              message: i18n.t("endConsult") + (message || title),
              isSupport: !!msg.data.isSupport,
              redirectUrl: "/consultations",
            });
            return;
          default:
            break;
        }
        break;
      default:
        return;
    }
  };
}

export default NotificationService;
