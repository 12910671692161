import { AudioWaveArr } from "../../../../utils/audioWave"
import { useAudioStyles } from "../audioStyles"
import React, { useRef } from 'react';
import '../chat1.scss'
import { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import oneArrowWhite from '../../../../style/icons/Chat/oneArrowWhite.svg'
import twoArrowsWhite from '../../../../style/icons/Chat/twoArrowsWhite.svg'
import { getMessageTime } from '../../../../utils/helpers';
import { PlayCircleOutline } from '@material-ui/icons';
import { PauseCircleOutlineOutlined } from '@material-ui/icons';

const AudioMessage = ({
  message = String,
  isSentByUser = Boolean,
  areBothInChat = Boolean,
  customParameters = Object,
  dateSent = String,
  status = Number,
  type = String,
  userId = Number,
  recordedFormat,
}) => {
  const [isAudioPlay, setAudioPlay] = useState(false)
  const [currentPlayTime, setCurrentPlayTime] = useState(0)
  const [audioDuration, setAudioDuration] = useState(0)
  const [animationClassName, setAnimationClassName] = useState('anim')
  const audioRef = useRef(null)
  const classes = useAudioStyles()
  const intervalId = useRef()
  const animBoxRef = useRef(null)

  useEffect(() => {
    return () => clearInterval(intervalId.current)
  }, [])
  
  const handlePlayAudio = () => {
    startTimer()
    setAnimationClassName(prev => `${prev} addAnim`)
    audioRef.current.play()
  }


  const handlePauseAudio = () => {
    pauseTimer()
    audioRef.current.pause()
  }

  const startTimer = () => {
    intervalId.current = setInterval(() => {
      setCurrentPlayTime(prev => prev + 250)
    }, 250)
  }

  const pauseTimer = () => {
    clearInterval(intervalId.current)
  }

  const handleOnEnded = () => {
    setAnimationClassName('anim')
    pauseTimer()
    setCurrentPlayTime(0)
  }

  const handleOnLoadedData = () => {
    const durationInS = audioRef.current.duration 
    if (durationInS !== Infinity) {
      setAudioDuration(durationInS)
    } else {
      setAudioDuration(+customParameters.audioDuration / 1000)
    }
  }

  const durationFormat = moment(audioDuration, 's.S').format('mm:ss.S')
  const currentTimeFormat = moment(currentPlayTime).format('mm:ss')
  const durationInS = moment(audioDuration, 's.S').format('s.S')

  
  return (
    <div
      id='audioContainer'
      className={`audioMessageBox ${isSentByUser ? 'me' : 'you'} ${recordedFormat ? 'recordingFormat' : ''}`}
    >
      <div style={recordedFormat ? {justifyContent: "flex-start"} : {}} className="playBox">
        {isAudioPlay ? (
          <PauseCircleOutlineOutlined
            onClick={handlePauseAudio}
            className={isSentByUser ? classes.playMeButton : classes.playButton}
          />
        ) : (
          <PlayCircleOutline
            onClick={handlePlayAudio}
            className={isSentByUser ? classes.playMeButton : classes.playButton}
          />
        )}
        <div className="statusBox">
          <div className="wave">
            <div className="animationBox">
              {AudioWaveArr.map(({ height }, i) => {
                return <div key={i} style={{ height: height + 'px', background: isSentByUser ? 'white' : "lightgrey"}} className='line'></div>
              })}
            </div>
            <div
              ref={animBoxRef}
              className={animationClassName}
              style={{animationDuration: `${durationInS}s`, animationPlayState: isAudioPlay ? 'running' : 'paused'}}
            >
              <div className="lineBox">
                {AudioWaveArr.map(({ height }, i) => {
                  return <div key={i} style={{ height: height + 'px', background: isSentByUser ? "lightgrey" : "#3288cd" }} className="line"></div>
                })}
              </div>
            </div>
          </div>
          <div className="audioDuration">{`${currentTimeFormat}/${durationFormat}`}</div>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={message}
        onLoadedMetadata={handleOnLoadedData}
        onEnded={handleOnEnded}
        onPause={() => setAudioPlay(false)}
        onPlay={() => setAudioPlay(true)}
      ></audio>
      {!recordedFormat && (
        <div className='messageInfo'>
          <div className='messageTime'>{getMessageTime(dateSent)}</div>
          <img
            className={`messageReaded ${(+status === 2 || areBothInChat) ? 'readed' : "sent"}`}
            src={(+status === 2 || areBothInChat) ? twoArrowsWhite : oneArrowWhite}
            alt=""
          />
        </div>
      )}
    </div>
  )
}


export default AudioMessage;