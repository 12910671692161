import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllSpecializations: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/specialty?perPage=20`,
      headers: setHeader()
    }
    return axios(config).then(res => res)
  },
}