import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  GET_NETWORK_ERROR,
  SET_NETWORK_ERROR,
} from './networkError-actions'

const initialState = {
  isGetError: false,
  status: "",
  hold: "",
  succes: "",
  data: {
    message: "",
  },
};

const networkError = createReducer(initialState, {
  [GET_NETWORK_ERROR]: (state) => {
    return {
      ...state,
      isGetError: true,
    };
  },
  [SET_NETWORK_ERROR]: (state, { payload }) => {
    return {
      ...state,
      isGetError: false,
      ...payload,
    };
  },
});

export default networkError;