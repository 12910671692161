import api from '../../api'
import {
  getHospitalGroupsSuccess,
  getHospitalsSuccess,
  getRequest,
} from './hospitals-actions'
// filter[specialty]=38&filter[fullName]=&filter[type]=0

export const getHospitalGroups = (specialtyId) => async dispatch => {
  try {
    const data = await api.hospitals.getHospitalGroups(specialtyId)
    dispatch(getHospitalGroupsSuccess(data))
  } catch(error) {
    console.log(error);
  }
}

export const getHospitals = (groupId, specialtyId, query = '') => async dispatch => {
  dispatch(getRequest())
  try {
    const data = await api.hospitals.getHospitals(groupId, specialtyId, query)
    dispatch(getHospitalsSuccess(data))
  } catch (e) {
    console.log(e);
  }
}
