import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getUserIp } from "../../../../utils/common";
import "./phoneInput.scss"
import "../SimpleInput/simpleInput.scss"
import es from 'react-phone-input-2/lang/es.json'
import ru from 'react-phone-input-2/lang/ru.json'

import "../formComponents.scss";
import { useTranslation } from "react-i18next";

const InputPhone = ({
  onChange,
  name,
  value,
  placeholder,
  disabled,
  className,
  ...props
}) => {
  const [code, setCode] = useState("ua");
  const [focus, setFocus] = useState(false);
  const lang = localStorage.getItem("lang")
  const {t} = useTranslation()
  useEffect(() => {
    getUserIp()
      .then(countryCode => setCode(countryCode))
  }, []);

  const localization = (lang === "ru" || lang === "ua") ? ru : es;

  return (
    <div className="authFieldSimpleInput">
      <PhoneInput
        country={code}
        inputStyle={{
          textAlign: "left",
          paddingLeft: "60px"
        }}
        searchStyle={{
          width: "90%",
          border: "none",
          boxShadow: "0px 0px 4px 1px #3287cd69"
        }}
        searchPlaceholder={t("placeholderSearch")}
        searchClass={"searchInput"}
        inputProps={{
          name: name,
          className: "simpleInput",
          id: "inputphone",
        }}
        buttonClass={"phoneDropDownButton"}
        buttonStyle={{
          background: "transparent",
          border: "none",
          width: "50px",
          paddingLeft: "10px",
          left: "2px",
          margin: "0px",
          padding: "0px",
          borderBottomLeftRadius: "8px",
          borderTopLeftRadius: "8px",
        }}
        dropdownClass={"dropDown"}
        dropdownStyle={{
          boxShadow: "0px 0px 4px 1px #3287cd69",
          borderRadius: "8px"
        }}
        enableSearch={true}
        disabled={disabled}
        onChange={onChange}
        localization={localization}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
        placeholder={"+380XXXXXXX"}
        countryCodeEditable={false}
        {...props}
      />
    </div>
  );
};

export default InputPhone;