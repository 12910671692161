import api from '../../api'
import {
  getSpecializationsSuccess,
  getSpecializationsError,
} from './specializations-actions'

export const getAllSpecializations = () => async dispatch => {
  try {
    const { data } = await api.specializations.getAllSpecializations()
    dispatch(getSpecializationsSuccess(data))
  } catch(e) {
    dispatch(getSpecializationsError(e.message))
  }
}