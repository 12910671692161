import { createSelector } from "@reduxjs/toolkit";

const defaultAddresses = {
  addresses: null,
  provinces: [],
  districts: [],
  loading: false,
};

const defaultState = { addresses: { ...defaultAddresses }};

const getAddresses = (state = defaultState) =>
  state.addresses;

export const getAddressesSelector = createSelector(
  [getAddresses],
  ({addresses}) => addresses
);
export const getProvincesSelector = createSelector(
  [getAddresses],
  ({provinces}) => provinces
);
export const getDistrictsSelector = createSelector(
  [getAddresses],
  ({ districts }) => districts
);
export const getAddressesLoading = createSelector(
  [getAddresses],
  ({ loading }) => loading
);
