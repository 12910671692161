import { setHeader, TEST_API } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAddresses: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/addresses`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
  addNewAddress: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/addresses`,
      headers: setHeader(),
      data
    }
    return axios(config).then(res => res.data)
  },
  getProvinces: (specialtyId = '') => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/provinces`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
  getDistricts: (provinceId, specializationId) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/provinces/${provinceId}/districts?specialty=${specializationId}`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
}