import api from '../../api'
import { setPopUpSuccess } from '../networkError/networkError-operations'
import { addNotification } from '../popup/popup-actions'
import { v4 } from 'uuid';
import {
  createCardSuccess,
  updateCardSuccess,
  photoRequest,
  photoSuccess,
  cardRequest,
} from './cards-actions'
import i18n from '../../i18n';

export const createCard = (data, blobImage = null, firstTime = false) => async dispatch => {
  dispatch(cardRequest())
  try {
    const dat = await api.cards.createCard(data)
    if (blobImage && dat?.entity?.id) {
      await api.cards.uploadCardImage(dat.entity.id, blobImage)
    }
    dispatch(createCardSuccess)

    if (firstTime) {
      dispatch(createCardSuccess())
      window.location.reload()
    }
    
    dispatch(addNotification({
      id: v4(),
      type: "SUCCESS",
      message: i18n.t("medcardSuccessCreate"),
      cardCreated: true,
      parameters: {
        redirectUrl: `/medical-cards`
      }
    }))
  } catch (e) {
    console.log(e);
  }
}

export const updateCard = (cardId, data, successInfo) => async dispatch => {
  try {
    await api.cards.updateCard(cardId, data)
    dispatch(addNotification({
      id: v4(),
      type: "SUCCESS",
      message: i18n.t("medcardSuccessUpdate"),
      parameters: {
        redirectUrl: `/medical-cards/${cardId}`
      }
    }))
  } catch (e) {
    console.log(e);
  }
}

export const deleteCard = (cardId) => async dispatch => {
  try {
    await api.cards.deleteCard(cardId)
    dispatch(addNotification({
      id: v4(),
      type: "SUCCESS",
      message: 'Медкарта была успешно удалена',
      parameters: {
        redirectUrl: `/medical-cards`
      }
    }))
  } catch (e) {
    console.log(e);
  }
}

export const uploadCardImage = (cardId, image) => async dispatch => {
  dispatch(photoRequest())
  try {
    await api.cards.uploadCardImage(cardId, image)
    dispatch(photoSuccess())
    dispatch(addNotification({
      id: v4(),
      type: "SUCCESS",
      message: 'Фото было успешно загружено',
      noClick: 'true',
    }))
  } catch (e) {
    console.log(e);
  }
}


