import axios from "axios";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSymptoms: (query) => {
    let config = {
        method: "GET",
        baseURL: `${TEST_API}/symptoms?search=${query}`,
        headers: setHeader(),
    };
    return axios(config).then(res => res.data)
  },
}