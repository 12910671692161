import React from "react";
import './button.scss'
import previousIcon from '../../../../style/icons/Subscription/previousIconBlue.svg'
import Spinner from "../Spinner/Spinner";

const Button = ({
  disabled,
  className,
  type,
  onClick,
  loading,
  secondary,
  shevron,
  freeTopSpace,
  text,
  withIcon,
  icon,
  filter,
  danger,
  green,
  dangerAnimation,
  disabledIcon,
  secondaryLock,
  optionAsList,
  labelsOption,
  save,
  primary,
  withArrowRight,
  secondaryRed,
  buttonLoading,
  smallLoader,
  style = {}
}) => {
  return (
    <button
      disabled={disabled || buttonLoading}
      type={type}
      className={`${className} ${freeTopSpace ? " freeTopSpace" : ""}${
        filter ? " filter" : ""
      }${withIcon ? "withIcon" : ""}${secondary ? " secondary" : ""}${
        danger ? " danger" : ""
        } ${primary ? " primary" : ""}${dangerAnimation ? ' dangerAnimation' : ''}
        ${secondaryRed ? " secondaryRed" : ""}
        ${secondaryLock ? ' secondaryLock' : ''}
        ${save ? ' save' : ''}
      ${disabled ? " disabled" : ""} ${green ? 'green' : ''}`}
      style={style}
      onClick={() => onClick && onClick()}
    >
      <span>
        {withIcon && icon && (
          <img
            className="btn-icon"
            src={disabled ? disabledIcon : icon}
            alt="btn-icon"
          />
        )}
        {!optionAsList ? (
          text || ''
        ) : (
            <div className="selectedLabels">
              {(labelsOption && labelsOption.length === 0) ||
                labelsOption.length === undefined
                ? text || ""
                : labelsOption.map((el, idEl) => <span key={idEl}>{el || ''}</span>)}
            </div>
          )}
      </span>
      {withArrowRight && (
        <img className='arrowRight' width='20' height='20' src={previousIcon} alt="arrow-right" />
      )}
      {shevron && <img className="btn-icon" src={shevron} alt="btn-icon" />}
      {buttonLoading && (
        <div className="spinnerBox">
          <Spinner width={smallLoader ? 20 : 30} height={smallLoader ? 20 : 30}/>
        </div>
      )}
    </button>
  );
};

export default Button;