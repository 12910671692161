import React from 'react';
import { connect } from 'react-redux';
import { getPopups } from '../../../redux/popup/popup-selectors';
import NotificationItem from '../NotificationItem/NotificationItem';
import './notificationProvider.scss'

const NotificationProvider = (props) => {
  return (
    <>
      <div className='notificationWrapper'>
        {props.popups.map(note => {
          return (
            <NotificationItem key={note.id} {...note}/>
          )
        })}
      </div>
      {props.children}
    </>
  );
}
const mapStateToProps = (state) => ({
  popups: getPopups(state),
})
export default connect(mapStateToProps)(NotificationProvider);
