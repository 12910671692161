import React from 'react';
import '../chat1.scss';
import oneArrowWhite from '../../../../style/icons/Chat/oneArrowWhite.svg'
import twoArrowsWhite from '../../../../style/icons/Chat/twoArrowsWhite.svg'
import { getMessageTime } from '../../../../utils/helpers';

const VideoMessage = ({
  areBothInChat = Boolean,
  message = String,
  customParameters = Object,
  dateSent = String,
  isSentByUser = Boolean,
  status = Number,
  type = String,
  userId = Number,
}) => {
  return (
    <div className={`videoMessageBox ${isSentByUser ? 'me' : 'you'}`}>
      <video className='videoMessage' controls src={message} />
      <div className='messageInfo'>
        <div className='messageTime'>{getMessageTime(dateSent)}</div>
        <img
          className={`messageReaded ${(+status === 2 || areBothInChat) ? 'readed' : "sent"}`}
          src={(+status === 2 || areBothInChat) ? twoArrowsWhite : oneArrowWhite}
          alt=""
        />
      </div>
    </div>
  )
}

export default VideoMessage;