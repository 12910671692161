const info = {
  getToken: () => {
    const token = localStorage.getItem('token')
    return token
  },
  getUserId: () => {
    const userId = localStorage.getItem('userId')
    return +userId
  },
  getLang: () => {
    const lang = localStorage.getItem('i18nextLng')
    return lang.slice(0,2)
  },
  getAppoitmentId: () => {
    const appId = 2008
    return appId
  }
}

export default info