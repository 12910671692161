import localStorage from 'redux-persist/es/storage'
import api from '../../api'
import {
  getConsultationHistorySuccess,
  getAllConsultationsSuccess,
  getConsultationError,
  getAnamnesisSuccess,
  getBookingDetailsSuccess,
  bookConsultationSuccess,
  consultationRequest,
  anamnezRequest,
  gotInfo,
  calculatePrice,
  priceRequest,
  cancelConsultationSuccess,
  getAllConsultationInfoSuccess,
  getInitialTypeStatus,
  repaySuccess,
  searchRequest,
  getBySearchSuccess,
  clearSearch,
  setIsSearchEmpty,
  getConsultAnamnesisSuccess,
  anamnesisDisabledSuccess,
  anamnezDisabledSuccess,
  anamnezDisableRequest,
} from './consultations-actions'
import moment from "moment"

const setBookedConsultationData = (entity) => {
  localStorage.setItem(`bookedConsultation${entity.id}`, JSON.stringify(entity))
}

export const checkIfIsPaid = (id) => {
  if(!id) return true
  const bookedConsultation = JSON.parse(localStorage.getItem(`bookedConsultation${id}`))
  if (bookedConsultation?.id) {
    return bookedConsultation.id == id ? false : true
  }
  return true
}

export const getBookedConsultationData = (id) => {
  return JSON.parse(localStorage.getItem(`bookedConsultation${id}`))
}

export const deleteConsultationDataIfPaid = (id) => {
  localStorage.removeItem(`bookedConsultation${id}`)
}

export const getAnamnesis = consultationId => async dispatch => {
  dispatch(anamnezRequest())
  try {
    const data  = await api.consultations.getConsultationAnamnesis(consultationId)
    dispatch(getAnamnesisSuccess(data))
  } catch (e) {
    console.log(e);
  }
}
export const getConsultAnamnesis = consultationId => async dispatch => {
  try {
    const data  = await api.consultations.getAnamnesis(consultationId)
    dispatch(getConsultAnamnesisSuccess(data))
  } catch (e) {
    console.log(e);
  }
}
export const answerOnAnamnez = (id, body) => async dispatch => {
  try {
    await api.consultations.answerAnamnesis(id, body)
    dispatch(getConsultAnamnesis(id))
  } catch (e) {
    console.log(e);
  }
}
export const disableAnamnesis = consultationId => async dispatch => {
  dispatch(anamnezDisableRequest())
  try {
    await api.consultations.disableAnamnesis(consultationId)
    await api.consultations.getAnamnesis(consultationId)
    dispatch(anamnezDisabledSuccess())
  } catch (e) {
    console.log(e);
  }
}
export const getConsultationHistory = (cardId, page) => async dispatch => {
  dispatch(consultationRequest())
  try {
    const { data } = await api.consultations.getConsultationHistory(cardId, page)
    dispatch(getConsultationHistorySuccess(data))
  } catch (e) {
    dispatch(getConsultationError(e.message))
  }
}

export const getAllConsultations = (page = 1) => async dispatch => {
  dispatch(consultationRequest())
  try {
    const { data } = await api.consultations.getAllConsultations(page)
    dispatch(getAllConsultationsSuccess(data))
  } catch (e) {
    dispatch(getConsultationError(e.message))
  }
}

export const getConsultationsBySearch = (query, start, end, page = 1) => async dispatch => {
  dispatch(searchRequest())
  const startFormat = start && moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")
  const endFormat = end && moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")
  try {
    const { data } = await api.consultations.getBySearch(query, startFormat, endFormat, page)
    if (data.items.length === 0) {
      dispatch(setIsSearchEmpty(true))
    } else {
      dispatch(setIsSearchEmpty(false))
    }
    dispatch(getBySearchSuccess(data))
  } catch (e) {
    console.log(e.message);
  }
}

export const getConsultationBookingDetails = (specialtyId) => async dispatch => {
  try {
    const {entity}  = await api.consultations.getBookingDetails(specialtyId)
    dispatch(getBookingDetailsSuccess(entity))
  } catch (e) {
    // dispatch(getConsultationError(e.message))
    console.log(e.message);
  }
}

export const bookConsultation = (specialtyId, requestBody) => async dispatch => {
  dispatch(consultationRequest())
  try {
    const data = await api.consultations.bookConsultation(specialtyId, requestBody)
    dispatch(bookConsultationSuccess(data))
    if (data?.entity?.isNeedToPay) {
      setBookedConsultationData(data.entity)
    }
  } catch (e) {
    // console.log(2342221111);
    dispatch(getConsultationError(e.message))
    throw new Error()
  }
}

export const getConsultationInfo = (appointmentId, callback) => async dispatch => {
  dispatch(anamnezRequest())
  try {
    const data = await api.consultations.getCurrentConsultationInfo(appointmentId)
    localStorage.setItem('currentConsultationInfo', JSON.stringify(data.entity))
    dispatch(getAllConsultationInfoSuccess(data))
    dispatch(gotInfo(data.entity.id))
    // callback && callback()
  } catch (e) {
    // throw new Error()
    dispatch(getConsultationError(e.message))
  }
}

export const getConsultationPrice = ({ specialtyId, doctorId, date, type, cardId, districtId}) => async dispatch => {
  let requestString = `specialty/${specialtyId}/type/${type}/card/${cardId}${doctorId ? `/doctor/${doctorId}` : ''}${date ? `/date/${date}` : ''}`
  if (type == 1 && districtId) requestString += `?district=${districtId}`
  if (type == 1 && !districtId) return
  console.log(requestString);
  console.log('districtId', districtId);
  dispatch(priceRequest())
  try {
    const data = await api.consultations.calculateConsultationPrice(requestString)
    dispatch(calculatePrice(data))
  } catch (e) {
    console.log(e.message);
  }
}

export const cancelConsultation = (id) => async dispatch => {
  // dispatch(priceRequest())
  try {
    await api.consultations.cancelConsultation(id)
    dispatch(cancelConsultationSuccess())
  } catch (e) {
    console.log(e.message);
  }
}

export const getInitialSpecializationStatus = (specialtyId) => async dispatch => {
  try {
    const data = await api.consultations.getInitialConsultationTypeStatus(specialtyId)
    dispatch(getInitialTypeStatus(data))
  } catch (e) {
    console.log(e.message);
  }
}

export const repayConsultation = (consultId) => async dispatch => {
  try {
    const data = await api.consultations.repayConsultation(consultId)
    dispatch(repaySuccess(data))
  } catch (e) {
    console.log(e.message);
  }
}

