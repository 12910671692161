import api from '../../api'
import { addNotification } from '../popup/popup-actions'
import {
  getSubscriptionSuccess,
  getSubscriptionRequest,
  subscriptionBookingRequest,
  getSubscriptionListSuccess,
  bookSubscriptionSuccess,
  requestError,
} from './subscription-actions'
import {v4} from 'uuid'
import i18n from '../../i18n'
// filter[specialty]=38&filter[fullName]=&filter[type]=0

export const getSubscriptionInfo = () => async dispatch => {
  dispatch(getSubscriptionRequest())
  try {
    const data = await api.subscription.getSubscriptionInfo()
    dispatch(getSubscriptionSuccess(data))
  } catch(error) {
    console.log(error);
  }
}

export const getSubscriptionList = (cardId) => async dispatch => {
  dispatch(getSubscriptionRequest())
  try {
    const data = await api.subscription.getSubscriptionList(cardId)
    dispatch(getSubscriptionListSuccess(data))
  } catch (error) {
    console.log(error);
  }
}

export const bookSubscription = (subId, cardId, promocode = null, bonuses = null) => async dispatch => {
  dispatch(subscriptionBookingRequest())
  try {
    const data = await api.subscription.bookSubscription(subId, cardId, promocode, bonuses)
    dispatch(bookSubscriptionSuccess(data))
    if (!data?.entity?.payment) {
      dispatch(addNotification({
        id: v4(),
        type: 'SUCCESS',
        noClick: 'true',
        message: i18n.t('subscriptionSuccess'),
      }))
      dispatch(getSubscriptionList())
    }
  } catch (error) {
    console.log(111111111);
    dispatch(requestError())
  }
}


export const getOnlyBookedSubscriptions = (cardId) => async dispatch => {
  dispatch(getSubscriptionRequest())
  try {
    const data = await api.subscription.getSubscriptionList(cardId, 'booked')
    dispatch(getSubscriptionListSuccess(data))
  } catch (error) {
    console.log(error);
  }
}
 