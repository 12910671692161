import { createSelector } from "@reduxjs/toolkit";

const defaultDoctors = {
  doctorsBySearch: null,
  // doctorsList: [],
  randomDoc: null,
  randomPeriods: null,
  periods: [],
  isGetDoctor: true,
  loading: false,
  currentPage: 1,
  pageCount: 1,
  searchLoading: false,
  doctorsByQuery: [],
  isEmptySearchResult: false,
  searchPage: 1,
  searchPageCount: 1,
};

const defaultState = { doctors: { ...defaultDoctors }};

const getDoctors = (state = defaultState) =>
  state.doctors;

export const getDoctorsList = createSelector(
  [getDoctors],
  ({ doctorsBySearch, doctorsByQuery }) => {
    return doctorsByQuery.length > 0
      ? doctorsByQuery
      : doctorsBySearch
  }
);

export const getDoctorsSearchLoading = createSelector(
  [getDoctors],
  ({searchLoading}) => searchLoading
);

export const getDoctorsCurrentPage = createSelector(
  [getDoctors],
  ({currentPage, searchPage, doctorsByQuery}) => {
    return doctorsByQuery.length > 0
      ? searchPage
      : currentPage
  }
);

export const getIsSearchEmpty = createSelector(
  [getDoctors],
  ({isEmptySearchResult}) => isEmptySearchResult
);

export const getDoctorsPageCount = createSelector(
  [getDoctors],
  ({pageCount, searchPageCount, doctorsByQuery}) => {
    return doctorsByQuery.length > 0
      ? searchPageCount
      : pageCount
  }
);

export const getDoctorsLoading = createSelector(
  [getDoctors],
  ({loading}) => loading
);

export const getPeriodsLoading = createSelector(
  [getDoctors],
  ({periodsLoading}) => periodsLoading
);

export const getPeriodsSelector = createSelector(
  [getDoctors],
  ({periods}) => periods
);

export const getRandomDoctorSelector = createSelector(
  [getDoctors],
  ({randomDoc}) => randomDoc
);