import {
    getBannersRequest,
    getBannersSuccess,
} from './banners-actions'
import api from '../../api'
import axios from 'axios'

export const getBanners = () => async dispatch => {
    try {
        const {data} = await api.banners.getBanners()
        dispatch(getBannersSuccess(data && data.items))
    } catch (error) {
        console.log(error.message);
    }  
}