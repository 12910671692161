import * as actions from "../chat/chat-actions";
import * as socketActions from "./socketActions";
import { types } from "./callEventTypes";
import { sleep } from "../../utils/sleep";

const messagesStatuses = {
  readed: 2,
  sent: 1,
};

export const socketHandlers = {
  onAuth:
    (dispatch) =>
    ({ user, status = 0 }) =>
      dispatch(socketActions.setAuthorized(true)),

  onGetHistoryWithOperator:
    (dispatch) =>
    ({ messages, current_page }) => {
      const unreadedMessages = messages
        .filter(
          (msg) => msg.status === messagesStatuses.sent && !msg.isSentByUser
        )
        .map((msg) => msg._id);

      if (unreadedMessages.length) {
        dispatch(
          socketActions.readStartMessages({
            list: unreadedMessages,
            messageType: "patientToOperator",
          })
        );
      }
      if (messages.length < 1) {
        dispatch(actions.setIsTheEndPage(true));
        return;
      }
      dispatch(
        actions.getAppointmentChatHistorySuccess({ messages, current_page })
      );
    },

  onGetHistoryAppointment:
    (dispatch, onGetHistoryPool = []) =>
    ({ messages, current_page }) => {
      if (current_page === 0 && messages.length === 0) {
        onGetHistoryPool.forEach((cb) => cb());
        onGetHistoryPool = [];
      } else {
        onGetHistoryPool = [];
      }
      const unreadedMessages = messages
        .filter(
          (msg) => msg.status === messagesStatuses.sent && !msg.isSentByUser
        )
        .map((msg) => msg._id);

      if (unreadedMessages.length) {
        dispatch(
          socketActions.readStartMessages({
            list: unreadedMessages,
            messageType: "patientToDoctor",
          })
        );
      }
      if (messages.length < 1) {
        dispatch(actions.setIsTheEndPage(true));
        return;
      }
      dispatch(
        actions.getAppointmentChatHistorySuccess({ messages, current_page })
      );
    },

  onMessage:
    (dispatch, onAnamnezMessage) =>
    ({ ...message }) => {
      const editedMessage = onAnamnezMessage(message);
      dispatch(actions.addMessage(editedMessage));
      if (!editedMessage.isSentByUser) {
        dispatch(
          socketActions.readStartMessages({
            list: [editedMessage._id],
            messageType: editedMessage.messageType,
          })
        );
      }
    },

  isReadMessageIds:
    (dispatch) =>
    ({ idsList }) =>
      dispatch(socketActions.readChatMessages({ list: idsList })),

  onJoinRoom:
    (dispatch) =>
    ({ userId, totalMembers }) =>
      dispatch(actions.setChatMemberCount(totalMembers)),

  onChatRoomLeave:
    (dispatch) =>
    ({ userId, totalMembers }) =>
      dispatch(actions.setChatMemberCount(totalMembers)),

  error: (dispatch) => (error) => console.log("ERROR", error),

  onCallMessage:
    (callTools) =>
    async ({ type, candidate, offer, ...message }) => {
      console.log({ type, candidate, offer, message });
      if (type === types.OFFER) callTools.handleOffer(offer);
      if (type === types.CALL) {
        await sleep(2500);
        callTools.handleCall(message);
      }
      if (type === types.CANDIDATE) callTools.handleCandidate(candidate);
      if (type === types.ENDED) callTools.handleEndCall({ endByMe: false });
      if (type === types.REJECT) callTools.handleEndCall({ endByMe: false });
    },
};
