import React from 'react';
import '../chat1.scss'
import oneArrowWhite from '../../../../style/icons/Chat/oneArrowWhite.svg'
import twoArrowsWhite from '../../../../style/icons/Chat/twoArrowsWhite.svg'
import { getMessageTime } from '../../../../utils/helpers';

const FileMessage = ({
  areBothInChat = Boolean,
  message = String,
  customParameters = Object,
  dateSent = String,
  isSentByUser = Boolean,
  status = String,
  type = String,
  userId = Number,
}) => {
  return (
    <div className={`ordinaryMessageBox ${isSentByUser ? 'me' : 'you'}`}>
      <a target='_blank' rel="noreferrer" href={message} className='messageLink'>{customParameters.file_name}</a>
      <div className='messageInfo'>
        <div className='messageTime'>{getMessageTime(dateSent)}</div>
        <img
          className={`messageReaded ${(+status === 2 || areBothInChat) ? 'readed' : "sent"}`}
          src={(+status === 2 || areBothInChat) ? twoArrowsWhite : oneArrowWhite}
          alt=""
        />
      </div>
    </div>
  )
}

export default FileMessage;