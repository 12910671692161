import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: "10px",
    // position: "absolute",
    // top: "10px",
    // left: "20px",
    color: "#3288cd"
  },
  pageIcon: {
    color: "#3288cd",
    width: "40px",
    height: "40px",
  }
}));