import { combineReducers, createReducer } from '@reduxjs/toolkit'
import chatModes from '../../utils/chatModes'
import { readChatMessages } from '../socket/socketActions'
import {
  getSupportHistorySuccess,
  getSupportHistoryError,
  getChatHistoryRequest,
  sendMessage,
  setChatMemberCount,
  getAppointmentChatHistorySuccess,
  changeChatMode,
  setCallInfo,
  addMessage,
  clearMessages,
  setVideoStatus,
  setAudioStatus,
  setModalCallInfo,
  allowMicrophone,
  setConnectedToPeer,
  isBackgroundCallNeeded,
  getNextPageRequest,
  getBySearchSuccess,
  resetSearch,
  resetStatus,
  setConnectionState,
  setIsTheEndPage,
} from './chat-actions'

const defaultChatMode = chatModes.CHAT

const messages = createReducer([], {
  [getSupportHistorySuccess]: (state, { payload }) => {
    if (payload?.data?.current_page === 0) {
      return payload?.data?.items
    } else {
      return [...payload?.data?.items, ...state]
    }
  },
  [getAppointmentChatHistorySuccess]: (state, { payload }) => {
    if (payload.current_page === 0) {
      return [...payload.messages]
    } else {
      return [...payload.messages, ...state]
    }
  },
  
  [readChatMessages]: (state, { payload }) => {
    console.log("sascdsc", payload);
    return state.map(message => payload.list.includes(message._id) ? ({ ...message, status: 2 }) : message)
  },
  
  [sendMessage]: (state, { payload }) => [...state, payload],
  [addMessage]: (state, { payload }) => {
    let newState = [...state]
    if (payload.customParameters.withButton) {
      newState = state.map((el) => {
        if (el.customParameters.withButton) {
          return {
            ...el,
            customParameters: {
              ...el.customParameters,
              withButton: false
            },
          }
        }
        return el
      })
    }
    return [...newState, payload]
  },
  [clearMessages]: (_, __) => []
})

const messagesBySearch = createReducer([], {
  [getBySearchSuccess]: (_, { payload }) => payload?.data?.items,
  [resetSearch]: (_, __) => []
})

const error = createReducer(null, {
  [getSupportHistoryError]: (_, {payload}) => payload.message
})

const currentPage = createReducer(0, {
  [getAppointmentChatHistorySuccess]: (_, { payload }) => payload.current_page,
  [clearMessages]: () => 0,
})

const loading = createReducer(false, {
  [getChatHistoryRequest]: (_, __) => true,
  [getSupportHistorySuccess]: (_, __) => false,
  [getSupportHistoryError]: (_, __) => false,
  [getAppointmentChatHistorySuccess]: (_, __) => false,
})

const areBothInChat = createReducer(false, {
  [setChatMemberCount]: (_, {payload}) => payload === 2 ? true : false,
})

const chatMode = createReducer(defaultChatMode, {
  [changeChatMode]: (_, {payload}) => payload
})

const callInfo = createReducer({}, {
  [setCallInfo]: (_, {payload}) => ({...payload})
})

const videoStatus = createReducer(true, {
  [setVideoStatus]: (state, _) => !state,
  [resetStatus]: () => true,
})

const audioStatus = createReducer(true, {
  [setAudioStatus]: (state, _) => !state,
  [resetStatus]: () => true,
})

const callModalInfo = createReducer(null, {
  [setModalCallInfo]: (_, {payload}) => payload,
})

const audioAllowed = createReducer(false, {
  [allowMicrophone]: (_, {payload}) => payload
})

const isPeerConnected = createReducer(false, {
  [setConnectedToPeer]: (_, {payload}) => payload
})

const isBackgroundCallNeededBool = createReducer(false, {
  [isBackgroundCallNeeded]: (_, {payload}) => payload
})

const isTheEndPage = createReducer(false, {
  [setIsTheEndPage]: (_, { payload }) => payload,
  [clearMessages]: () => false,
})

const nextPageLoading = createReducer(false, {
  [getNextPageRequest]: () => true,
  [getSupportHistorySuccess]: () => false,
  [getAppointmentChatHistorySuccess]: () => false,
  [getBySearchSuccess]: () => false,
})

const totalPageCount = createReducer(1, {
  // [getSupportHistorySuccess]: (_, {payload}) => payload?.data?.page_count,
  // [getAppointmentChatHistorySuccess]: (_, {payload}) => payload?.data?.page_count,
})

const isConnectedToSocket = createReducer(false, {
  [setConnectionState]: (_, {payload}) => payload,
})

export default combineReducers({
  loading,
  messages,
  error,
  areBothInChat,
  chatMode,
  callInfo,
  videoStatus,
  audioStatus,
  callModalInfo,
  audioAllowed,
  isPeerConnected,
  currentPage,
  nextPageLoading,
  isTheEndPage,
  totalPageCount,
  messagesBySearch,
  isConnectedToSocket,
  isBackgroundCallNeeded: isBackgroundCallNeededBool,
});