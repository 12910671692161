import React, { useRef } from 'react';
import { connect } from 'react-redux';
import './callChat.scss'
import answer from '../../../style/icons/Call/answer.svg'
import reject from '../../../style/icons/Call/reject.svg'
import { answerCall, endCall, muteAudio, rejectCall, startAudioChat } from '../../../redux/chat/chat-actions';
import { useEffect } from 'react';
import videoActive from '../../../style/icons/VideoChat/videoActive.svg'
import videoMuted from '../../../style/icons/VideoChat/videoMuted.svg'
import microActive from '../../../style/icons/VideoChat/microActive.svg'
import microMuted from '../../../style/icons/VideoChat/microMuted.svg'
// import end_сall from '../../../style/icons/VideoChat/end_call.svg'
import make_full_screen from "../../../style/icons/VideoChat/make_full_screen_video.svg";
import close_full_screen from "../../../style/icons/VideoChat/close_full_screen_video.svg";
import chatIcon from "../../../style/icons/VideoChat/chat.svg";
import reject_icon from '../../../style/icons/Call/reject.svg'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useState } from 'react';
import callSound from '../../../audios/call.mp3'
import {getCurrentCallInfoSelector} from "../../../redux/consultations/consultations-selectors"
import Carousel from "../ui/Carousel/Carousel"

const CallChat = ({
  callInfo,
  rejectCall,
  answerCall,
  isActive,
  startAudioChat,
  muteAudio,
  endCall,
  audioStatus,
  currentCallInfo,
  callType,
  doctorAudioRef,
}) => {
  const [duration, setDuration] = useState(0)
  const { t } = useTranslation()
  const callAudio = useRef(new Audio(callSound))
  const intervalId = useRef(null)

  useEffect(() => {
    if (!isActive) {
      callAudio.current.play()
    }
    if (isActive) {
      callAudio.current.pause()
    }
    return () => callAudio.current.pause()
  }, [isActive])
  
  const handleStartTimer = () => {
    const startTime = Date.now()
    intervalId.current = setInterval(() => {
      const exacDuration = Date.now() - startTime
      setDuration(moment(exacDuration).format('mm:ss'))
    }, 1000)
  }

  useEffect(() => {
    setTimeout(() => {
      startAudioChat()
    }, 500)
    return () => {
      handlePauseTimer()
    }
  }, [])

  useEffect(() => {
    if (isActive) {
      handleStartTimer()
    }
  }, [isActive])

  const handlePauseTimer = () => {
    clearInterval(intervalId.current)
  }

  return (
    <div className={`callChatContainer ${isActive ? 'isActive' : ""}`}>
      <audio ref={doctorAudioRef} autoPlay volume="true" id='doctorAudio'></audio>
      {!currentCallInfo && (
        <div className="loader">
          <Carousel />
        </div>
      )}
      {currentCallInfo && (
        <>
          {!callType && (
            <div className='callType'>{callInfo.type === '1' ? 'Аудиозвонок' : "Видеозвонок"}</div>
          )}
          {callType && (
            <div className='callType'>{callType === 'audio' ? 'Аудиозвонок' : "Видеозвонок"}</div>
          )}
          <div className="callInfoContainer">
            <img className={`callImage ${isActive ? 'isActive' : ''}`} src={currentCallInfo.photoUrl} alt="" />
            <div className='callSpecialization'>{currentCallInfo.specialtyTitle}</div>
            <div className='callDoctorName'>{currentCallInfo.fullName}</div>
            {isActive && (
              <div className='callDuration'>{duration === 0 ? '00:00' : duration}</div>
            )}
            {!isActive && (
              <div className='incomingCall'>{`${t("incomingCall")}...`}</div>
            )}
          </div>
          {!isActive && (
            <div className='toolsContainer'>
              <img onClick={() => answerCall()} className='toolImage' src={answer} alt="answer" />
              <img onClick={() => rejectCall()} className='toolImage' src={reject} alt="reject" />
            </div>
          )}
          {isActive && (
            <div className="toolPanel">
              <div className='toolContainer'>
                {/* <img
              onClick={() => muteVideo()}
              src={videoStatus ? videoActive : videoMuted}
              alt="video-tool"
              title={'mute video'}
            /> */}
                <img
                  onClick={() => muteAudio()}
                  src={audioStatus ? microActive : microMuted}
                  alt="micro-tool"
                  title={'mute audio'}
                />
                <img
                  onClick={() => endCall()}
                  style={{ width: "58px", height: "58px" }}
                  src={reject_icon} alt="end-call"
                  title={'end call'}
                />
              </div>
              {/* <div className='tool2Container'>
            <img
              onClick={() => handleFullScreen()}
              src={fullScreenChat ? close_full_screen : make_full_screen}
              alt="fullscreen"
              title={fullScreenChat ? 'выйти из полноэкранного режима' : 'полноэкранный режим'}
            />
            <img
              // onClick={() => setChatOpenedWithVideo(true)}
              className='showChatIcon'
              src={chatIcon}
              alt="open-chat"
              title={'показать чат'}
            />
          </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  callInfo: state.chat.callInfo,
  audioStatus: state.chat.audioStatus,
  currentCallInfo: getCurrentCallInfoSelector(state)
})

const mapDispatchToProps = {
  rejectCall,
  answerCall,
  startAudioChat,
  muteAudio,
  endCall,
}

export default connect(mapStateToProps, mapDispatchToProps)(CallChat);
