import { createSelector } from "@reduxjs/toolkit";

const defaultFishka = {
  isFishkaOpen: false,
  buttonLoading: false,
  patientBonuses: null,
  subscriptionBonuses: null,
  consultationBonuses: null,
  isRegistered: false,
  isAuthorized: false,
  directlyToRegister: false,
};

const defaultState = { fishka: { ...defaultFishka }};

const getFishka = (state = defaultState) =>
  state.fishka;

export const getIsFishkaOpen = createSelector(
  [getFishka],
  ({isFishkaOpen}) => isFishkaOpen
);

export const getFishkaButtonLoading = createSelector(
  [getFishka],
  ({buttonLoading}) => buttonLoading
);

export const getPatientBonusesSelector = createSelector(
  [getFishka],
  ({patientBonuses}) => patientBonuses
);

export const getConsultationBonusesSelector = createSelector(
  [getFishka],
  ({consultationBonuses}) => consultationBonuses
);

export const getSubscriptionBonusesSelector = createSelector(
  [getFishka],
  ({subscriptionBonuses}) => subscriptionBonuses
);

export const getIsRegistered = createSelector(
  [getFishka],
  ({isRegistered}) => isRegistered
);

export const getIsAuthorized = createSelector(
  [getFishka],
  ({isAuthorized}) => isAuthorized
);

export const getIsDirectlyToRegister = createSelector(
  [getFishka],
  ({directlyToRegister}) => directlyToRegister
);

