import React, { useEffect, useRef } from 'react';
import { SimpleForm } from '../../components/formComponents/SimpleForm/SimpleForm'
import SimpleInput from '../../components/formComponents/SimpleInput/SimpleInput'
import SimpleSelect from '../../components/formComponents/SimpleSelect/SimpleSelect'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../../components/ui/Button/Button';
import './cardForm.scss'
import AddressForm from '../AddressForm/AddressForm';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { parserForm } from '../../../utils/parserForm';
import DatePicker from '../../components/DatePicker/DatePicker';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { IconButton } from '@material-ui/core'
import MaterialButton from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import { LoadingComponent } from '../../pages/ConsultationBooking/ConsultationBooking';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const voidRequired = (value) =>
  (value && typeof value === "string" && !value.match(/^\s+$/)) ||
  (value && typeof value !== "string")
    ? undefined
    : " ";
const useStyles = makeStyles({
  button: {
    background: "#3288cd",
    height: "50px",
    color: "white",
    fontSize: '30px',
    fontWeight: "300",
    textTransform: "none",
    borderRadius: "8px",
    '&:hover': {
      background: "#3287cde1"
    },
    '& span .loaderBox': {
      position: "absolute",
      top: "-3px",
      // transform: "translateY(-50%)",
      right: "20px",
      width: "60px",
      height: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      '& .absoluteCenter': {
        position: 'static',
        transform: "translate(0, 0)",
        marginRight: "0px",
      }
    }
  },
  buttonBlack: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: "black",
  },
  buttonBack: {
    position: "absolute",
    top: "5px",
    left: "5px",
    color: "black",
  }
})

const CardForm = ({
  card = {},
  formValues,
  rel,
  adr = null,
  withNewAddress,
  formSubmit,
  setShowForm,
  showForm = false,
  setLoading,
  buttonText,
  userGender = null,
  userAddress = null,
  userRelation = null,
  genderOptions = null,
  setBirthDate,
  setBirthDateError,
  fishkaMode,
  buttonLoading,
  registerMode = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [previousValues, setPreviousValues] = useState(null)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    const formRef = document.querySelector('form[name="cardForm"]')
    const divFormRef = document.querySelector('.mainFormContainer')
    if (showForm && (formRef || divFormRef)) {
      setTimeout(() => {
        (formRef ?? divFormRef).style.scrollBehavior = 'smooth';
        (formRef ?? divFormRef).scrollTop = 99999
      }, 200)
    }
  }, [showForm]);

  useEffect(() => {
    if (formValues) {
      setBirthDate(formValues.birthDate)
    }
  }, [formValues])
  

  return (<>
    <SimpleForm
      className='simpleCardForm'
      style={{ position: "relative", overflowY: 'visible' }}
      handleSubmit={formSubmit}
      name='cardForm'
      autoComplete="off"
      novalidate
    >
      {!fishkaMode && (
        <div className='modeInfoContainer'>
          <div className='columnContainer'>
            <div className='columnFieldBox'>
              <SimpleInput
                name="lastName"
                defaultValue={card?.lastName || previousValues?.lastName}
                pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]?$"
                errorMessage={t('lastNameIsIncorrect')}
                placeholder={t("lastName")}
                label={t("lastName")}
                noTabs={true}
                required
              />
            </div>
            <div className='columnFieldBox'>
              <SimpleInput
                name="firstName"
                pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]?$"
                defaultValue={card?.firstName || previousValues?.firstName}
                errorMessage={t('firstNameIsIncorrect')}
                placeholder={t("name")}
                label={t("name")}
                noTabs={true}
                required
              />
            </div>
            <div className='columnFieldBox'>
              <SimpleInput
                name="patronymic"
                pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇЁ-щьюяєіїё'`]?$"
                defaultValue={card?.patronymic || previousValues?.patronymic}
                errorMessage={t('patronymicIsIncorrect')}
                placeholder={t("patronymic")}
                label={t("patronymic")}
                noTabs={true}
                required
              />
            </div>
            <div className='columnFieldBox'>
              <SimpleInput
                name="phone"
                defaultValue={card?.phone || previousValues?.phone}
                pattern="^\+[0-9]{12}$"
                errorMessage={t('phoneIsIncorrect')}
                placeholder={t("phoneNumber")}
                label={t("phoneNumber")}
                noTabs={true}
                required
              />
            </div>
            {!fishkaMode && (
              <div className='columnFieldBox'>
                <SimpleInput
                  name="email"
                  defaultValue={card?.email || previousValues?.email}
                  pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                  errorMessage={t("emailIsIncorrect")}
                  placeholder={t("email")}
                  label={t("email")}
                  noTabs={true}
                />
              </div>
            )}
          </div>
          <div className='columnContainer'>
            <div className='columnFieldBox'>
              <Field
                component={DatePicker}
                defaultValue={card?.birthDate ? moment(card.birthDate).format('DD/MM/YYYY') : ''}
                name={`birthDate`}
                placeholder={`${t("birthDate")}*`}
                required
                setBirthDateError={setBirthDateError}
                validDate={{ number: 0, type: "year", period: "isBefore" }}
                errorMessage={t("birthDateIsIncorrect")}
              />
            </div>
            <div className='columnFieldBox'>
              <SimpleSelect
                name="gender"
                // pattern="/^$|\s+/"
                placeholder={t("gender")}
                required
                options={genderOptions}
                defaultValue={userGender && userGender}
              />
            </div>
            {!fishkaMode && (<>
              <div className='columnFieldBox'>
                <SimpleSelect
                  name="relationShip"
                  defaultValue={userRelation && userRelation}
                  options={rel}
                  placeholder={t("relations")}
                  label={t("relations")}
                  required
                />
              </div>
              <div className='columnFieldBox'>
                <SimpleInput
                  name='policeNumber'
                  placeholder={t("policeNumber")}
                  label={t("policeNumber")}
                  defaultValue={card?.policeNumber || previousValues?.policeNumber}
                />
              </div>
            </>)}
            {adr && !fishkaMode && (
              <div className={`addressBox ${!withNewAddress ? 'noPadding' : ''}`}>
                {withNewAddress && (<>
                  <div className='addressButtonContainer'>
                    <IconButton
                      variant='contained'
                      color='primary'
                      style={{color: "#3288cd"}}
                      onClick={() => setShowForm(prev => !prev)}
                    >
                      {showForm && (
                        <CloseTwoToneIcon />
                      )}
                      {!showForm && (
                        <AddTwoToneIcon />
                      )}
                    </IconButton>
                  </div>
                </>)}
                <SimpleSelect
                  name='address'
                  defaultValue={userAddress ?? ''}
                  options={adr}
                  placeholder={t("address")}
                  label={t("address")}
                  // required
                />
              </div>
            )}
          </div>
        </div>
      )}
      {!showForm && !fishkaMode && (
          <Button
              type='submit'
              className='btn'
              style={{ width: "100%", position: "absolute", bottom: 0 }}
              text={buttonText}
          />
      )}
      {fishkaMode && (
        <div className='fishkaModeContainer'>
          {/* {registerMode && ( */}
            <div className='fishkaInputBox'>
              <SimpleInput
                name="phone"
                defaultValue={card?.phone || previousValues?.phone}
                pattern="^\+[0-9]{12}$"
                errorMessage={t('phoneIsIncorrect')}
                placeholder={t("phoneNumber")}
                label={t("phoneNumber")}
                required
              />
            </div>
          {/* )} */}
          {registerMode && (
            <div className='fishkaInputBox'>
              <SimpleInput
                name="email"
                defaultValue={card?.email || previousValues?.email}
                // pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                // errorMessage={t("emailIsIncorrect")}
                placeholder={t("email")}
                label={t("email")}
                // required
              />
            </div>
          )}
          <div className='fishkaInputBox'>
            <Field
              component={DatePicker}
              defaultValue={!registerMode ? moment(card.birthDate).format('DD/MM/YYYY') : ""}
              name={`birthDate`}
              placeholder={t("birthDate")}
              required
              setBirthDateError={setBirthDateError}
              validDate={{ number: 0, type: "year", period: "isBefore" }}
              errorMessage={t("birthDateIsIncorrect")}
            />
          </div>
          <div className='fishkaInputBox'>
            <SimpleSelect
              name="gender"
              placeholder={t("gender")}
              required
              options={genderOptions}
              defaultValue={userGender && userGender}
            />
          </div>
          <div className='fishkaInputBox'>
            <SimpleInput
              name="firstName"
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              defaultValue={card?.firstName || previousValues?.firstName}
              errorMessage={t('firstNameIsIncorrect')}
              placeholder={t("name")}
              label={t("name")}
              required
            />
          </div>
          <div className='fishkaInputBox'>
            <SimpleInput
              name="patronymic"
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              defaultValue={card?.patronymic || previousValues?.patronymic}
              errorMessage={t('patronymicIsIncorrect')}
              placeholder={t("patronymic")}
              label={t("patronymic")}
              required
            />
          </div>
          <div className='fishkaInputBox'>
            <SimpleInput
              name="lastName"
              defaultValue={card?.lastName || previousValues?.lastName}
              pattern="^[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`][a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]+[a-zA-Zа-яА-Я-А-ЩЬЮЯҐЄІЇ-щьюяєії'`]?$"
              errorMessage={t('lastNameIsIncorrect')}
              placeholder={t("lastName")}
              label={t("lastName")}
              required
            />
          </div>
          {!registerMode && (
            <div className='authCheckbox'>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    onChange={(e) => setChecked(e.target.checked)}
                    color="primary"
                    style={{ color: "#3288cd" }}
                  />
                }
                label={t("fishkaPolicyText")}
                labelPlacement="end"
              />
            </div>
          )}
          <div className='registerButton' style={{ bottom: '-80px' }}>
            <MaterialButton
              fullWidth
              variant='contained'
              className={classes.button}
              disabled={buttonLoading || (!registerMode && !checked)}
              // onClick={handleButtonClick}
              type='submit'
            >{t("registerAction")}
              <div className="loaderBox">
                <LoadingComponent loading={buttonLoading} width={35} height={35} />
              </div>
            </MaterialButton>
          </div>
        </div>
        )}
    </SimpleForm>
    {showForm && (
      <div style={{ width: "100%" }}>
        <div className='newAddressFormContainer'>
          <AddressForm
            setShowForm={setShowForm}
            setLoading={setLoading}
          />
        </div>
      </div>
    )}
  </>)
}

const mapStateToProps = (state) => ({
  formValues: getFormValues('cardForm')(state),
})

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({form: 'cardForm', enableReinitialize: true})
)

export default enhance(CardForm)
// export default compose(reduxForm({form: 'consultation', enableReinitialize: true}), CardForm)