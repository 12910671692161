import api from '../../api'
import {
  getRelationsSuccess,
} from './relations-actions'

export const getRelations = () => async dispatch => {
  try {
    const data = await api.relations.getRelations()
    dispatch(getRelationsSuccess(data))
  } catch (e) {
    console.log(e);
  }
}