import { setHeader, TEST_API } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getRelations: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/patients/cards/relations`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  }
}