import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getCurrentUser, login } from "../redux/auth/auth-operations";
import Home from "../views/pages/Home/Home";
import firebase from "firebase/app";
import LoadingPlaceholder from "../views/components/LoadingPlaceholder/LoadingPlaceholder";
import {
  getIsRegisterCardNeeded,
  getIsToken,
  getIsUser,
  getIsUserLoading,
} from "../redux/auth/auth-selectors";
import RegistrationAuthPage from "../views/pages/RegistrationAuthPage/RegistrationAuthPage";

function Authorization(Component) {
  // const firebaseConfig = {
  //   apiKey: "AIzaSyB6_Dpzsy22DATLAiUwoy2HMFeXQomBRdM",
  //   authDomain: "dobrodoc-392f5.firebaseapp.com",
  //   databaseURL: "https://dobrodoc-392f5.firebaseio.com",
  //   projectId: "dobrodoc-392f5",
  //   storageBucket: "dobrodoc-392f5.appspot.com",
  //   messagingSenderId: "895113743631",
  //   appId: "1:895113743631:web:96b9d0283b5172b396c27a",
  //   measurementId: "G-W96KLKL1Z8",
  // };

  // if (!firebase.apps.length) {
  //   firebase.initializeApp(firebaseConfig);
  // } else {
  //   firebase.app();
  // }

  return function (props) {
    const path1 = localStorage.getItem("path1");
    const { isRegisterNeeded, isToken, isUser, getCurrentUser, isUserLoading } =
      props;
    const pathToHome = isUser && !isUserLoading;
    const pathToPreloader = isUserLoading;
    const readyToGetUser =
      isToken && !isUser && !isUserLoading && !isRegisterNeeded;

    // return (
    //   <ModalWindow open={true} onlyContent>
    //     <div className="phoneModalBox">
    //       <img
    //         width='150'
    //         height='150'
    //         src={logo}
    //         alt="phone-icon"
    //       />
    //       <div className="phoneModalText">
    //         Тимчасово, у зв'язку з технічними роботами із забезпечення безперебійної роботи сервера, запис на консультацію до лікарів Мобільної клініки DobroDoc+ через службу підтримки за номером <span>0800330036</span>. З мобільних телефонів - безкоштовно.
    //       </div>
    //     </div>
    //   </ModalWindow>
    // )
    readyToGetUser && getCurrentUser();

    if (pathToHome) {
      return (
        <Component>
          <Home currentPath={path1} {...props} />
        </Component>
      );
    }

    if (pathToPreloader) {
      return <LoadingPlaceholder />;
    }

    return (
      <RegistrationAuthPage
        redirectTo={isToken && isRegisterNeeded ? "/registration" : "/login"}
        {...props}
      />
    );
  };
}

const mapDispatchToProps = {
  login,
  getCurrentUser,
};
const mapStateToProps = (state) => ({
  isUserLoading: getIsUserLoading(state),
  isUser: getIsUser(state),
  isToken: getIsToken(state),
  isRegisterNeeded: getIsRegisterCardNeeded(state),
});

const composedAuthHOC = compose(
  connect(mapStateToProps, mapDispatchToProps),
  Authorization
);

export default composedAuthHOC;
