import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  getAnalysisSuccess,
  getAnalysisError,
  getAnalysisRequest,
  loadMoreRequest,
  setEmpty,
  getLaboratoriesSuccess,
  bookAnalysisSuccess,
  clearAnalysis,
} from './analysis-actions'

const analysis = createReducer([], {
  [getAnalysisSuccess]: (state, { payload }) => [
    ...state,
    ...payload.data.items,
  ],
  [setEmpty]: (state, _) => state,
  [getAnalysisError]: (state, { payload }) => payload,
  [clearAnalysis]: (_, __) => []
})

const laboratories = createReducer([], {
  [getLaboratoriesSuccess]: (state, {payload}) => payload.data.items
})

const loading = createReducer(false, {
  [getAnalysisRequest]: (_, __) => true,
  [getAnalysisSuccess]: (_, __) => false,
  [getLaboratoriesSuccess]: (_, __) => false,
  [bookAnalysisSuccess]: (_, __) => false,
})

const loadingMore = createReducer(false, {
  [loadMoreRequest]: (_, __) => true,
  [getAnalysisSuccess]: (_, __) => false,
})

const currentPage = createReducer(0, {
  [getAnalysisSuccess]: (page, __) => page + 1
})



export default combineReducers({
  analysis,
  loading,
  loadingMore,
  currentPage,
  laboratories,
});