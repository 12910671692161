import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  openFishka,
  closeFishka,
  fishkaRequest,
  getPatientBonusesSuccess,
  registerInFishka,
  calculateSubscriptionBonusesSuccess,
  calculateConsultationBonusesSuccess,
  moveDirectlyToRegister,
} from './fishka-actions'

const patientBonuses = createReducer(null, {
  [getPatientBonusesSuccess]: (_, {payload}) => payload?.bonus?.fishka?.primaryBalance,
})

const isRegistered = createReducer(false, {
  [getPatientBonusesSuccess]: (_, {payload}) => !payload?.bonus?.fishka?.registerNeeded,
})

const isAuthorized = createReducer(false, {
  [getPatientBonusesSuccess]: (_, {payload}) => !payload?.bonus?.fishka?.authNeeded,
})

const consultationBonuses = createReducer(null, {
  [calculateConsultationBonusesSuccess]: (_, {payload}) => payload,
})

const subscriptionBonuses = createReducer(null, {
  [calculateSubscriptionBonusesSuccess]: (_, {payload}) => payload,
})

const directlyToRegister = createReducer(false, {
  [moveDirectlyToRegister]: (_, {payload}) => payload,
})

const isFishkaOpen = createReducer(false, {
  [openFishka]: (_, __) => true,
  [closeFishka]: (_, __) => false,
})

const buttonLoading = createReducer(false, {
  [fishkaRequest]: (_, __) => true,
  [getPatientBonusesSuccess]: (_, __) => false,
  [registerInFishka]: (_, __) => false
})

export default combineReducers({
  isFishkaOpen,
  buttonLoading,
  patientBonuses,
  subscriptionBonuses,
  consultationBonuses,
  isRegistered,
  isAuthorized,
  directlyToRegister,
})
