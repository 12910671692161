import React from 'react';
import { Route, Redirect } from 'react-router';
import Auth from '../Auth/Auth';
import Registration from '../Registration/Registration';

const RegistrationAuthPage = ({ redirectTo }) => {
  const currentLocation = `${window.location.pathname}${window.location.search}`
  const isStorageNeeded = currentLocation !== '/login' && currentLocation !== '/registration'
  if (isStorageNeeded) {
    localStorage.setItem('path1', currentLocation)
  }

  return (
    <>
      <Redirect to={redirectTo} to={{
        pathname: redirectTo,
        state: {refferrer: currentLocation}
      }} />
      <Route exact path='/login' component={Auth}/>
      <Route exact path='/registration' component={Registration}/>
    </>
  );
}

export default RegistrationAuthPage;
