import { setHeader, TEST_API } from ".."
import axios from 'axios'

export default {
  getBanners: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/onboarding`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  }
}