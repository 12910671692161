import axios from "axios";
import { handleStatusUploadFile } from "../../utils/http";
import {
  setHeader,
  TEST_API,
  SIGNALING_SERVER,
  PORT_CHAT_SOCKET,
  PORT_SUPPORT_SOCKET,
  PORT_ATTENDING_DOCTOR,
} from "../index";

export default {
  getChatMessagesTemplates: (data) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/chat-template-groups`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  deviceCall: ({ appointmentId, data }) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/call/${appointmentId}`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  chatAudioMsgUpload: ({ id, file }) => {
    let formData = new FormData();
    formData.append("audioFile", file);
    return axios.post(
      `${TEST_API}/admin/devices/uploads/audio/${id}`,
      formData,
      {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  chatFileUpload: ({ appointmentId, file, setUploadStatus = () => {}  }) => {
    let formData = new FormData();
    formData.append("file", file);
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/chats/${appointmentId}/upload`,
      headers: {
        ...setHeader(),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress: (e) => setUploadStatus(handleStatusUploadFile(e))
    }
    return axios(config).then(res => res.data)
  },

  chatOperatorFileUpload: ({ userId, file, setUploadStatus = () => {} }) => {
    let formData = new FormData();
    formData.append("file", file);
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/support/${userId}/upload`,
      headers: {
        ...setHeader(),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress: (e) => setUploadStatus(handleStatusUploadFile(e))
    }
    return axios(config).then(res => res.data)
      // .post(`${TEST_API}/admin/support/${id}/upload`, formData, {
      //   headers: {
      //     ...setHeader(),
      //     "Content-Type": "multipart/form-data",
      //   },
      //   onUploadProgress: (evt) => setUploadStatus(handleStatusUploadFile(evt))
      // })
      // .then((res) => res && res.data);
  },

  getChatHistory: (appoitmentId, page = 0) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/history/${appoitmentId}?page=${page}`,
      headers: setHeader("ua"),
    };
    return axios(config)
      .then((res) => {
      return res && res.data
    }).catch((e) => console.error('error', e))
  },

  getAttendingDoctorChatHistory: (
    patientCardId = Number,
    doctorId = Number
  ) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_ATTENDING_DOCTOR}/attendingDoctor/history/patientCard/${patientCardId}/doctor/${doctorId}`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => res && res.data);
  },

  getOperatorChatHistory: (id, page = 0, filterQuery = '') => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/support/history/${id}?page=${page}${filterQuery}`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => res && res.data);
  },

  editChatMessage: (
    role,
    messageID = Number,
    messageValue = "",
    cardID = Number,
    doctorID = Number,
    isAttendingDoctor = Boolean
  ) => {
    const data = { message: messageValue };
    let URL;
    if (role === "operator") {
      URL = `${SIGNALING_SERVER}${PORT_SUPPORT_SOCKET}/appointment/chat/operator/${messageID}`;
    } else if (isAttendingDoctor === true && role === "doctor") {
      URL = `${SIGNALING_SERVER}${PORT_ATTENDING_DOCTOR}/attendingDoctor/chat/${messageID}`;
    } else {
      URL = `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/${messageID}`;
    }
    let config = {
      method: "patch",
      baseURL: URL,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  deleteChatMessage: (role, messageID = Number, isAttendingDoctor) => {
    let URL;
    if (role === "operator") {
      URL = `${SIGNALING_SERVER}${PORT_SUPPORT_SOCKET}/appointment/chat/operator/${messageID}`;
    } else if (isAttendingDoctor === true && role === "doctor") {
      URL = `${SIGNALING_SERVER}${PORT_ATTENDING_DOCTOR}/attendingDoctor/chat/${messageID}`;
    } else {
      URL = `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/${messageID}`;
    }
    let config = {
      method: "DELETE",
      baseURL: URL,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },

  sendOperatorPushNotification: (data) => {
    console.log(data);
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/devices/consultation/operators`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  }
};
