import { createSelector } from "@reduxjs/toolkit";

const defaultCards = {
  photoLoading: false,
  loading: false,
};

const defaultState = { cards: { ...defaultCards }};

const getCards = (state = defaultState) =>
  state.cards;

export const getCardPhotoLoading = createSelector(
  [getCards],
  ({photoLoading}) => photoLoading
);

export const getCardsLoading = createSelector(
  [getCards],
  ({loading}) => loading
);
