import React from 'react'
import '../chat1.scss'
import oneArrowWhite from '../../../../style/icons/Chat/oneArrowWhite.svg'
import twoArrowsWhite from '../../../../style/icons/Chat/twoArrowsWhite.svg'
import { getMessageTime } from '../../../../utils/helpers';
import Button from "../../ui/Button/Button";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDisableAnamnezLoading, getIsAnamnezDisabled } from '../../../../redux/consultations/consultations-selectors';

const OrdinaryMessage = ({
  areBothInChat = Boolean,
  message = String,
  customParameters = Object,
  dateSent = String,
  isSentByUser = Boolean,
  status = String,
  type = String,
  userId = Number,
  handleDisableAnamnez = () => {},
}) => {
  const { t } = useTranslation()
  const loading = useSelector(getDisableAnamnezLoading)
  const isAnamnezDisabled = useSelector(getIsAnamnezDisabled)

  return (
    <div className={`ordinaryMessageBox ${isSentByUser ? 'me' : 'you'}`}>
      <div className='messageText'>{message}</div>
      <div className='messageInfo'>
        <div className='messageTime'>{getMessageTime(dateSent)}</div>
        <img
          className={`messageReaded ${(Number(status) === 2) ? 'readed' : "sent"}`}
          src={Number(status) === 2 ? twoArrowsWhite : oneArrowWhite}
          alt=""
        />
      </div>
      {customParameters.withButton && !isAnamnezDisabled && (
        <Button
          primary
          className='btn small'
          text={t("turnOfChatBot")}
          onClick={handleDisableAnamnez}
          buttonLoading={loading}
          smallLoader
        />
      )}
    </div>
  )
}

export default OrdinaryMessage
