import { createAction } from "@reduxjs/toolkit";

export const getDrugsListSuccess = createAction('drugs/getDrugsListSuccess')
export const getDrugsProvidersSuccess = createAction('drugs/getDrugsProvidersSuccess')
export const bookDrugsSuccess = createAction('drugs/bookDrugsSuccess')
export const getDrugsExcludedList = createAction('drugs/getDrugsExcludedList')
export const getProviderPharmaciesSuccess = createAction('drugs/getProviderPharmaciesSuccess')
export const setEmpty = createAction("drugs/setEmpty")
export const clearDrugs = createAction('drugs/clearDrugs')
export const getDrugsRequest = createAction("drugs/getDrugsRequest")
export const loadMoreDrugsRequest = createAction("drugs/loadMoreDrugsRequest")
export const changeDrugQuantityAction = createAction("drugs/changeDrugQuantityAction")
export const setSelected = createAction("drugs/setSelected")
export const changeDrugProvider = createAction('drugs/changeDrugProvider')
export const getDrugsFromSearchSuccess = createAction('drugs/getDrugsFromSearchSuccess')
export const resetDrugs = createAction("drugs/resetDrugs")
export const resetPageFromSearch = createAction("drugs/resetPageFromSearch")
export const setDrugsCard = createAction("drugs/setDrugsCard")
export const resetDrugsFromSearch = createAction("drugs/resetDrugsFromSearch")

