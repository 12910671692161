import React from 'react';
import './smsCodeComponent.scss'
// import Button from '../../components/ui/Button/Button';
import sync from '../../../style/icons/sync.svg'
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import useStyles from './styles'
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { IconButton } from '@material-ui/core';

const codeLength = 6;

const SmsCodeComponent = ({
  setCode,
  sendCode,
  smsLoading,
  handleConfirm,
  handleClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [error, setError] = React.useState(false)

  const validateCode = (code) => {
    return !/^[0-9]{6,6}$/.test(code)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const error = validateCode(value)
    if (error) {
      setError(t("fishkaCodeError"))
      return
    }
    setError(false)
    setCode(value)
  }

  return (
    <div className='fishkaCodeContainer'>
      <div className="codeTitle">Введите код</div>
      <IconButton
        style={{ position: "absolute", top: "10px", right: "10px", height: "20px", width: "20px" }}
        variant='contained'
        color='primary'
        onClick={handleClose}
        title={t("close")}
      >
        <CloseTwoToneIcon />
      </IconButton>
      <div className='inputBox'>
        {error && (
          <div className='inputError'>{error}</div>
        )}
        <input
          onChange={handleChange}
          className='fishkaCodeInput'
          name='fishkaCode'
          label={t('InputSmsCode')}
          placeholder={t("code")}
        />
      </div>
      <div className='repeatContainer'>
        <div className='repeat'>{t("ResendCode")}</div>
        <img
          className={`${smsLoading ? 'repeatCodeImg' : ''}`}
          onClick={sendCode}
          src={sync}
          alt="repeat code"
        />
      </div>
      <div className="buttonContainer">
        <Button
          fullWidth
          disabled={error}
          variant='contained'
          className={classes.button}
          onClick={handleConfirm}
          loading={true}
        >{t("confirmButton")}
        </Button>
      </div>
    </div>
  );
}

export default SmsCodeComponent;
