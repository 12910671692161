import axios from "axios";
import user from "./users";
import specializations from "./specializations";
import symptoms from "./symptoms";
import consultations from "./consultations";
import store from "../redux/store";
import analysis from "./analysis";
import doctors from "./doctors";
import hospitals from "./hospitals";
import addresses from "./addresses";
import relations from "./relations";
import notifications from "./notifications";
import cards from "./cards";
import subscription from "./subscription";
import fishka from "./fishka";
import drugs from "./drugs";
import chat from "./chat";
import banners from "./banners";
import { addNotification } from "../redux/popup/popup-actions";
import { v4 } from "uuid";
import i18n from "../i18n";
import { setToken } from "../redux/auth/auth-actions";

const lang = localStorage.getItem("i18nextLng")?.slice(0, 2) || "ua";
const refresh_times = localStorage.getItem("refresh_times") || "0";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.request?.responseURL.includes("/api/promocode?code")) {
      throw new Error(error?.response?.data?.message || "");
    }

    if (error?.response?.status === 401) {
      ///IF NOT CORRECT CODE FROM SMS SENT
      if (error?.config?.data?.includes("phone")) {
        store.dispatch(
          addNotification({
            id: v4(),
            type: "ERROR",
            message: i18n.t("codeNotCorrect"),
          })
        );
        return;
      }

      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        axios({
          method: "POST",
          baseURL: `${TEST_API}/token/refresh`,
          data: {
            refreshToken,
          },
        })
          .then((res) => {
            console.log("sdhcvjsdbv", { res });
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            store.dispatch(setToken(res.data.token));
            window.location.reload();
          })
          .catch((error) => {
            localStorage.clear();
            localStorage.setItem("lang", lang);
            window.location.reload();
          });
      }
    } else if (
      error?.request?.responseURL &&
      error?.request?.responseURL.includes("/api/promocode?code")
    ) {
      return;
    } else {
      if (error?.request?.status === 403) {
        throw new Error(error?.response?.data?.message || "");
      }
      store.dispatch(
        addNotification({
          id: v4(),
          type: "ERROR",
          message: error?.response?.data?.message || "",
        })
      );
    }
    return Promise.reject(error);
  }
);

export const TEST_HOST = process.env.REACT_APP_TEST_HOST;
export const SIGNALING_SERVER = process.env.REACT_APP_SIGNAL_SERVER;
export const PORT_CHAT_SOCKET = process.env.REACT_APP_PORT_CHAT_SOCKET;
export const PORT_CALL_SOCKET = process.env.REACT_APP_PORT_CALL;
export const PORT_SUPPORT_SOCKET = process.env.REACT_APP_PORT_CHAT_SUPPORT;
export const PORT_ATTENDING_DOCTOR =
  process.env.REACT_APP_PORT_CHAT_ATTENDING_DOCTOR;

export const TEST_API = `${TEST_HOST}/api`;

export const setHeader = () => {
  const token = localStorage.getItem("token");

  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "X-User-Agent": "DobroDocWeb: 1.0",
    "X-App-Version": "2.13.14"
  };
  let language =
    localStorage.getItem("i18nextLng").slice(0, 2) ||
    localStorage.getItem("lang");
  if (language) {
    // console.log({ language });
    if (language === "uk") language = "ua";
    header = { ...header, "Accept-Language": language };
  }
  return header;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user,
  specializations,
  symptoms,
  analysis,
  consultations,
  doctors,
  addresses,
  hospitals,
  relations,
  notifications,
  cards,
  subscription,
  chat,
  fishka,
  drugs,
  banners,
};
