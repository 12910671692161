import { createSelector } from "@reduxjs/toolkit";

const defaultConsultations = {
  subscription: [],
  isSubscribed: false,
  subscriptionList: [],
  loading: false,
  isBooked: false,
  otherLoading: false
};

const defaultState = { subscription: { ...defaultConsultations }};

const getSubscriptions = (state = defaultState) =>
  state.subscription;

export const getSubscriptionsInfo = createSelector(
  [getSubscriptions],
  ({subscription}) => subscription
);
export const getSubscribedCards = createSelector(
  [getSubscriptions],
  ({subscriptionList}) => getActiveCards(subscriptionList)
)
export const getIsSubscribed = createSelector(
  [getSubscriptions],
  ({isSubscribed}) => isSubscribed
);
export const getSubscriptionsList = createSelector(
  [getSubscriptions],
  ({ subscriptionList }) => subscriptionList
);
export const getSubscriptionLoading = createSelector(
  [getSubscriptions],
  ({ loading }) => loading
);
export const getSubscriptionOtherLoading = createSelector(
  [getSubscriptions],
  ({ otherLoading }) => otherLoading
);
export const getIsBooked = createSelector(
  [getSubscriptions],
  ({ isBooked }) => isBooked
);
export const getFondyUrl = createSelector(
  [getSubscriptions],
  ({fondyUrl}) => fondyUrl
)

const getActiveCards = (subArr) => {
  const obj = {}
  subArr
    .filter(el => el.booking?.patientCard?.id)
    .forEach(el => {
      obj[el.id] = el.booking.patientCard
    })
  return obj
}
