import { setHeader, TEST_API } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAnalysis: (page) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/analysis/items?page=${page}`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },
  getLaboratories: (page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/analysis/labs`,
      headers: setHeader()
    }
    return axios(config).then(res => res.data)
  },

  bookAnalysis: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/analysis/booking`,
      headers: setHeader(),
      data
    }
    return axios(config).then(res => res.data)
  } 
}