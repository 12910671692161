import chatModes from "../../utils/chatModes";
import info from "../../utils/info";
import { changeChatMode, setCallInfo, isBackgroundCallNeeded, setModalCallInfo, setVideoStatus, setAudioStatus, resetStatus } from "../chat/chat-actions";

const configuration = {
  iceServers: [
    { urls: "stun:relay1.gooddoc.fun:80" },

    {
      urls: "turn:relay1.gooddoc.fun:80?transport=udp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
    {
      urls: "turn:relay1.gooddoc.fun:80?transport=tcp",
      username: "domus",
      credential: "sPy80Hb7o(",
    },
  ],
};

export const callRtcTools = ({dispatch, socket, sendCallMessage, refs}) => {
  let peerConnection = null;
  let iceCandidates = [];
  let localStream = null
  let remoteStream = new MediaStream()
  let isRemoteDescriptionSet = false
  let callType = ''
  const {doctorAudioRef, doctorVideoRef, userVideoRef} = refs

  const _setIceCandidate = () => {
    iceCandidates.forEach((candidate) => {
      peerConnection.addIceCandidate(candidate)
    })
  }

  const setCallType = (type) => {
    console.log("3357scvhhsv", type);
    callType = type;
  }

  const _onIceCandidate = (event) => {
    if (event.candidate) {
      sendCallMessage({
        type: "candidate",
        candidate: event.candidate,
        dateSent: new Date(),
        customParameters: {
          nameValuePairs: {}
        },
        status: 0,
        isSentByUser: true,
      })
    }
  }

  const changeCallWindowRefs = ({patientRef, doctorRef}) => {
    patientRef.current.srcObject = localStream
    doctorRef.current.srcObject = remoteStream
  }

  const _onAddTrack = (event) => {
    remoteStream.addTrack(event.track, remoteStream);
    if (callType === 'video') {
      doctorVideoRef.current.srcObject = remoteStream
    }
    if (callType === 'audio') {
      doctorAudioRef.current.srcObject = remoteStream
    }
  }

  const _closePeer = () => {
    peerConnection && peerConnection.close();
    peerConnection = null;
    iceCandidates = []
    localStream = null
    remoteStream = new MediaStream()
    isRemoteDescriptionSet = false
  }

  const _onConnectionStateChange = e => {
    const state = e.currentTarget.connectionState;
    if (state === "closed" || state === "disconnected") {
      console.log("CLOSED OR DISCONNECTED", state);
      handleEndCall({endByMe: false})
    }
    if (state === "connected") {
      console.log("CONNECTED TO PEER");
    }
    if (state === "failed") {
      _closePeer()
      dispatch(isBackgroundCallNeeded(false))
    }
  }

  const handleCall = (message) => {
    console.log("RECEIVED CALL");
    callType = message.customParameters.call_type
    dispatch(setCallInfo(message.customParameters))
    dispatch(changeChatMode(chatModes.CALL))
  }

  const handleEndAnsweredCall = () => {
    callType = ""
    dispatch(changeChatMode(chatModes.CHAT))
    dispatch(setCallInfo(null))
  }

  const createPeer = () => {
    const peer = new RTCPeerConnection(configuration)
    peer.addEventListener('icecandidate', _onIceCandidate)
    peer.addEventListener('track', _onAddTrack)
    peer.addEventListener('connectionstatechange', _onConnectionStateChange)
    return peer
  }

  const handleAnswerCall = (type) => {
    console.log("type111", type);
    peerConnection = createPeer()
    if (type) {
      callType = type
    }
    console.log("type111 22", callType);
    if (callType === "video") {
      dispatch(changeChatMode(chatModes.VIDEO))
    }
    if (callType === "audio") {
      dispatch(changeChatMode(chatModes.AUDIO))
    }
    console.log("READY SENT");
    sendCallMessage({type: "ready"})
  }

  const handleOffer = async (offer) => {
    console.log("RECEIVED OFFER");
    const withVideo = callType === "video"
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: withVideo, audio: true })
      localStream = stream
      if (withVideo) userVideoRef.current.srcObject = stream;
      stream.getTracks().forEach((track) => {
        localStream.addTrack(track, stream)
        peerConnection.addTrack(track, stream)
      })
      const desc = new RTCSessionDescription({
        type: "offer",
        sdp: offer,
      })
      await peerConnection.setRemoteDescription(desc)
      isRemoteDescriptionSet = true
      _setIceCandidate()
      const sessionDescription = await peerConnection.createAnswer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
      })
      await peerConnection.setLocalDescription(sessionDescription)
      sendCallMessage({
        type: "answer",
        answer: sessionDescription.sdp,
        isSentByUser: true,
        customParameters: {
          nameValuePairs: {}
        }
      })
      console.log("ANSWER SENT");
    } catch (error) {
      console.log({error});
    }
  }

  const handleCandidate = (cand) => {
    console.log("RECEIVED CANDIDATE");
    const candidate = new RTCIceCandidate(cand)
    if (isRemoteDescriptionSet) {
      peerConnection.addIceCandidate(candidate)
    } else {
      iceCandidates.push(candidate)
    }
  }

  const handleRejectCall = ({endByMe}) => {
    console.log("RECEIVED REJECT");
    dispatch(setModalCallInfo(null))
    dispatch(changeChatMode(chatModes.CHAT))
    if (endByMe) {
      sendCallMessage({type: "reject"})
    }
  }


  const handleEndCall = async ({endByMe}) => {
    console.log("RECEIVED ENDED");
    iceCandidates = []
    dispatch(resetStatus())
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      stream.getTracks().forEach((tr) => tr.stop())
      _shutDownStream()
      dispatch(changeChatMode(chatModes.CHAT))
      dispatch(isBackgroundCallNeeded(false))
      if (endByMe) {
        sendCallMessage({
          type: 'ended',
          isSentByUser: true,
          status: 0,
          dateSent: new Date(),
          customParameters: {
            nameValuePairs: {}
          }
        })
      }
      _closePeer()
    } catch (error) {
      console.log({error});
    }
  }

  const toggleVideo = () => {
    if (localStream) {
      localStream
        .getVideoTracks()
        .forEach(tr => tr.enabled = !tr.enabled)
      dispatch(setVideoStatus())
    }
  }

  const toggleAudio = () => {
    if (localStream) {
      localStream && localStream
        .getAudioTracks()
        .forEach(tr => tr.enabled = !tr.enabled)
      dispatch(setAudioStatus())
    }
  }

  const _shutDownStream = () => {
    localStream && localStream
      .getTracks()
      .forEach(tr => tr.stop())
  }

  return {
    handleCall,
    handleOffer,
    handleCandidate,
    handleEndCall,
    handleRejectCall,
    toggleVideo,
    toggleAudio,
    handleAnswerCall,
    changeCallWindowRefs,
    handleEndAnsweredCall,
    setCallType,
  }
}
