import { setHeader, TEST_API, SIGNALING_SERVER } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNotifications: (page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/devices/notifications/list?page=${page}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  getBySearch: (query, start, end, page = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/devices/notifications/list?q=${query}${start ? `&dateFrom=${start}` : ""}${end ? `&dateTo=${end}` : start ? `&dateTo=${start}` : ""}&page=${page}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  }
}


