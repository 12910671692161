import axios from "axios";
import PageHeader from "../../views/components/PageHeader/PageHeader";
import { setHeader, TEST_API } from "../index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDrugsList: (page, drugsProvider = 'apteka24', query = '') => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/drugs?drugs_provider=${drugsProvider}&page=${page}&perPage=10${query ? `&filter[title]=${query}` : ''}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  getDrugsProvidersList: () => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/drugs/providers`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  getDrugsListWithExcluded: (data, page, drugsProvider = 'apteka24') => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/drugs?drugs_provider=${drugsProvider}?page=${PageHeader}`,
      headers: setHeader(),
      data
    }
    return axios(config).then(res => res.data)
  },
  bookDrugs: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/drugs/booking/order`,
      headers: setHeader(),
      data
    }
    return axios(config).then((res) => res.data)
  },
  getProviderPharmacies: (provider) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/drugs/${provider}/pharmacies`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
}