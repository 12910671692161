import io from "socket.io-client";
import {
  clearMessages,
  setConnectionState,
  isBackgroundCallNeeded,
  changeChatMode,
} from "../chat/chat-actions";
import { answerOnAnamnez } from "../consultations/consultations-operations";
import { socketHandlers } from "./handlers";
import { getCurrentDate } from "../../utils/helpers";
import { callRtcTools } from "./callTools";
import { getLastAnamnezId } from "../chat/chat-selectors";
import NotificationService from "./webSocketMiddleware";
import moment from "moment";
import i18n from "../../i18n";

const CHAT_PORT = 8085;
const CHAT_HOST = process.env.REACT_APP_SIGNAL_SERVER;
const SOCKET_HOST = `${CHAT_HOST}:${CHAT_PORT}`;
// const SOCKET_HOST = `http://localhost:8085`

const socketMiddleWare = () => {
  let socket = null;
  let onAuthSocketPool = [];
  let isAuthenticated = false;
  let hasJoinedRoom = false;
  let currentAppointmentId = null;
  let disableAnamnezButtonNeeded = false;
  let onAnamnezMessage = () => {};
  let socketPool = [];
  let onGetHistoryPool = [];
  let onJoinRoomPool = [];
  let callType = null;
  let callTools = {
    handleAnswerCall: () => {},
    handleRejectCall: () => {},
    handleEndCall: () => {},
    toggleVideo: () => {},
    toggleAudio: () => {},
    handleCall: () => {},
    handleOffer: () => {},
    handleCandidate: () => {},
    changeCallWindowRefs: () => {},
    setCallType: () => {},
    handleEndAnsweredCall: () => {},
  };

  const sendCallMessage = (data) => {
    socket.emit("callMessage", {
      appointmentId: currentAppointmentId,
      message: {
        ...data,
      },
    });
  };

  const sendAnamnezMessage = (message, question_id, appointmentId) => {
    socket.emit("message", {
      messageType: "patientToDoctor",
      appointmentId,
      type: "chatBot",
      message,
      isSentByUser: false,
      dateSent: getCurrentDate(),
      customParameters: {
        question_id: question_id.toString(),
      },
    });
  };

  const sendGreetingMessage = (message, appointmentId) => {
    socket.emit("message", {
      messageType: "patientToDoctor",
      appointmentId,
      type: "chatBot",
      message,
      isSentByUser: false,
      dateSent: getCurrentDate(),
      customParameters: {
        param: "",
      },
    });
  };

  const _onAnamnezMessage =
    (dispatch, getState) =>
    (message = Object) => {
      console.log("anamMessage", message);
      if (disableAnamnezButtonNeeded && message.customParameters.question_id) {
        message.customParameters.withButton = true;
      }
      if (message.isSentByUser) {
        const lastAnamnez = getLastAnamnezId(getState());
        if (message.appointmentId && lastAnamnez) {
          const requestBody = { anamnesis: { [lastAnamnez]: message.message } };
          dispatch(answerOnAnamnez(message.appointmentId, requestBody));
        }
      }
      return message;
    };

  return ({ dispatch, getState }) =>
    (next) =>
    async (action) => {
      switch (action.type) {
        case "chat-socket/wsConnect": // CONFIGURE SOCKET
          if (socket) {
            socket.close();
            socket = null;
          }
          socket = io.connect(SOCKET_HOST, {
            "reconnection delay": 0,
            "reopen delay": 0,
            "force new connection": true,
            transports: ["websocket"],
          });
          socket.on("connect", () => {
            console.log("CONNECTED TO CHAT SOCKET");
            callTools = callRtcTools({
              dispatch,
              socket,
              sendCallMessage,
              refs: { ...action.payload },
            });
            onAnamnezMessage = _onAnamnezMessage(dispatch, getState);
            socket.emit("auth", { token: localStorage.getItem("token") });
            dispatch(setConnectionState(true));
          });
          socket.on("disconnect", () => {
            console.log("DISCONNECTED FROM SOCKET");
            dispatch(setConnectionState(false));
            isAuthenticated = false;
            hasJoinedRoom = false;
            callType = null;
          });
          socket.on("connect_error", (err) => {
            console.log("ERROR FROM SOCKET", { err });
            // dispatch(setConnectionState(false))
          });
          socket.on("onAuth", () => {
            isAuthenticated = true;
            Object.keys(socketHandlers).forEach((key) => {
              if (key === "onCallMessage") {
                socket.on([key], socketHandlers[key](callTools));
              } else if (key === "onMessage") {
                socket.on(
                  [key],
                  socketHandlers[key](dispatch, onAnamnezMessage)
                );
              } else if (key === "onGetHistoryAppointment") {
                socket.on(
                  [key],
                  socketHandlers[key](dispatch, onGetHistoryPool)
                );
              } else {
                socket.on([key], socketHandlers[key](dispatch, socket));
              }
            });
            onAuthSocketPool.forEach((callback) => callback());
            onAuthSocketPool = [];
          });
          socket.on("onJoinAppointmentRoom", () => {
            hasJoinedRoom = true;
            if (onJoinRoomPool.length) {
              onJoinRoomPool.forEach((cb) => cb());
              onJoinRoomPool = [];
            }
          });
          socket.on("onAlreadyAnsweredCall", () => {
            callTools.handleEndAnsweredCall();
          });
          break;

        case "chat-socket/getHistory": /// GET CHAT HISTORY
          const {
            userId,
            appointmentId,
            page,
            startDate,
            endDate,
            isSupport,
            typeRoom,
          } = action.payload;

          currentAppointmentId = appointmentId;
          const limit = startDate || endDate ? 50 : 20;
          const requestedPage = startDate || endDate ? 0 : page;

          function getHistory() {
            if (page === 0 && !startDate && !endDate) {
              if (isSupport) socket.emit("joinRoom", { typeRoom });
              if (!isSupport)
                socket.emit("joinAppointmentRoom", { typeRoom, appointmentId });
            }
            if (isSupport) {
              socket.emit("getHistoryWithOperator", {
                skip: requestedPage,
                startDate,
                endDate,
                limit,
              });
            } else {
              socket.emit("getHistoryAppointment", {
                appointmentId,
                startDate,
                endDate,
                skip: requestedPage,
                limit,
              });
            }
          }
          if (startDate || endDate) {
            getHistory();
            return;
          }
          if (socket && isAuthenticated) {
            getHistory();
          } else {
            onAuthSocketPool.push(getHistory);
          }
          break;

        case "chat/sendAnamnezMessage":
          const { chatText, id, consultId } = action.payload;
          disableAnamnezButtonNeeded = true;
          if (socket && hasJoinedRoom) {
            sendAnamnezMessage(chatText, id, consultId);
          } else {
            onJoinRoomPool.push(sendAnamnezMessage);
          }
          break;

        case "chat-socket/wsDisconnect":
          console.log("WS DISCONNECT");
          if (socket) {
            dispatch(clearMessages());
            socket.emit("chatRoomLeave");
            socket.close();
            socket = null;
            isAuthenticated = false;
            hasJoinedRoom = false;
            callType = null;
            onAuthSocketPool = [];
            onJoinRoomPool = [];
            onGetHistoryPool = [];
          }
          break;
        case "chat/sendGreetingMessage":
          console.log("sendGreetingMessage");
          const { doctor, date, appId } = action.payload;
          const formatDate = moment(date).format("DD MMMM");
          const formatTime = moment(date).format("HH:mm");
          const message = `${i18n.t(
            "doctorGreetingFirstPart"
          )} ${doctor}. ${i18n.t(
            "doctorGreetingSecondPart"
          )} ${formatDate} ${i18n.t("at")} ${formatTime}. ${i18n.t(
            "doctorGreetingThirdPart"
          )}.`;
          function sendGreetings() {
            sendGreetingMessage(message, appId);
          }
          // if (socket && hasJoinedRoom) {
          //   sendGreetings();
          // } else {
          if (!onGetHistoryPool.length) onGetHistoryPool.push(sendGreetings);
          // }
          break;
        case "chat/sendMessage":
          const { messageBody } = action.payload;
          socket.emit("message", messageBody);
          break;
        case "chat-socket/readStartMessages":
          const { list, messageType } = action.payload;
          socket.emit("readMessagesList", { idsList: list, messageType });
          break;
        case "chat/answerCall":
          function sendReady() {
            const typeCall = action.payload?.type
              ? action.payload.type
              : callType;
            callTools.handleAnswerCall(typeCall);
          }
          if (socket && hasJoinedRoom) {
            sendReady();
          } else {
            onJoinRoomPool.push(sendReady);
          }
          break;
        case "chat/rejectCall":
          function sendRejected() {
            callTools.handleRejectCall({ endByMe: true });
          }
          if (socket && hasJoinedRoom) {
            sendRejected();
          } else {
            onJoinRoomPool.push(sendRejected);
          }
          callType = null;
          break;
        case "chat/endCall":
          callTools.handleEndCall({ endByMe: true });
          callType = null;
          break;
        case "chat/muteVideo":
          callTools.toggleVideo();
          break;
        case "chat/muteAudio":
          callTools.toggleAudio();
          break;
        case "chat/moveCallToBackground": {
          const { patientRef, doctorRef } = action.payload;
          callTools.changeCallWindowRefs({ patientRef, doctorRef });
          break;
        }
        case "chat/updateCall":
          dispatch(isBackgroundCallNeeded(false));
          const { patientRef, doctorRef } = action.payload;
          callTools.changeCallWindowRefs({ patientRef, doctorRef });
          break;
        case "chat/openCallFromBackground":
          callType = action.payload.callType;
          callTools.setCallType(action.payload.callType);
          dispatch(changeChatMode(action.payload.chatMode));
          break;
        case "chat/connectNotification":
          const notifications = new NotificationService(dispatch);
          notifications.createMessaging();
          break;
        default:
          return next(action);
      }
    };
};

export default socketMiddleWare();
