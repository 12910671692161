import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  getDoctorsBySearchSuccess,
  getDoctorsBySearchRequest,
  getRandomDocSuccess,
  getPeriodsSuccess,
  changeType,
  checkIfEmpty,
  resetIsEmpty,
  getBySearchSuccess,
  pageSearchRequest,
  clearSearch,
  setIsSearchEmpty,
  getPeriodsRequest,
} from './doctors-actions'


const doctorsBySearch = createReducer(null, {
  [getDoctorsBySearchSuccess]: (_, { payload }) => payload?.data.items,
  // [getBySearchSuccess]: (_, { payload }) => payload?.data.items,
  [changeType]: (_, __) => null,
})

const searchPage = createReducer(1, {
  [getBySearchSuccess]: (_, { payload }) => payload?.data.current_page,
  [clearSearch]: (_, { payload }) => 1
})

const searchPageCount = createReducer(1, {
  [getBySearchSuccess]: (_, { payload }) => payload?.data.page_count,
  [clearSearch]: () => 1
})

const doctorsByQuery = createReducer([], {
  [getBySearchSuccess]: (_, { payload }) => payload?.data.items,
  [clearSearch]: (_, __) => [],
})

const searchLoading = createReducer(false, {
  [pageSearchRequest]: (_, __) => true,
  [getBySearchSuccess]: (_, __) => false,
})
// const doctorsList = createReducer([], {
//   [getDoctorsBySearchSuccess]: (_, { payload }) => payload?.data?.items || [],
//   [changeType]: (_, __) => null,
// })

const loading = createReducer(false, {
  [getDoctorsBySearchRequest]: (_, __) => true,
  [getDoctorsBySearchSuccess]: (_, __) => false,
})

const currentPage = createReducer(1, {
  [getDoctorsBySearchSuccess]: (_, {payload}) => payload?.data?.current_page || 1,
})

const pageCount = createReducer(1, {
  [getDoctorsBySearchSuccess]: (_, {payload}) => payload?.data?.page_count || 1,
})

const randomDoc = createReducer(null, {
  [getRandomDocSuccess]: (_, { payload }) => payload?.entity?.doctor,
  [changeType]: (_, __) => null,
})
const randomPeriods = createReducer(null, {
  [getRandomDocSuccess]: (_, { payload }) => payload?.entity?.periods,
  [changeType]: (_, __) => null,
})

const periods = createReducer([], {
  [getPeriodsSuccess]: (_, { payload }) => payload?.entity?.periods,
  [changeType]: (_, __) => null,
})

const periodsLoading = createReducer(false, {
  [getPeriodsRequest]: () => true,
  [getPeriodsSuccess]: () => false,
})

const isGetDoctor = createReducer(true, {
  // [getPeriodsSuccess]: (_, { payload }) => payload?.entity?.periods?.length > 0 ? true : false,
  [checkIfEmpty]: (_, { payload }) => payload?.data?.items?.length ? true : false,
  [resetIsEmpty]: (_, __) => true, 
})

export const isEmptySearchResult = createReducer(false, {
  [setIsSearchEmpty]: (_, {payload}) => payload,
  [clearSearch]: (_, {payload}) => false,
})

export default combineReducers({
  doctorsBySearch,
  randomDoc,
  randomPeriods,
  periods,
  isGetDoctor,
  loading,
  currentPage,
  pageCount,
  searchLoading,
  doctorsByQuery,
  isEmptySearchResult,
  searchPage,
  searchPageCount,
  periodsLoading,
  // doctorsList,
})