import { createAction } from "@reduxjs/toolkit";

export const getDoctorsBySearchSuccess = createAction("doctors/getDoctorsSuccess")
export const getDoctorsBySearchRequest = createAction("doctors/getDoctorsRequest")
export const getRandomDocSuccess = createAction("doctors/getRandomDocSuccess")
export const getPeriodsSuccess = createAction("doctors/getPeriodsSuccess")
export const changeType = createAction("consultation/changeConsultationType")
export const checkIfEmpty = createAction('doctors/checkIfEmpty')
export const resetIsEmpty = createAction('doctors/resetIsEmpty')
export const pageSearchRequest = createAction('doctors/pageSearchRequest')
export const getBySearchSuccess = createAction('doctors/getBySearchSuccess')
export const clearSearch = createAction("doctors/clearSearch")
export const setIsSearchEmpty = createAction("doctors/setIsSearchEmpty")
export const getPeriodsRequest = createAction("doctors/getPeriodsRequest")
