import { setHeader, TEST_API, SIGNALING_SERVER } from ".."
import axios from 'axios'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCard: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/patients/cards`,
      headers: setHeader(),
      data,
    }
    return axios(config).then(res => res.data)
  },
  updateCard: (cardId, data) => {
    let config = {
      method: "PUT",
      baseURL: `${TEST_API}/patients/cards/${cardId}`,
      headers: setHeader(),
      data,
    }
    return axios(config).then(res => res.data)
  },
  deleteCard: (cardId) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/patients/cards/${cardId}`,
      headers: setHeader(),
    }
    return axios(config).then(res => res.data)
  },
  uploadCardImage: (cardId, image) => {
    let formData = new FormData()
    formData.append('photoFile', image)
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/patients/cards/${cardId}/uploadPhoto`,
      headers: {
        ...setHeader(),
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    }
    return axios(config).then(res => res.data)
  },
}
