import { combineReducers, createReducer } from "@reduxjs/toolkit";
import {
  photoRequest,
  photoSuccess,
  createCardSuccess,
  cardRequest,
} from './cards-actions'


const photoLoading = createReducer(false, {
  [photoRequest]: (_, __) => true,
  [photoSuccess]: (_, __) => false,
})

const loading = createReducer(false, {
  [cardRequest]: (_, __) => true,
  [createCardSuccess]: (_, ___) => false
})

export default combineReducers({
  photoLoading,
  loading,
})

// export default consultations