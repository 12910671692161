import { makeStyles } from "@material-ui/core"

export const useAudioStyles = makeStyles({
  playButton: {
    color: "#3288cd",
    width: "50px",
    height: "50px",
    '&:hover': {
      cursor: "pointer"
    }
  },
  playMeButton: {
    color: "white",
    width: "50px",
    height: "50px",
    '&:hover': {
      cursor: "pointer"
    }
  }
})