import React from 'react';
import Spinner from "../../ui/Spinner/Spinner"

export const InternetStateComponent = ({t}) => {
  return <div className="errorText">{t("internetError")}</div>;
}

export const WebSocketStateComponent = ({t}) => {
  return (
    <div className="connectionBox">
      <div className="loadingText">{t("connection")}</div>
      <Spinner width={20} height={20} />
    </div>
  );
}
