import React from 'react'
import logo from '../../../style/icons/logoMobileRef.svg'
import './refToMobile.scss'

export const RefToMobile = () => {
  return (
    <div className='mobileRefContainer'>
      <div className='logoWrapper'>
        <img src={logo} alt="Dobrodoc+" />
      </div>
      <div className='Dobrodoc'>Dobrodoc+</div>
      <div className='description'>Перша Мобільна клініка в Україні</div>
      <div className='refsContainer'>
        <a target='_blank' rel="nofollow noopener noreferrer" href="https://apps.apple.com/us/app/dobrodoc/id1483029353">
          <img src="https://qrco.de/img/generator/app/apple-en.png" alt="" />
        </a>
        <a target='_blank' rel="nofollow noopener noreferrer" href="https://play.google.com/store/apps/details?id=domus.dobrodoc">
          <img src="https://qrco.de/img/generator/app/google-en.png" alt="" />
        </a>
      </div>
    </div>
  )
}