import { combineReducers, createReducer } from '@reduxjs/toolkit'
import {
  getHospitalGroupsSuccess,
  getHospitalsSuccess,
  getRequest,
  filterHospitals,
} from './hospitals-actions'


const hospitalGroups = createReducer(null, {
  [getHospitalGroupsSuccess]: (_, {payload}) => payload?.data?.items || null
})

const hospitals = createReducer(null, {
  [getHospitalsSuccess]: (_, { payload }) => payload?.data?.items || null,
  [filterHospitals]: (state, { payload }) => {
    if (!state) return null
    return state.filter((el) => el.addressText.includes(payload))
  }
})

const loading = createReducer(false, {
  [getRequest]: (_, __) => true,
  [getHospitalsSuccess]: (_, __) => false
})

export default combineReducers({
  hospitalGroups,
  hospitals,
  loading,
})