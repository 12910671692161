import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import previousIcon from '../../../../style/icons/Subscription/previousIcon.svg'
import patientActive from '../../../../style/icons/Subscription/patient_active.svg'
import { getUserCards } from "../../../../redux/auth/auth-operations"
import '../navbar.scss'
import { NavLink } from "react-router-dom"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import { LoadingComponent } from "../../../pages/ConsultationBooking/ConsultationBooking"
import Animationlist from "../../AnimatedList/AnimatedList"

const SubscriptionCards = ({
  setCardListOpen,
  setCardId,
  setOpenMenu,
  t,
}) => {
  const dispatch = useDispatch()
  const userCards = useSelector(state => state?.user?.userCards)
  const loading = useSelector(({ user }) => user?.loading)

  useEffect(() => {
    dispatch(getUserCards())
  }, [])

  const handleCloseNavbar = () => {
    setCardListOpen(false)
    setOpenMenu(false)
  }
  
  return (
    <div className='medcardsBox'>
      <div className='listTool'>
        <IconButton
          style={{ width: "20px", height: "20px", color: "#3288cd" }}
          variant='contained'
          color='primary'
          onClick={() => setCardListOpen(false)}
          title={t("back")}
        >
          <ChevronLeftIcon />
        </IconButton>
        <div className='title'>{t("selectMedicalCard")}</div>
      </div>
      <div className="cardBox">
        <LoadingComponent loading={loading} width={70} height={70}>
          {userCards && (
            <Animationlist>
              {userCards.map(card => {
                return (
                  <NavLink key={card.id} className='cardHref' to={`/subscriptions/${card.id}`}>
                    <div
                      onClick={handleCloseNavbar}
                      className='userCard'>
                      <div
                        style={{
                          backgroundImage: `url(${card.photoUrl || patientActive})`,
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          width: "50px"
                        }}
                        className='subscriptionCardImage'
                      ></div>
                      <div className='infoContainer'>
                        <div className='userName'>{`${card.lastName} ${card.firstName}`}</div>
                        <div className="userRelation">{card.relationShip.title}</div>
                      </div>
                    </div>
                  </NavLink>
                )
              })}
            </Animationlist>
          )}
        </LoadingComponent>
      </div>
    </div>
  )
}

export default SubscriptionCards
